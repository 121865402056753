/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { GA_URL } from '../util/constants';
import { chunkArray, sleep } from '../../../utils/util';
import { validateGAquery, createRequests, formatGAoutput, splitResponsesByPeriod, aggregateReport, realignDates, addMissingKeys, sortData } from './titanAPIutil';

const getGAData = async (reportRequests, GA_accessToken) => {
  const requestConfig = { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${GA_accessToken}` } };
  const requestBody = JSON.stringify({ reportRequests });
  try {
    const requestRawOutput = await axios.post(GA_URL, requestBody, requestConfig);
    return requestRawOutput.data;
  } catch (err) {
    throw err.response.data.error;
  }
};

const fetchGAData = async (key, { GA_token, ...params }) => {
  // console.log(params);

  // To be deleted - needed in dev because params is empty when loading
  if (Object.entries(params).length === 0) return [];
  // console.log(params);

  // 1. Validate query
  const queryValidation = validateGAquery(params);
  if (queryValidation.error) throw Error(queryValidation.message);

  // 2. Convert query to request objects
  const allRequests = createRequests(params);
  // console.log(allRequests);

  // 4. Group batches in batchGroups of 10 batches (GA limit of 10 API calls per second)
  const requestBatches = chunkArray(allRequests, 10);

  // 5. Call API
  let responses_raw = [];
  for (const requestBatch of requestBatches) {
    const requestPromises = requestBatch.map((request) => getGAData(request, GA_token));
    const responsesCurrentBatch = await Promise.all(requestPromises);
    responses_raw = [...responses_raw, ...responsesCurrentBatch];
    await sleep(1100); // wait ˜1s until next API call
  }
  // console.log(responses_raw);

  // 6. Cleanup response
  const responses = responses_raw.map((res) => formatGAoutput(res, params));
  // console.log(responses);

  // Loop over periods and parks
  // requests were flatten, they are ordered as: [period1park1, period1park2, ...period1parkn, period2park1, period2park2, etc]
  const dataByPeriod = splitResponsesByPeriod(responses, params);
  // console.log(dataByPeriod);

  // Aggregate the results of each viewId
  const aggregatedData = dataByPeriod.map((periodResponse) => aggregateReport(periodResponse, params));
  // console.log(aggregatedData);

  // for comparison periods, realign the dates to the main period
  const realignedData = aggregatedData.map((data, i) => (i === 0 ? data : realignDates(data)));
  // console.log(realignedData);

  // Add keys that are present in other period
  const enhancedData = addMissingKeys(realignedData);
  // console.log(enhancedData);

  // sort data by the dimensions and then by date if present (/!\ OVERWRITES THE OBJECT enhancedData)
  const sortedData = enhancedData.map((data) => sortData(data));
  // console.log(sortedData);

  return sortedData;
};


export default { getGAData, fetchGAData };
