import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import datasetsActions from 'AppModules/Data/Actions/datasetsActions';
import FilesInputConfiguration from './FilesInputConfiguration';

const FilesInputConfigurationContainer = ({ dataset }) => {
  const { token } = useSelector((store) => store.authReducer);
  const { fileList } = useSelector((store) => store.uploadReducer);

  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  // When fileList changes, update dataset.files to reflect the files
  React.useEffect(() => {
    stableDispatch(datasetsActions.updateDatasetInputFileList(dataset, fileList, token));
  }, [fileList, stableDispatch]);


  const handleClickSelect = (itemKey) => {
    const file = dataset.files.find((el) => el.name === itemKey);
    stableDispatch(datasetsActions.updateInputFileParameter(dataset.name, file.name, 'selected', !file.selected));
  };

  const handleClickSelectAll = () => {
    const selected = dataset.files.filter((file) => !file.selected).length > 0;
    stableDispatch(datasetsActions.bulkUpdateInputFileParameter(dataset.name, dataset.files.map((file) => file.name), 'selected', selected));
  };

  const handleChangeFileRole = (fileName, newRole) => {
    stableDispatch(datasetsActions.updateInputFileParameter(dataset.name, fileName, 'role', newRole));
  };

  return (
    <FilesInputConfiguration
      dataset={dataset}
      handleClickSelectAll={handleClickSelectAll}
      handleClickSelect={handleClickSelect}
      handleChangeFileRole={handleChangeFileRole}
    />
  );
};

FilesInputConfigurationContainer.propTypes = {
  dataset: datasetPropTypes.isRequired,
};
export default FilesInputConfigurationContainer;
