/* eslint-disable no-param-reassign */
import produce from 'immer';
import userActionTypes from './userActionTypes';
import { dateReviver } from '../utils/util';

const initialState = localStorage.getItem('userSettings') ? JSON.parse(localStorage.getItem('userSettings'), dateReviver) : {
  userSettings: {
    isDarkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
  },
};

const userReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case userActionTypes.TOGGLE_DARK_MODE:
      draft.userSettings.isDarkMode = !draft.userSettings.isDarkMode;
      break;
    // return ({ ...state, userSettings: { ...state.userSettings, isDarkMode: !state.userSettings.isDarkMode } });
    default:
      return draft;
  }
  localStorage.setItem('userSettings', JSON.stringify(draft));
  return draft;
});

export default userReducer;
