import { sub } from 'date-fns';
import { comparisonDefinitions } from '../Definitions/definitions';

const buildChartQuery = (chartParameters) => {
  // get the metrics: metrics.map(el => el.name), dimensions and filters
  const { metrics, dimensions, filters } = chartParameters;

  // determine the comparison periods
  const comparisonsDefinition = chartParameters.comparisons.map((chartComparisons) => comparisonDefinitions.find((comp) => comp.name === chartComparisons.name));
  const comparisonPeriods = comparisonsDefinition.map((comparison) => {
    // find the date filter
    const dateFilterValues = filters.find((filter) => filter.name === 'ga:date').values;
    if (!dateFilterValues) throw Error('A comparison is present but no filter on Date is present');
    return {
      from: sub(dateFilterValues.from, comparison.timeDiff),
      to: sub(dateFilterValues.to, comparison.timeDiff),
      name: comparison.name,
    };
  });

  const query = {
    metrics: metrics.map((el) => el.name),
    dimensions: dimensions.map((el) => el.name),
    filters,
    comparisonPeriods,
  };

  return query;
};

export default buildChartQuery;
