import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import datasetsActions from 'AppModules/Data/Actions/datasetsActions';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import RunDefinition from './RunDefinition';

const RunDefinitionContainer = (props) => {
  const { token } = useSelector((store) => store.authReducer);

  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  const handleClickRun = () => {
    stableDispatch(datasetsActions.createRun(props.dataset, token));
  };

  return props.dataset.dataSource !== 'files' ? null : (
    <RunDefinition
      dataset={props.dataset}
      handleClickRun={handleClickRun}
    />
  );
};

RunDefinitionContainer.propTypes = {
  dataset: datasetPropTypes.isRequired,
};

export default RunDefinitionContainer;
