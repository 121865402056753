import React from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import datasetsActions from 'AppModules/Data/Actions/datasetsActions';
import InputsDefinition from './InputsDefinition';

const InputsDefinitionContainer = (props) => {
  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  const handleChangeDataSource = (dataSourceName) => {
    stableDispatch(datasetsActions.changeDatasetParameter(props.dataset.name, 'dataSource', dataSourceName));
  };
  return (
    <InputsDefinition
      dataset={props.dataset}
      handleChangeDataSource={handleChangeDataSource}
    />
  );
};

InputsDefinitionContainer.propTypes = {
  dataset: datasetPropTypes.isRequired,
};

export default InputsDefinitionContainer;
