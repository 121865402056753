/* eslint-disable react/prop-types */
import React from 'react';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import { Button } from '@blueprintjs/core';

const RunGenerator = (props) => (
  <div className="PageContainer">
    <p>
      Estimate number of rows for the Volumes file:
      {' '}
      {props.numberOfRows}
    </p>
    <Button icon="cog" onClick={props.handleGenerateData} large>Generate data</Button>
    {props.data.volumesTable ? (
      <CSVLink data={props.data.volumesTable} enclosingCharacter="" filename={`${format(new Date(), 'yyMMdd')}_Volumes.csv`}>
        Volumes Table
      </CSVLink>
    ) : null}
    {props.data.eventsTable ? (
      <CSVLink data={props.data.eventsTable} enclosingCharacter="" filename={`${format(new Date(), 'yyMMdd')}_Events.csv`}>
        Events Table
      </CSVLink>
    ) : null}
    {props.data.financialsTable ? (
      <CSVLink data={props.data.financialsTable} enclosingCharacter="" filename={`${format(new Date(), 'yyMMdd')}_Financials.csv`}>
        Financials Table
      </CSVLink>
    ) : null}
  </div>
);

export default RunGenerator;
