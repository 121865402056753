import titanActionTypes from './titanActionTypes';
// import { validateParameters } from '../Definitions/chartParametersRules';

const handleChangeFiltersDisplayMode = () => ({
  // currently not implemented in reducer, doesn't seem necessary to save this in store
  type: titanActionTypes.CHANGE_DASHBOARD_FILTERS_DISPLAYMODE,
});

const validateParameters = (dashboardID, chartGroupID) => ({
  type: titanActionTypes.VALIDATE_PARAMETERS,
  dashboardID,
  chartGroupID,
});

const checkChartIsSynced = (dashboardID, chartGroupID) => ({
  type: titanActionTypes.CHECK_CHART_IS_SYNCED,
  dashboardID,
  chartGroupID,
});

const updateFilterAttribute = (dashboardID, chartGroupID, filterID, attributeName) => (dispatch) => {
  dispatch({
    type: titanActionTypes.UPDATE_FILTER_ATTRIBUTE,
    dashboardID,
    chartGroupID,
    filterID,
    attributeName,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const updateFilterValues = (dashboardID, chartGroupID, filterID, values) => (dispatch) => {
  dispatch({
    type: titanActionTypes.UPDATE_FILTER_VALUE,
    dashboardID,
    chartGroupID,
    filterID,
    values,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const addFilter = (dashboardID, chartGroupID) => (dispatch) => {
  dispatch({
    type: titanActionTypes.ADD_FILTER,
    dashboardID,
    chartGroupID,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const removeFilter = (dashboardID, chartGroupID, filterID) => (dispatch) => {
  dispatch({
    type: titanActionTypes.REMOVE_FILTER,
    dashboardID,
    chartGroupID,
    filterID,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

export default {
  handleChangeFiltersDisplayMode,
  updateFilterAttribute,
  updateFilterValues,
  addFilter,
  removeFilter,
};
