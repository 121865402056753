import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chartGroupPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';
import ChartGroups from './ChartGroups';

const ChartGroupsContainer = (props) => {
  const handleAddChart = () => {
    props.addChart(props.token, props.dashboardID);
  };

  return (
    <ChartGroups
      dashboardID={props.dashboardID}
      chartGroups={props.chartGroups}
      handleAddChart={handleAddChart}
    />
  );
};

ChartGroupsContainer.propTypes = {
  token: PropTypes.string.isRequired,
  dashboardID: PropTypes.number.isRequired,
  chartGroups: PropTypes.arrayOf(chartGroupPropTypes).isRequired,
  addChart: PropTypes.func.isRequired,
};

const mapStateToProps = (store, ownProps) => ({
  token: store.authReducer.token,
  chartGroups: store.titanReducer.dashboards[ownProps.dashboardID].chartGroups,
});

const mapDispatchToProps = (dispatch) => ({
  addChart: (token, dashboardID) => (
    dispatch(chartGroupsActions.addChart(token, dashboardID))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartGroupsContainer);
