import React from 'react';
import MyBreadCrumbs from 'Components/MyBreadCrumbs/MyBreadCrumbs';
import FileUploadContainer from './FilesUpload/FileUploadContainer';
import FileListContainer from './FileList/FileListContainer';

const UploadContainer = () => (
  <div>

    <MyBreadCrumbs
      items={[
        { href: '/', text: 'Home' },
        { href: '/Data/Upload', text: 'Upload' },
      ]}
    />
    <FileUploadContainer />
    <FileListContainer />
  </div>
);
export default UploadContainer;
