/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DropZone from 'react-dropzone';
import { Icon } from '@blueprintjs/core';
import { useAuth0 } from '../../../../../Auth/react-auth0-spa';
import { BigButton } from '../../../../../Components/Buttons/Buttons';
import uploadActions from '../../../Actions/uploadActions';
import './FileSelector.css';

const FileSelector = ({ addFilesToUpload }) => {
  const { getTokenSilently } = useAuth0();

  const handleFilesSelected = async (files) => {
    const token = await getTokenSilently();
    addFilesToUpload(files, token);
  };
  return (
    <DropZone className="fileSelectorSection" onDrop={handleFilesSelected}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div className={isDragActive ? 'fileSelectorSection active' : 'fileSelectorSection'} {...getRootProps()}>
          <input {...getInputProps()} />
          <BigButton bgColor="#394B59">
            <Icon icon="multi-select" iconSize={30} />
          </BigButton>
          <p style={{ marginTop: '10px' }}>{isDragActive ? 'OK you can drop them!' : 'Drag file onto this area'}</p>
        </div>
      )}
    </DropZone>
  );
};

FileSelector.propTypes = {
  addFilesToUpload: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addFilesToUpload: (files, token) => dispatch(uploadActions.addFilesToUpload(files, token)),
});

export default connect(null, mapDispatchToProps)(FileSelector);
