import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import localforage from 'localforage';
import ganymedeActions from 'AppModules/Ganymede/Actions/ganymedeActions';
import { Toaster, Intent } from '@blueprintjs/core';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import ScreenshotRow from './ScreenshotRow';

const infoToaster = Toaster.create();
const downloadErrorToaster = Toaster.create();

const ScreenshotRowContainer = (props) => {
  const [downloadProgress, setDownloadProgress] = React.useState();
  const [screenshotImgData, setScreenshotImgData] = React.useState('');

  // When mounting, load imgData from localforage if it exists
  React.useEffect(() => {
    let isSubscribed = true; // needed because the async function could resolve after the component has unmounted
    const getLocalImgData = async () => {
      const imgData = await localforage.getItem(`ganymedeScreenshotData_${props.screenshot.key}`);
      if (isSubscribed) {
        if (imgData) {
          setScreenshotImgData(imgData);
          setDownloadProgress(1);
        } else {
          setScreenshotImgData('');
          setDownloadProgress(null);
        }
      }
    };
    getLocalImgData();
    return () => { isSubscribed = false; };
  }, [props.isDeleting, props.screenshot.key]);

  const onDownloadProgress = (progressEvent) => {
    setDownloadProgress(progressEvent.loaded / progressEvent.total);
  };

  const downloadScreenshot = async () => {
    setDownloadProgress(0); // Set progress to show that download has started (instead of waiting for the first progress event)
    const { imgData, err } = await ganymedeActions.downloadScreenshot(props.screenshot.key, onDownloadProgress, props.token);
    if (!err) {
      setScreenshotImgData(imgData);
      props.updateNbOfLocalScreenshotKeys();
    } else {
      downloadErrorToaster.show({ message: err, intent: Intent.DANGER, icon: 'warning-sign' });
      setDownloadProgress(null);
    }
    return { imgData, err };
  };

  const handleClickDownloadScreenshot = async (e) => {
    e.stopPropagation();
    await downloadScreenshot();
  };

  const handleClickShowScreenshot = async () => {
    if (screenshotImgData) {
      props.handleDisplayScreenshot(screenshotImgData);
    } else if (downloadProgress === null || downloadProgress === undefined) { // screenshot hasn't be downloaded yet
      if (!props.aScreenshotIsLoading) {
        props.setAScreenshotIsLoading(true);
        // if (screenshotImgData) {
        //   props.handleDisplayScreenshot(screenshotImgData);
        //   props.setAScreenshotIsLoading(false);
        // } else {
        const { imgData, err } = await downloadScreenshot();
        if (!err) {
          props.handleDisplayScreenshot(imgData);
          props.setAScreenshotIsLoading(false);
        } else {
          props.setAScreenshotIsLoading(false);
          setDownloadProgress(null);
        }
        // }
      } else {
        infoToaster.show({ message: 'Another screenshot is already loading', intent: Intent.WARNING, icon: 'warning-sign' });
      }
    } // otherwise screenshot is currently loading, do nothing
  };

  return (
    <ScreenshotRow
      screenshot={props.screenshot}
      selected={props.selected}
      isDeleting={props.isDeleting}
      handleSelectScreenshot={props.handleSelectScreenshot}
      handleClickDownloadScreenshot={handleClickDownloadScreenshot}
      downloadProgress={downloadProgress}
      handleClickShowScreenshot={handleClickShowScreenshot}
    />
  );
};

ScreenshotRowContainer.propTypes = {
  token: PropTypes.string.isRequired,
  screenshot: screenshotPropTypes.isRequired,
  selected: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  handleSelectScreenshot: PropTypes.func.isRequired,
  aScreenshotIsLoading: PropTypes.bool.isRequired,
  setAScreenshotIsLoading: PropTypes.func.isRequired,
  handleDisplayScreenshot: PropTypes.func.isRequired,
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
});

export default connect(mapStateToProps)(ScreenshotRowContainer);
