/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import RunGenerator from './RunGenerator';
import buildDataTables from '../DataGeneratorAlgorithm';

const RunGeneratorContainer = (props) => {
  const [outputFiles, setOutputFiles] = React.useState({});

  const numberOfRows = 52 * props.categories.Products.numberOfItems * props.categories.Retailers.numberOfItems * props.categories.Years.numberOfItems;

  const handleGenerateData = () => {
    const categories = props.parameterCategories;
    const { volumesTable, eventsTable, financialsTable } = buildDataTables(categories);
    setOutputFiles({ volumesTable, eventsTable, financialsTable });
  };

  return <RunGenerator numberOfRows={numberOfRows} handleGenerateData={handleGenerateData} data={outputFiles} />;
};

const mapStateToProps = (store) => ({
  parameterCategories: store.dataGeneratorReducer.dataGeneratorParameters.categories,
});

export default connect(mapStateToProps)(RunGeneratorContainer);
