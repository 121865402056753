import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chartGroupPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';
import ChartParametersHeader from './ChartParametersHeader';

const ChartParametersHeaderContainer = (props) => {
  const [chartNameChangePopoverIsOpen, setChartNameChangePopoverIsOpen] = React.useState(false);
  const [enteredChartName, setEnteredChartName] = React.useState(props.chartGroup.name);

  const handleChangeChartType = (chartType) => {
    if (chartType !== props.chartGroup.chartParameters.type) props.changeChartType(props.dashboardID, props.chartGroupID, chartType);
  };

  const handleChangeChartName = () => {
    setChartNameChangePopoverIsOpen(false);
    props.renameChart(props.dashboardID, props.chartGroupID, enteredChartName);
  };

  return (
    <ChartParametersHeader
      chartGroup={props.chartGroup}
      handleChangeChartType={handleChangeChartType}
      chartNameChangePopoverIsOpen={chartNameChangePopoverIsOpen}
      setChartNameChangePopoverIsOpen={setChartNameChangePopoverIsOpen}
      enteredChartName={enteredChartName}
      setEnteredChartName={setEnteredChartName}
      handleChangeChartName={handleChangeChartName}
    />
  );
};

ChartParametersHeaderContainer.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  chartGroup: chartGroupPropTypes.isRequired,
  changeChartType: PropTypes.func.isRequired,
  renameChart: PropTypes.func.isRequired,
};

const mapStateToProps = (store, ownProps) => ({
  chartGroup: store.titanReducer.dashboards[ownProps.dashboardID].chartGroups[ownProps.chartGroupID],
});

const mapDispatchToProps = (dispatch) => ({
  changeChartType: (dashboardID, chartGroupID, chartType) => dispatch(chartGroupsActions.changeChartType(dashboardID, chartGroupID, chartType)),
  renameChart: (dashboardID, chartGroupID, name) => dispatch(chartGroupsActions.renameChart(dashboardID, chartGroupID, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartParametersHeaderContainer);
