import { endOfWeek, startOfWeek, subWeeks, subMonths, endOfMonth, startOfMonth, subDays } from 'date-fns';
import { demoMode } from 'utils/appDefinitions';

const parksViewIds = demoMode
  ? {
    Carrefour: 42217826,
    'El Corte Ingles': 13167647,
    Auchan: 11273708,
    Sainsbury: 13165787,
    Tesco: 13166021,
    'E.Leclerc': 13167356,
    Lidl: 13227256,
    Rewe: 72230829,
    Aldi: 13227317,
    Kaufland: 75536980,
    Morrisons: 13226566,
    Edeka: 13167439,
    Walmart: 13167724,
  }
  : {
    'SeaWorld Rollup': 42217826,
    SWO: 13167647,
    APO: 11273708,
    DCO: 13165787,
    BGT: 13166021,
    AIT: 13167356,
    SWC: 13227256,
    APC: 72230829,
    SWT: 13227317,
    APT: 75536980,
    BGW: 13226566,
    WCW: 13167439,
    SPL: 13167724,
  };

const layoutTypes = {
  ROWS: 'list',
  ROWS_SUMMARY: 'list-detail-view',
  COLUMNS_SUMMARY: 'list-columns',
  TABLE: 'th-list',
};

const MAX_DASHBOARD_NAME_LENGTH = 30;

const GA_URL = 'https://analyticsreporting.googleapis.com/v4/reports:batchGet';

const GARetryChecker = (failureCount, error) => {
  // eslint-disable-next-line no-console
  if (process.env.REACT_APP_STAGE !== 'production') console.log(`Retry checker - failureCount: ${failureCount} (error code: ${error.code})`);
  return failureCount < 6 && error.code === 429;
};
const titanGAUseQueryParams = {
  refetchOnWindowFocus: false, refetchOnMount: false, retry: GARetryChecker, staleTime: 60000,
};
const useQueryParams = titanGAUseQueryParams;

// prettier-ignore
const buildDateRange_pastDays = (subtractStart, subtractEnd) => [
  subDays(new Date(), subtractStart),
  subDays(new Date(), subtractEnd),
];

// prettier-ignore
const buildDateRange_weeksAgo = (nbWeeks) => [
  startOfWeek(subWeeks(new Date(), nbWeeks), { weekStartsOn: 1 }),
  endOfWeek(subWeeks(new Date(), nbWeeks), { weekStartsOn: 1 }),
];

// prettier-ignore
const buildDateRange_monthsAgo = (nbMonths) => [
  startOfMonth(subMonths(new Date(), nbMonths), { weekStartsOn: 1 }),
  endOfMonth(subMonths(new Date(), nbMonths), { weekStartsOn: 1 }),
];

const datePickerShortcuts = [
  {
    dateRange: buildDateRange_pastDays(7, 1),
    label: 'last 7 days',
  },
  {
    dateRange: buildDateRange_pastDays(30, 1),
    label: 'last 30 days',
  },
  {
    dateRange: buildDateRange_pastDays(90, 1),
    label: 'last 90 days',
  },
  {
    dateRange: buildDateRange_pastDays(180, 1),
    label: 'last 180 days',
  },
  {
    dateRange: buildDateRange_weeksAgo(1),
    label: 'last week',
  },
  {
    dateRange: buildDateRange_monthsAgo(1),
    label: 'last month',
  },
  {
    dateRange: buildDateRange_monthsAgo(2),
    label: '2 months ago',
  },
];

export {
  parksViewIds, MAX_DASHBOARD_NAME_LENGTH, GA_URL, layoutTypes, useQueryParams, titanGAUseQueryParams, datePickerShortcuts,
};
