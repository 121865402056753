import React from 'react';
import PropTypes from 'prop-types';
import { ETLparametersPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import { Switch } from '@blueprintjs/core';

const CannibalizationParameters = (props) => (
  <div className="ETLParamDefinition">
    <h4>Cannibalization</h4>
    <div className="ETLparam">
      <Switch
        checked={props.datasetETLparameters.cannibalization.enabled}
        label="Enabled"
        onChange={() => props.handleChangeETLparameter('cannibalization', null, 'enabled', !props.datasetETLparameters.cannibalization.enabled)}
      />
    </div>
  </div>
);
CannibalizationParameters.propTypes = {
  datasetETLparameters: ETLparametersPropTypes.isRequired,
  handleChangeETLparameter: PropTypes.func.isRequired,
};

export default CannibalizationParameters;
