import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as titanPropTypes from 'AppModules/Titan/Definitions/propTypes';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';
import AttributesGroup from './AttributesGroup';

const AttributesGroupContainer = (props) => {
  const handleAddAttribute = () => {
    props.addAttribute(props.dashboardID, props.chartGroupID, props.parameterGroup.type);
  };

  return (
    <AttributesGroup
      dashboardID={props.dashboardID}
      chartGroupID={props.chartGroupID}
      parameterGroupID={props.parameterGroupID}
      chartType={props.chartType}
      parameterGroup={props.parameterGroup}
      selectedAttributes={props.selectedAttributes}
      handleAddAttribute={handleAddAttribute}
    />
  );
};

AttributesGroupContainer.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  parameterGroupID: PropTypes.number.isRequired,
  chartType: titanPropTypes.chartTypePropTypes.isRequired,
  parameterGroup: titanPropTypes.parameterGroupPropTypes.isRequired,
  selectedAttributes: PropTypes.arrayOf(titanPropTypes.attributePropTypes).isRequired,
  addAttribute: PropTypes.func.isRequired,

};

const mapStateToProps = (store, ownProps) => ({
  selectedAttributes: store.titanReducer.dashboards[ownProps.dashboardID].chartGroups[ownProps.chartGroupID].chartParameters[ownProps.parameterGroup.type],
});

const mapDispatchToProp = (dispatch) => ({
  addAttribute: (dashboardID, chartGroupID, attributeType) => {
    dispatch(chartGroupsActions.addAttribute(dashboardID, chartGroupID, attributeType));
  },
});

export default connect(mapStateToProps, mapDispatchToProp)(AttributesGroupContainer);
