// Width of the Control Panel of charts
const controlPanelWidth = 250;

// How often the datasets pulse check on the Data/Run page is run
const datasetsPulseFrequency_slow = 5000;
const datasetsPulseFrequency_fast = 500;

// Mapping table for what progress corresponds to each status of the Run
const runProgressByStatus = {
  'Starting Run': 0,
  'Connecting to RDS database': 3,
  'Loading data files': 5,
  'Merging data files': 20,
  'Listing unique values': 30,
  'Calculating baselines': 35,
  'Calculating metrics': 70,
  'Finalizing dataset': 95,
  'Successfully completed': 100,
};

// if RDS is doing a cold start, connecting to RDS will be long
const runProgressByStatus_cold = {
  'Starting Run': 0,
  'Connecting to RDS database': 3,
  'Loading data files': 50,
  'Merging data files': 60,
  'Listing unique values': 65,
  'Calculating baselines': 70,
  'Calculating metrics': 85,
  'Finalizing dataset': 95,
  'Successfully completed': 100,
};

export { controlPanelWidth, datasetsPulseFrequency_slow, datasetsPulseFrequency_fast, runProgressByStatus, runProgressByStatus_cold };
