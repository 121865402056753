import { Intent } from '@blueprintjs/core';


const rules = [
  {
    message: 'None of the controls can be empty',
    chartTypes: ['lines-bars', 'vertical-lines-bars'],
    intent: Intent.DANGER,
    validator: (params) => ['metrics', 'dimensions', 'filters', 'comparisons'].reduce((categoryIsValid, category) => {
      const thisCategoryIsValid = params[category].length === 0 ? true : (params[category].reduce((valid, param) => {
        const thisParameterIsValid = !!param.name;
        return valid && thisParameterIsValid;
      }, true));
      return (categoryIsValid && thisCategoryIsValid);
    }, true),
  },
  {
    message: 'There cannot be more than one \'axis\' dimension',
    chartTypes: ['lines-bars', 'vertical-lines-bars'],
    intent: Intent.DANGER,
    validator: (params) => ['metrics', 'dimensions'].reduce((categoryIsValid, category) => {
      const thisCategoryIsValid = params[category].filter((attr) => attr.dimensionDisplayType === 'axis').length < 2;
      return (categoryIsValid && thisCategoryIsValid);
    }, true),
  },
  {
    message: 'There must be an \'axis\' dimension',
    chartTypes: ['lines-bars', 'vertical-lines-bars'],
    intent: Intent.DANGER,
    validator: (params) => ['dimensions'].reduce((categoryIsValid, category) => {
      const thisCategoryIsValid = params[category].filter((attr) => attr.dimensionDisplayType === 'axis').length === 1;
      return (categoryIsValid && thisCategoryIsValid);
    }, true),
  },
  {
    message: 'Bar chart metrics must be on the same axis (primary or secondary).',
    chartTypes: ['lines-bars', 'vertical-lines-bars'],
    intent: Intent.DANGER,
    validator: (params) => ['metrics', 'dimensions'].reduce((categoryIsValid, category) => {
      const barAxisTypes = params[category].filter((attr) => attr.chartType === 'bars').map((attr) => attr.axisType);
      const thisCategoryIsValid = barAxisTypes.every((axis) => axis === 'primary') || barAxisTypes.every((axis) => axis === 'secondary');
      return (categoryIsValid && thisCategoryIsValid);
    }, true),
  },
  {
    message: 'Comparison cannot be of type \'Line Types\' if there is a metric displayed as bars',
    chartTypes: ['lines-bars', 'vertical-lines-bars'],
    intent: Intent.DANGER,
  },
  {
    message: 'Metrics of type \'Stacked Bars\' or \'Full Stacked Bars\' can only be used if there a Dimension of type \'Color\'',
    chartTypes: ['lines-bars', 'vertical-lines-bars'],
    intent: Intent.DANGER,
  },
  {
    message: 'Bar charts cannot be grouped by line types',
    chartTypes: ['lines-bars', 'vertical-lines-bars'],
    intent: Intent.DANGER,
  },
  // {
  //   intent: Intent.WARNING,
  //   message: 'If multiple dimensions to group by, the settings for Max displayed, Show Other and Show Total will be taken from the ',
  // },
  // {
  //   intent: Intent.WARNING,
  //   message: `When showing bars on the primary axis and lines on the secondary axis,
  //             the lines will appear hidden behind the bars. Switch the axes to fix the issue.`,
  // },
];

const validateParameters = (params) => {
  // console.log(params);
  for (const rule of rules.filter((el) => el.chartTypes.includes(params.type))) {
    if (!rule.validator) return { valid: true, intent: Intent.PRIMARY }; // while not all rules are implemented
    if (!rule.validator(params)) return ({ valid: false, message: rule.message, intent: rule.intent });
  }
  return { valid: true, intent: Intent.PRIMARY };
};

export {
  validateParameters,
  rules,
};
