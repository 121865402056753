import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { chartTypePropTypes } from 'AppModules/Titan/Definitions/propTypes';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';
import ChartParameters from './ChartParameters';

const ChartParametersContainer = ({ dashboardID, chartGroupID, ...props }) => {
  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  // When mounting: validate parameters
  React.useEffect(() => {
    stableDispatch(chartGroupsActions.validateParameters(dashboardID, chartGroupID));
  }, [stableDispatch, dashboardID, chartGroupID]);

  return (
    <ChartParameters
      dashboardID={dashboardID}
      chartGroupID={chartGroupID}
      chartType={props.chartType}
    />
  );
};

ChartParametersContainer.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  chartType: chartTypePropTypes.isRequired,
};

export default ChartParametersContainer;
