import React from 'react';
import { Button } from '@blueprintjs/core';
import './loadingPage.css';

const LoadingPage = () => (
  <div className="loadingPage bp3-dark">
    <div className="loadingPageBody">
      <p>Signing in...</p>
      <Button large loading />
    </div>
    <footer className="buildNumber">
      {`Build: ${process.env.REACT_APP_BUILD_NUMBER || 'local'}`}
    </footer>
  </div>
);

export default LoadingPage;
