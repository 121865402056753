import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dashboardHeaderActions from 'AppModules/Titan/Actions/dashboardHeaderActions';
import DashboardHeader from './DashboardHeader';

const DashboardHeaderContainer = (props) => {
  const handleChangeDescription = (newDescription) => {
    props.changeDashboardDescription(props.token, props.dashboardName, newDescription);
  };

  return (
    <DashboardHeader
      dashboardName={props.dashboardName}
      dashboardDescription={props.dashboardDescription}
      handleChangeDescription={handleChangeDescription}
    />
  );
};

DashboardHeaderContainer.propTypes = {
  token: PropTypes.string.isRequired,
  dashboardName: PropTypes.string.isRequired,
  dashboardDescription: PropTypes.string.isRequired,
  changeDashboardDescription: PropTypes.func.isRequired,
};

const mapStateToProps = (store, ownProps) => ({
  token: store.authReducer.token,
  dashboardName: store.titanReducer.dashboards[ownProps.dashboardID].name,
  dashboardDescription: store.titanReducer.dashboards[ownProps.dashboardID].description,
});

const mapDispatchToProps = (dispatch) => ({
  renameDashboard: (token, oldDashboardName, newDashboardName) => {
    dispatch(dashboardHeaderActions.renameDashboard(token, oldDashboardName, newDashboardName));
  },
  changeDashboardDescription: (token, dashboardName, newDescription) => {
    dispatch(dashboardHeaderActions.changeDashboardDescription(token, dashboardName, newDescription));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeaderContainer);
