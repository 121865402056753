import React from 'react';
import PropTypes from 'prop-types';
import EditableContent from 'Components/EditableContent/EditableContent';
import './DashboardHeader.css';

const DashboardHeader = (props) => (
  <div className="DashboardHeader">
    <div className="DashboardTitle">
      <h2>{props.dashboardName}</h2>
    </div>
    <div className="DashboardDescription">
      <EditableContent onChange={props.handleChangeDescription} text={props.dashboardDescription} placeholder="Edit description..." />
    </div>
  </div>
);

DashboardHeader.propTypes = {
  dashboardName: PropTypes.string.isRequired,
  dashboardDescription: PropTypes.string.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
};

export default DashboardHeader;
