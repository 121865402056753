import React from 'react';
import PropTypes from 'prop-types';
import { Button, Alert, Intent, Card } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import './DashboardItem.css';

const DashboardItem = (props) => (
  <Card className="dashboardItemContainer" interactive>
    <div className="dashboardItem">
      <div className="dashboardItemHeader">
        <div className="dashboardItemName">
          <Link to={`/Titan/Dashboard/${encodeURI(props.name)}`}>
            <h3>{props.name}</h3>
          </Link>
        </div>
        <div className="dashboardItemButtons">
          <Button className="renameDashboardButton" icon="edit" intent={Intent.PRIMARY} onClick={props.handleRenameDashboard} />
          <Button className="removeDashboardButton" icon="trash" intent={Intent.DANGER} onClick={props.handleRemoveDashboard} />
        </div>
      </div>
      <div className="dashboardItemDescription">
        <Link to={`/Titan/Dashboard/${encodeURI(props.name)}`}>
          <p>{props.description}</p>
        </Link>
      </div>
    </div>
    <Alert
      isOpen={props.removeAlertIsOpen}
      cancelButtonText="Cancel"
      confirmButtonText="Yes, delete"
      icon="trash"
      intent={Intent.DANGER}
      onCancel={props.cancelRemoveDashboard}
      onConfirm={props.confirmRemoveDashboard}
      canEscapeKeyCancel
      canOutsideClickCancel
    >
      Are you sure you want to delete the dashboard
      {' '}
      <i>{props.name}</i>
      ? This action cannot be undone.
    </Alert>
    <Alert
      isOpen={props.renameAlertIsOpen}
      cancelButtonText="Cancel"
      confirmButtonText="Rename"
      icon="edit"
      intent={Intent.PRIMARY}
      onCancel={props.cancelRenameDashboard}
      onConfirm={props.confirmRenameDashboard}
      canEscapeKeyCancel
      canOutsideClickCancel
    >
      <input
        style={{ fontSize: '1.2rem', width: '300px', marginTop: '5px' }}
        type="text"
        value={props.enteredDashboardName}
        onChange={props.handleEnteredDashboardNameChange}
        onKeyDown={(e) => e.keyCode === 13 && props.confirmRenameDashboard()}
      />
    </Alert>
  </Card>
);

DashboardItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleRemoveDashboard: PropTypes.func.isRequired,
  removeAlertIsOpen: PropTypes.bool.isRequired,
  cancelRemoveDashboard: PropTypes.func.isRequired,
  confirmRemoveDashboard: PropTypes.func.isRequired,
  handleRenameDashboard: PropTypes.func.isRequired,
  renameAlertIsOpen: PropTypes.bool.isRequired,
  enteredDashboardName: PropTypes.string.isRequired,
  handleEnteredDashboardNameChange: PropTypes.func.isRequired,
  cancelRenameDashboard: PropTypes.func.isRequired,
  confirmRenameDashboard: PropTypes.func.isRequired,
};

export default DashboardItem;
