/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Alert } from '@blueprintjs/core';

const ButtonWithWarning = ({ buttonText, buttonIcon, buttonIntent, warningBodyText, warningButtonText, warningIcon, warningIntent, onConfirm, ...restProps }) => {
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);

  const handleClickButton = () => {
    setAlertIsOpen(true);
  };

  const handleCancel = () => {
    setAlertIsOpen(false);
  };

  const handleConfirm = () => {
    setAlertIsOpen(false);
    onConfirm();
  };

  return (
    <>
      <Button
        text={buttonText}
        onClick={handleClickButton}
        intent={buttonIntent}
        icon={buttonIcon || null}
        {...restProps}
      />
      <Alert
        isOpen={alertIsOpen}
        cancelButtonText="Cancel"
        confirmButtonText={warningButtonText}
        icon={warningIcon}
        intent={warningIntent}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        canEscapeKeyCancel
        canOutsideClickCancel
      >
        {warningBodyText}
      </Alert>
    </>
  );
};

ButtonWithWarning.propTypes = {
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonIntent: PropTypes.string,
  warningBodyText: PropTypes.string.isRequired,
  warningButtonText: PropTypes.string.isRequired,
  warningIcon: PropTypes.string.isRequired,
  warningIntent: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ButtonWithWarning.defaultProps = {
  buttonIcon: '',
  buttonText: '',
  buttonIntent: null,
};

export default ButtonWithWarning;
