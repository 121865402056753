import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { Toaster, Intent } from '@blueprintjs/core';
import Titan from './Titan';
// import TrafficConversionSalesContainer from './Dashboards/TrafficConversionSalesContainer';
import DashboardContainer from './Components/Dashboard/DashboardContainer';
import titanOverviewActions from './Actions/titanOverviewActions';

const GA_toaster = Toaster.create();

const TitanContainer = (props) => {
  const [GA_signingIn, setGA_signingIn] = React.useState(false);
  const [GA_signedIn, setGA_signedIn] = React.useState(false);

  // GA sign in/out Callbacks
  const GA_onRequest = () => {
    setGA_signingIn(true);
  };
  const GA_siginSuccess = (response) => {
    props.setGA_token(response.accessToken);
    setGA_signingIn(false);
    setGA_signedIn(true);
  };
  const GA_siginFailure = (response) => {
    setGA_signingIn(false);
    GA_toaster.show({
      message: `Error login in Google: ${JSON.stringify(response)}`,
      intent: Intent.DANGER,
      icon: 'warning-sign',
    });
  };

  const GA_logoutSuccess = () => {
    setGA_signedIn(false);
  };


  const WaitingForGALogIn = () => <p>Please sign into Google</p>;

  return (
    <div className="titan">
      <div className="GoogleSignIn" style={{ float: 'right', margin: '10px' }}>
        {GA_signedIn
          ? (
            <GoogleLogout
              clientId="728662031489-gmuffrn1t5837h3n5gi8ascimu0q6b52.apps.googleusercontent.com"
              buttonText="Log out"
              onLogoutSuccess={GA_logoutSuccess}
              theme="dark"
            />
          )
          : (
            <GoogleLogin
              clientId="728662031489-gmuffrn1t5837h3n5gi8ascimu0q6b52.apps.googleusercontent.com"
              scope="https://www.googleapis.com/auth/analytics.readonly"
              buttonText={GA_signingIn ? 'Signing in...' : 'Sign in'}
              onRequest={GA_onRequest}
              onSuccess={GA_siginSuccess}
              onFailure={GA_siginFailure}
              cookiePolicy="single_host_origin"
              isSignedIn
              theme="dark"
              style={{ margin: '5px' }}
            />
          )}
      </div>
      <Switch>
        <Route
          exact
          path="/Titan/Dashboards"
          component={() => (
            <Titan
              token={props.token}
              dashboardDetails={props.dashboardDetails}
              addDashboard={props.addDashboard}
              removeDashboard={props.removeDashboard}
              renameDashboard={props.renameDashboard}
            />
          )}
        />
        {props.dashboardDetails.map((dashboardDetails) => (
          <Route
            key={dashboardDetails.name}
            exact
            path="/Titan/Dashboard/:dashboardName"
            component={GA_signedIn ? DashboardContainer : WaitingForGALogIn}
          />
        ))}
        {/* <Route
          exact
          path="/Titan/Traffic-Conversion-Sales"
          component={
            GA_signedIn
              ? () => <TrafficConversionSalesContainer GA_tokens={GA_tokens} />
              : WaitingForGALogIn
          }
        />
        <Route exact path="/Titan/Salesbyproducts" component={Salesbyproducts} />
        <Route exact path="/Titan/Advancedreport" component={Advancedreport} /> */}
      </Switch>
    </div>
  );
};

TitanContainer.propTypes = {
  token: PropTypes.string.isRequired,
  setGA_token: PropTypes.func.isRequired,
  dashboardDetails: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  })).isRequired,
  addDashboard: PropTypes.func.isRequired,
  removeDashboard: PropTypes.func.isRequired,
  renameDashboard: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
  dashboardDetails: store.titanReducer.dashboards.map((dashboard) => ({ name: dashboard.name, description: dashboard.description })),
});

const mapDispatchToProps = (dispatch) => ({
  setGA_token: (token) => dispatch(titanOverviewActions.setGA_token(token)),
  addDashboard: (token) => dispatch(titanOverviewActions.addDashboard(token)),
  removeDashboard: (token, dashboardName) => dispatch(titanOverviewActions.removeDashboard(token, dashboardName)),
  renameDashboard: (token, oldDashboardName, newDashboardName) => dispatch(titanOverviewActions.renameDashboard(token, oldDashboardName, newDashboardName)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TitanContainer);
