import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import './App.css';

import { FocusStyleManager } from '@blueprintjs/core';
import { appName } from './utils/appDefinitions';

import { useAuth0 } from './Auth/react-auth0-spa';

import LoginPage from './Auth/LoginPage/LoginPage';
import LoadingPage from './Auth/LoadingPage/LoadingPage';
import MainContainer from './Components/MainContainer/MainContainer';
// import { ReactQueryDevtoolsPanel } from 'react-query-devtools';

const App = ({ stage }) => {
  const { isAuthenticated, getTokenSilently } = useAuth0();
  const { pathname } = useLocation();

  const { token } = useSelector((store) => store.authReducer);
  const { isDarkMode } = useSelector((store) => store.userReducer.userSettings);

  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  // When mounting: set the document title
  React.useEffect(() => {
    document.title = appName[stage];
  }, [stage]);

  // When authenticated: set token in Redux store
  React.useEffect(() => {
    const setToken = async () => {
      const accessToken = await getTokenSilently();
      stableDispatch({ type: 'SET_TOKEN', token: accessToken });
    };
    if (isAuthenticated) setToken();
  }, [isAuthenticated, getTokenSilently, stableDispatch]);

  // if (isAuthenticated !== undefined && token) {
  //   document.body.style = 'background: #F5F8FA';
  // } else {
  //   document.body.style = 'background: #293742';
  // }

  // When dark mode changes, apply background color of body
  React.useEffect(() => {
    document.body.className = isDarkMode ? 'bp3-dark' : 'bp3-light';
    // const style = window.getComputedStyle(document.getElementsByClassName('App')[0]);
    // document.body.style = `background: ${style.getPropertyValue('--appBackground').trim()};`;
  }, [isDarkMode]);

  // Remove focus outline unless using tab
  FocusStyleManager.onlyShowFocusOnTabs();

  const componentWhenAuthenticated = () => {
    // Get redicrectTo if it exists
    const redirectTo = sessionStorage.getItem('redirectTo');
    if (redirectTo) {
      sessionStorage.removeItem('redirectTo');
      return <Redirect to={redirectTo} />;
    }
    return <MainContainer />;
  };

  const componentWhenNotAuthenticated = <Redirect to={{ pathname: '/login', state: { redirectTo: pathname } }} />;

  return (
    <div className="App">
      {isAuthenticated === undefined || (isAuthenticated && !token) ? ( // undefined means it's currently logging in (otherwise it's either true or false)
        <LoadingPage />
      ) : (
        <Switch>
          <Route exact path="/login">
            {isAuthenticated ? <Redirect to="/" /> : <LoginPage />}
          </Route>
          <Route path="/">
            {isAuthenticated ? componentWhenAuthenticated : componentWhenNotAuthenticated}
          </Route>
        </Switch>
      )}
      {/* <ReactQueryDevtoolsPanel style={{ marginLeft: '67px' }} /> */}
    </div>
  );
};

App.propTypes = {
  stage: PropTypes.string.isRequired,
};

export default App;
