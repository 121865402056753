import cloneDeep from 'lodash.clonedeep';

const calculateChartSize = (layoutType) => {
  switch (layoutType) {
    case 'ROWS':
      return window.innerWidth - 160;
    case 'ROWS_SUMMARY':
      return 0.8 * (window.innerWidth - 160);
    case 'COLUMNS_SUMMARY':
      return 0.32 * (window.innerWidth - 160);
    default:
      return window.innerWidth - 160;
  }
};

const sortOneTrace = (trace, axisToSort, order) => {
  const traceCopy = JSON.parse(JSON.stringify(trace)); // trace should never include date objects because sorting is forbidden when date is on an axis

  // combine the x and y into a single array
  const dataTable = traceCopy.x.map((value, i) => [value, traceCopy.y[i]]);

  const indexToSortBy = axisToSort === 'x' ? 0 : 1;

  // sort the table by the right axis to sort
  const sortedTable = dataTable.sort((a, b) => {
    if (a[indexToSortBy] > b[indexToSortBy]) return order === 'asc' ? 1 : -1;
    if (a[indexToSortBy] < b[indexToSortBy]) return order === 'asc' ? -1 : 1;
    return 0;
  });

  // re-split the table into x and y property
  return { ...traceCopy, x: sortedTable.map((row) => row[0]), y: sortedTable.map((row) => row[1]) };
};

// returns the trace where the data (x & y) has been ordered such that the [axisToSort] has the same order as [expectedOrder]
const reorderTrace = (trace, axisToSort, expectedOrder) => {
  const traceCopy = JSON.parse(JSON.stringify(trace)); // trace should never include date objects because sorting is forbidden when date is on an axis
  const indexToSortBy = axisToSort === 'x' ? 0 : 1;

  // combine the x and y into a single array
  const dataTable = traceCopy.x.map((value, i) => [value, traceCopy.y[i]]);
  const reorderedDataTable = expectedOrder.map((value) => dataTable.find((row) => row[indexToSortBy] === value));

  // re-split the table into x and y property
  return { ...traceCopy, x: reorderedDataTable.map((row) => row[0]), y: reorderedDataTable.map((row) => row[1]) };
};

const sortTraces = (sortOrder, traces, chartType) => {
  if (sortOrder === 'original') {
    return (traces);
  }
  if (chartType === 'vertical-lines-bars') {
    const [firstTrace, ...otherTraces] = traces;
    const axisToSort = ['asc', 'desc'].includes(sortOrder) ? 'x' : 'y';
    const order = ['asc', 'alphabetical'].includes(sortOrder) ? 'desc' : 'asc';
    // Sort the first trace
    const newFirstTrace = sortOneTrace(firstTrace, axisToSort, order);
    // Sort the other traces based on the order of the first one
    const expectedOrder = newFirstTrace.y; // Y axis always has the category values for a vertical chart
    const newOtherTraces = otherTraces.map((otherTrace) => reorderTrace(otherTrace, 'y', expectedOrder));
    return [newFirstTrace, ...newOtherTraces];
  }
  const [firstTrace, ...otherTraces] = traces;
  const axisToSort = ['asc', 'desc'].includes(sortOrder) ? 'y' : 'x';
  const order = ['asc', 'alphabetical'].includes(sortOrder) ? 'asc' : 'desc';
  // Sort the first trace
  const newFirstTrace = sortOneTrace(firstTrace, axisToSort, order);
  // Sort the other traces based on the order of the first one
  const expectedOrder = newFirstTrace.x; // X axis always has the category values for a horizontal chart
  const newOtherTraces = otherTraces.map((otherTrace) => reorderTrace(otherTrace, 'x', expectedOrder));
  return [newFirstTrace, ...newOtherTraces];
};

const truncateTraces = (traces, params) => {
  const axisAttribute = params.dimensions.find((attr) => attr.dimensionDisplayType === 'axis');
  if (!axisAttribute) return traces; // There is not necessarily an 'axis' attribute
  if (axisAttribute.maxDisplayed === 0) return traces; // Currently 0 is equivalent to "do not truncate"

  const tracesCopy = cloneDeep(traces); // trace should never include date objects because sorting is forbidden when date is on an axis
  const truncatedTraces = tracesCopy.map((trace) => ({ ...trace, x: trace.x.slice(0, axisAttribute.maxDisplayed), y: trace.y.slice(0, axisAttribute.maxDisplayed) }));

  return truncatedTraces;
};

export {
  calculateChartSize,
  sortTraces,
  truncateTraces,
};
