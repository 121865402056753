import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Icon, Divider, Button, Intent, Switch } from '@blueprintjs/core';
import MyBreadCrumbs from 'Components/MyBreadCrumbs/MyBreadCrumbs';
import ButtonWithWarning from 'Components/ButtonWithWarning/ButtonWithWarning';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import InputsDefinitionContainer from './InputsDefinition/InputsDefinitionContainer';
import RunParametersDefinitionContainer from './RunParametersDefinition/RunParametersDefinitionContainer';
import RunDefinitionContainer from './RunDefinition/RunDefinitionContainer';
import AttributesDefinitionContainer from './AttributesDefinition/AttributesDefinitionContainer';
import './DatasetConfiguration.css';

const DatasetConfiguration = (props) => {
  const getTab = (id, name, icon, panelComponent) => (
    <Tab
      id={id}
      style={{ display: 'flex', alignItems: 'center' }}
      title={(
        <>
          <Icon icon={icon} style={{ marginRight: '10px' }} />
          {name}
        </>
      )}
      panel={panelComponent}
      disabled={props.dataset.dataSource !== 'files' && ['run-parameters', 'run'].includes(id)}
    />
  );

  return (
    <>
      <MyBreadCrumbs
        items={[
          { href: '/', text: 'Home' },
          { href: '/Data/Datasets', text: 'Datasets' },
          { href: `/Data/Dataset/${props.dataset.name}`, text: props.dataset.name },
        ]}
      />
      <div className="DatasetConfiguration PageContainer">
        <header>
          <h2>{props.dataset.name}</h2>
          <div className="rightSideButtons">
            <Switch checked={props.datasetsPulseIsOn} label="Pulse" onChange={props.handleClickDatasetPulse} />
            <Button
              icon="cloud-upload"
              text="Save"
              intent={Intent.PRIMARY}
              onClick={props.handleSaveConfiguration}
              loading={props.datasetConfigIsSaving}
            />
            <ButtonWithWarning
              buttonIcon="cloud-download"
              buttonText="Load"
                // buttonIntent={Intent.PRIMARY}
              warningBodyText="Load dataset configuration from the cloud? This will overwrite your local settings"
              warningButtonText="Yes, load"
              warningIcon="warning-sign"
              warningIntent={Intent.WARNING}
              onConfirm={props.handleLoadConfiguration}
              loading={props.datasetConfigIsLoading}
            />
          </div>
        </header>
        <Divider />
        <Tabs
          id="datasetConfigTabs"
          onChange={props.handleDatasetConfigTabChange}
          selectedTabId={props.activeTabId}
          large
        >
          {getTab('inputs', 'Inputs Definition', 'filter-list', <InputsDefinitionContainer dataset={props.dataset} />)}
          {getTab('run-parameters', 'Run Parameters', 'settings', <RunParametersDefinitionContainer dataset={props.dataset} />)}
          {getTab('run', 'Run', 'cog', <RunDefinitionContainer dataset={props.dataset} />)}
          {getTab('attributes', 'Attributes Definition', 'properties', <AttributesDefinitionContainer dataset={props.dataset} />)}
        </Tabs>
      </div>
    </>
  );
};

DatasetConfiguration.propTypes = {
  dataset: datasetPropTypes.isRequired,
  activeTabId: PropTypes.string.isRequired,
  handleDatasetConfigTabChange: PropTypes.func.isRequired,
  handleSaveConfiguration: PropTypes.func.isRequired,
  handleLoadConfiguration: PropTypes.func.isRequired,
  datasetConfigIsSaving: PropTypes.bool.isRequired,
  datasetConfigIsLoading: PropTypes.bool.isRequired,
  datasetsPulseIsOn: PropTypes.bool.isRequired,
  handleClickDatasetPulse: PropTypes.func.isRequired,
};

export default DatasetConfiguration;
