import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import MyBreadCrumbs from '../../Components/MyBreadCrumbs/MyBreadCrumbs';
import DashboardItemContainer from './Components/DashboardItem/DashboardItemContainer';
import './Titan.css';

const Titan = (props) => (
  <div className="Titan">
    <MyBreadCrumbs
      items={[
        { href: '/', text: 'Home' },
        { href: '/Titan', text: 'Titan' },
        { href: '/Titan/Dashboards', text: 'Dashboards' },
      ]}
    />
    <div className="PageContainer TitanContainer">
      <h2>Titan - Overview</h2>
      <div className="dashobardsContainer">
        {props.dashboardDetails.map((dashboardDetail) => (
          <DashboardItemContainer
            token={props.token}
            key={dashboardDetail.name}
            name={dashboardDetail.name}
            allDashboardNames={props.dashboardDetails.map((el) => el.name)}
            description={dashboardDetail.description}
            removeDashboard={props.removeDashboard}
            renameDashboard={props.renameDashboard}
          />
        ))}
      </div>
      <Button
        className="createDashboardButton"
        text="Create Dashboard"
        icon="insert"
        onClick={() => props.addDashboard(props.token)}
        intent={Intent.PRIMARY}
        large
      />
    </div>
  </div>
);

Titan.propTypes = {
  token: PropTypes.string.isRequired,
  dashboardDetails: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  })).isRequired,
  addDashboard: PropTypes.func.isRequired,
  removeDashboard: PropTypes.func.isRequired,
  renameDashboard: PropTypes.func.isRequired,
};

export default Titan;
