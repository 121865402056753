/* eslint-disable react/prop-types */
import React from 'react';
import { Button, InputGroup } from '@blueprintjs/core';
import ItemDefinition from './ItemDefinition/ItemDefinition';
import './ListCategoryDefinition.css';

const ListCategoryDefinition = (props) => {
  const { description, parameters, items } = props.category;
  return (
    <div className="PageContainer ListCategoryDefinition" style={{ height: `${items.length * 46 + 160}px` }}>
      <h3>{props.categoryName}</h3>
      <p>{description}</p>
      <div className="CategoryParamsContainer">
        <table className="categoryParamsTable" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th className="name" key="Name">
                <b>Name</b>
              </th>
              {Object.keys(parameters).map((parameterName) => (
                <th className="parameter" key={parameterName}>
                  <b>{parameterName}</b>
                </th>
              ))}
              <th className="delete" key="Delete">
                <b>Delete</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <ItemDefinition
                key={item.itemName}
                itemName={item.itemName}
                parameterValues={item.parameterValues}
                // handleChangeItemName={props.handleChangeItemName}
                handleEditItem={props.handleEditItem}
                handleRemoveItem={props.handleRemoveItem}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="addItemSection">
        <span className="addItemSectionText">Add an item:</span>
        <InputGroup
          className="addItemSectionInput"
          type="text"
          onChange={props.handleChangeNewItemName}
          onKeyPress={(e) => e.key === 'Enter' && props.handleAddItem()}
          value={props.newItemName}
        />
        <Button icon="add" onClick={props.handleAddItem} />
      </div>
    </div>
  );
};

export default ListCategoryDefinition;
