import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import FilesInputConfigurationContainer from './FilesInputConfiguration/FilesInputConfigurationContainer';
import './InputsDefinition.css';

const InputsDefinition = (props) => (
  <div className="PageContainer InputsDefinition">
    <div className="dataSource">
      <h3>Data Source</h3>
      <Button
        icon="folder-shared"
        text="Files"
        active={props.dataset.dataSource === 'files'}
        outlined="true"
        large
        onClick={() => props.handleChangeDataSource('files')}
      />
      <Button
        icon="globe-network"
        text="Google Analytics"
        active={props.dataset.dataSource === 'google-analytics'}
        outlined="true"
        large
        onClick={() => props.handleChangeDataSource('google-analytics')}
      />
    </div>
    <div className="filesConfiguration">
      {props.dataset.dataSource === 'files'
        && (
          <FilesInputConfigurationContainer
            dataset={props.dataset}
          />
        )}
    </div>
  </div>
);

InputsDefinition.propTypes = {
  dataset: datasetPropTypes.isRequired,
  handleChangeDataSource: PropTypes.func.isRequired,
};

export default InputsDefinition;
