import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Popover, Position, FormGroup, NumericInput, Switch } from '@blueprintjs/core';
import { attributeParameterPropTypes, parameterGroupPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';
import './AttributeParameters.css';
import { attributePropTypes } from '../../../../../../../../Definitions/propTypes';

const AttributeParameters = (props) => {
  const handleChangeParameter = (parameterName, parameterValue) => {
    props.changeAttributeParameter(props.dashboardID, props.chartGroupID, props.parameterGroup.type, props.attributeID, parameterName, parameterValue);
  };

  return (
    <Popover position={Position.BOTTOM_LEFT}>
      <Button icon="more" />
      <div className="attributeParametersPopup">
        {props.parameterDefinitions.map((parameter) => {
          if (parameter.type === 'number') {
            return (
              <FormGroup key={parameter.name} inline label={`${parameter.displayName}:`}>
                <NumericInput
                  min={parameter.values.from || null}
                  max={parameter.values.to || null}
                  value={props.attribute[parameter.name]}
                  onValueChange={(value) => handleChangeParameter(parameter.name, value)}
                />
              </FormGroup>
            );
          } if (parameter.type === 'boolean') {
            return (
              <Switch
                key={parameter.name}
                label={parameter.displayName}
                checked={props.attribute[parameter.name]}
                onChange={(e) => handleChangeParameter(parameter.name, e.target.checked)}
              />
            );
          }
          return null;
        })}
      </div>
    </Popover>
  );
};

AttributeParameters.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  attributeID: PropTypes.number.isRequired,
  parameterGroup: parameterGroupPropTypes.isRequired,
  attribute: attributePropTypes.isRequired,
  parameterDefinitions: PropTypes.arrayOf(attributeParameterPropTypes).isRequired,
  changeAttributeParameter: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  changeAttributeParameter: (dashboardID, chartGroupID, attributeType, attributeID, parameterName, parameterValue) => {
    dispatch(chartGroupsActions.changeAttributeParameter(dashboardID, chartGroupID, attributeType, attributeID, parameterName, parameterValue));
  },
});

export default connect(null, mapDispatchToProps)(AttributeParameters);
