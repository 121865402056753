/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
const addChart = async (token, dashboardID) =>
  // to be implemented once AWS backend is created
  null;
const removeChart = async (token, dashboardID, chartGroupID) =>
  // to be implemented once AWS backend is created
  null;
const renameChart = async (token, dashboardID, chartGroupID, name) =>
  // to be implemented once AWS backend is created
  null;
export default {
  addChart,
  removeChart,
  renameChart,
};
