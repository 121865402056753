import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import StandardPromoETLParametersContainer from './StandardPromoETLParameters/StandardPromoETLParametersContainer';
import './RunParametersDefinition.css';

const RunParametersDefinition = (props) => (
  <div className="PageContainer RunParametersDefinition">
    <div className="ETLschema">
      <h3>ETL Schema</h3>
      <div className="ETLschemaButtons">
        <Button
          icon="disable"
          large
          // intent={props.dataset.ETLschema === 'none' ? Intent.PRIMARY : Intent.NONE}
          outlined="true"
          text="None"
          onClick={() => props.handleChangeETLschema('none')}
          active={props.dataset.ETLschema === 'none'}
        />
        <Button
          icon="barcode"
          large
          // intent={props.dataset.ETLschema === 'standardPromoETL' ? Intent.PRIMARY : Intent.NONE}
          outlined="true"
          text="Standard Promo Effectiveness"
          onClick={() => props.handleChangeETLschema('standardPromoETL')}
          active={props.dataset.ETLschema === 'standardPromoETL'}
        />
      </div>
    </div>
    {props.dataset.ETLschema === 'standardPromoETL' && <StandardPromoETLParametersContainer dataset={props.dataset} />}
  </div>

);

RunParametersDefinition.propTypes = {
  dataset: datasetPropTypes.isRequired,
  handleChangeETLschema: PropTypes.func.isRequired,
};

export default RunParametersDefinition;
