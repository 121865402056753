import localforage from 'localforage';
import { Toaster, Intent } from '@blueprintjs/core';
import ganymedeActionTypes from './ganymedeActionTypes';
import ganymedeAPI from '../API/ganymedeAPI';

const errorToaster = Toaster.create();

const getPageList = (token, pageName) => async (dispatch) => {
  dispatch({ type: ganymedeActionTypes.SET_UPDATING, isUpdating: true });

  // Build pages list
  const pages = await ganymedeAPI.getPagesList(token, pageName);
  const pages_sorted = pages.sort((a, b) => a.name.localeCompare(b.name));
  dispatch({
    type: ganymedeActionTypes.UPDATE_PAGE_LIST,
    pages: pages_sorted,
  });

  dispatch({ type: ganymedeActionTypes.SET_UPDATING, isUpdating: false });
};

const updatePageParams = (pageName, parameterValues, token) => async (dispatch) => {
  await ganymedeAPI.updatePageParameters(pageName, parameterValues, token);
  dispatch({
    type: ganymedeActionTypes.UPDATE_PAGE_PARAMS,
    pageName,
    parameterValues,
  });
};

const updateScreenshots = (token, filtersSelection = []) => async (dispatch) => {
  dispatch({ type: ganymedeActionTypes.SET_SCREENSHOTS_LIST_ISUPDATING, screenshotsListIsUpdating: true });
  // get list of screenshots from server
  try {
    const screenshots = await ganymedeAPI.getScreenshots(token, filtersSelection);
    dispatch({
      type: ganymedeActionTypes.UPDATE_FILTERED_SCREENSHOTS,
      screenshots: screenshots.items,
    });
  } catch (error) {
    errorToaster.show({ message: `Error getting list of screenshots: ${error.message}`, intent: Intent.DANGER, icon: 'warning-sign' });
  }
  dispatch({ type: ganymedeActionTypes.SET_SCREENSHOTS_LIST_ISUPDATING, screenshotsListIsUpdating: false });
};

const addDateFilter = () => ({
  type: ganymedeActionTypes.ADD_DATE_FILTER,
});

const removeDateFilter = (additionalDateFilterID) => ({
  type: ganymedeActionTypes.REMOVE_DATE_FILTER,
  additionalDateFilterID,
});

const changeDateFilterType = (additionalDateFilterID, value) => ({
  type: ganymedeActionTypes.CHANGE_DATE_FILTER_TYPE,
  additionalDateFilterID,
  value,
});

const updateExplorerFilter = (filterName, filterValues) => ({
  type: ganymedeActionTypes.UPDATE_EXPLORER_FILTER,
  filterName,
  filterValues,
});

const setFiltersFromLink = (filtersSelection) => ({
  type: ganymedeActionTypes.SET_FILTERS_FROM_LINK,
  filtersSelection,
});

const downloadScreenshot = async (key, onDownloadProgress, token) => {
  try {
    const imgData = await ganymedeAPI.getImage(key, token, onDownloadProgress);
    // save imgData to localforage
    localforage.setItem(`ganymedeScreenshotData_${key}`, imgData);
    return { imgData, err: null };
  } catch (err) {
    return { err: err.message };
  }
};

export default {
  getPageList,
  updatePageParams,
  updateExplorerFilter,
  addDateFilter,
  removeDateFilter,
  changeDateFilterType,
  setFiltersFromLink,
  updateScreenshots,
  downloadScreenshot,
};
