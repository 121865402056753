import PropTypes from 'prop-types';

const fileListPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  lastModifiedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  uploadDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  selected: PropTypes.bool.isRequired,
});

const fieldPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  mappedTo: PropTypes.string,
  selected: PropTypes.bool.isRequired,
});

const inputFilePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  lastModifiedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  uploadDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  selected: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(fieldPropTypes),
});

const ETLparametersPropTypes = PropTypes.object;

const datasetPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  creationDate: PropTypes.instanceOf(Date).isRequired,
  lastRunDate: PropTypes.instanceOf(Date),
  size: PropTypes.number.isRequired,
  dataSource: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  runProgress: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(inputFilePropTypes),
  ETLschema: PropTypes.string.isRequired,
  ETLparameters: ETLparametersPropTypes,
});

const standardPromoETLDefinitionPropTypes = PropTypes.object;

const baselineMethodDefinitionPropTypes = PropTypes.object;

export {
  fieldPropTypes,
  inputFilePropTypes,
  fileListPropTypes,
  ETLparametersPropTypes,
  datasetPropTypes,
  standardPromoETLDefinitionPropTypes,
  baselineMethodDefinitionPropTypes,
};
