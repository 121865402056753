import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Popover, FormGroup, InputGroup, Position, Intent } from '@blueprintjs/core';
import { chartTypes } from 'AppModules/Titan/Definitions/chartTypes';
import { chartGroupPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import './ChartParametersHeader.css';

const ChartParametersHeader = (props) => (
  <div className="ChartParametersHeader">
    <ButtonGroup>
      {chartTypes.map((chartType) => (
        <Button
          key={chartType.name}
          icon={chartType.icon}
          large
          active={props.chartGroup.chartParameters.type === chartType.name}
          onClick={() => props.handleChangeChartType(chartType.name)}
        />
      ))}
    </ButtonGroup>
    <Popover
      popoverClassName="bp3-popover-content-sizing"
      position={Position.BOTTOM_RIGHT}
      isOpen={props.chartNameChangePopoverIsOpen}
      onInteraction={(state) => props.setChartNameChangePopoverIsOpen(state)}
    >
      <Button className="editChartNameButton" icon="edit" text="Edit Chart Name" onClick={() => props.setChartNameChangePopoverIsOpen(true)} />
      <FormGroup className="chartNamePopover" label="Chart Name" labelFor="chart-name-input">
        <InputGroup id="chart-name-input" placeholder="Enter name..." value={props.enteredChartName} onChange={(e) => props.setEnteredChartName(e.target.value)} />
        <div className="buttons">
          <Button text="Cancel" onClick={() => props.setChartNameChangePopoverIsOpen(false)} intent={Intent.DANGER} />
          <Button text="Change" onClick={props.handleChangeChartName} intent={Intent.PRIMARY} />
        </div>
      </FormGroup>
    </Popover>
  </div>
);

ChartParametersHeader.propTypes = {
  chartGroup: chartGroupPropTypes.isRequired,
  handleChangeChartType: PropTypes.func.isRequired,
  handleChangeChartName: PropTypes.func.isRequired,
  enteredChartName: PropTypes.string.isRequired,
  setEnteredChartName: PropTypes.func.isRequired,
  chartNameChangePopoverIsOpen: PropTypes.bool.isRequired,
  setChartNameChangePopoverIsOpen: PropTypes.func.isRequired,
};

export default ChartParametersHeader;
