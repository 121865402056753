import titanActionTypes from './titanActionTypes';
import titanOverviewAPI from '../API/titanOverviewAPI';

const renameDashboard = (token, oldDashboardName, newDashboardName) => async (dispatch) => {
  await titanOverviewAPI.renameDashboard(token, oldDashboardName, newDashboardName);
  dispatch({
    type: titanActionTypes.RENAME_DASHBOARD,
    oldDashboardName,
    newDashboardName,
  });
};

const changeDashboardDescription = (token, dashboardName, newDescription) => async (dispatch) => {
  await titanOverviewAPI.changeDashboardDescription(token, dashboardName, newDescription);
  dispatch({
    type: titanActionTypes.CHANGE_DASHBOARD_DESCRIPTION,
    dashboardName,
    newDescription,
  });
};

export default {
  renameDashboard,
  changeDashboardDescription,
};
