
/* eslint-disable no-restricted-syntax */
const allowedCharacters = '!#$%&()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_abcdefghijklmnopqrstuvwxyz{|}~';

const validateDatasetName = (name) => {
  for (const character of name) {
    if (allowedCharacters.indexOf(character) === -1) {
      return false;
    }
  }
  return true;
};

const dateReviver = (key, value) => {
  if (['from', 'to', 'minAllowed', 'maxAllowed'].includes(key) && typeof value === 'string' && value.length > 10 && value.substr(-1) === 'Z') {
    return new Date(value);
  }
  return value;
};

const formatDate = (rawDate) => {
  const date = new Date(rawDate);
  return `${(`0${date.getDate()}`).slice(-2)}-${(`0${date.getMonth() + 1}`).slice(-2)}-${date.getFullYear()}`;
};

const formatFileSize = (size) => {
  if (!size) return '';
  // eslint-disable-next-line no-nested-ternary
  const newSize = size < 1e6
    ? `${Math.round(size / 1e2) / 10} KB`
    : size < 1e9
      ? `${Math.round(size / 1e5) / 10} MB`
      : `${Math.round(size / 1e8) / 10} GB`;
  return newSize;
};

const chunkArray = (array = [], chunkSize) => (array.length ? [array.slice(0, chunkSize), ...chunkArray(array.slice(chunkSize), chunkSize)] : []);

const addSuffixIfNecessary = (name, existingNames) => {
  let alreadyExists = existingNames.includes(name);
  let newName = name;
  if (alreadyExists) {
    let iterator = 0;
    while (alreadyExists) {
      iterator += 1;
      newName = `${name} (${iterator})`;
      alreadyExists = existingNames.includes(newName);
    }
  }
  return newName;
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getNextArrayValue = (array, currentValue) => {
  const currentValueIndex = array.indexOf(currentValue);
  if (currentValueIndex === -1) throw Error(`Error getting next array value: unknown value '${currentValue}'`);
  return array[(currentValueIndex + 1) % array.length];
};

const capitalizeWord = (text) => text.charAt(0).toUpperCase() + text.slice(1);

const capitalize = (text) => text.split('-').map((str) => capitalizeWord(str)).join(' ');

const extractQueryStringParams = (str) => {
  const search = str.charAt(0) === '?' ? str.slice(1) : str;
  return JSON.parse(`{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => (key === '' ? value : decodeURIComponent(value)));
};

const encodeURIobject = (obj) => {
  const str = [];
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join('&');
};

export {
  dateReviver,
  validateDatasetName,
  formatDate,
  formatFileSize,
  sleep,
  chunkArray,
  addSuffixIfNecessary,
  getNextArrayValue,
  capitalize,
  extractQueryStringParams,
  encodeURIobject,
};
