/* eslint-disable no-param-reassign */
import produce from 'immer';
import uploadActionTypes from '../Actions/uploadActionTypes';

const initialState = {
  filesToUpload: [],
  fileList: [],
};

const uploadReducer = produce((draft = initialState, action) => {
  let newFilesToUpload = [];
  const filesToAdd = [];
  switch (action.type) {
    case uploadActionTypes.UPDATE_FILE_LIST:
      draft.fileList = action.fileList;
      break;


    case uploadActionTypes.ADD_FILES_TO_UPLOAD:
      // 1. Replace files that are already in the state
      for (const file of draft.filesToUpload) {
        if (action.files.map((e) => e.fileBody.name).includes(file.fileBody.name)) {
          const newFile = action.files.filter((payloadFile) => file.fileBody.name === payloadFile.fileBody.name)[0];
          newFilesToUpload.push(newFile);
        } else {
          newFilesToUpload.push(file);
        }
      }
      // 2, add the files that are not in the state
      for (const payloafFile of action.files) {
        if (!newFilesToUpload.map((e) => e.fileBody.name).includes(payloafFile.fileBody.name)) filesToAdd.push(payloafFile);
      }
      newFilesToUpload = [...newFilesToUpload, ...filesToAdd];
      draft.filesToUpload = newFilesToUpload;
      break;


    case uploadActionTypes.REMOVE_FILES_TO_UPLOAD:
      draft.filesToUpload = [...draft.filesToUpload.filter((file) => file.fileBody.name !== action.fileName)];
      break;


    case uploadActionTypes.UPDATE_FILE_PARAMETER:
      draft.fileList.find((file) => file.name === action.fileName)[action.parameterName] = action.parameterValue;
      break;


    case uploadActionTypes.BULK_UPDATE_FILE_PARAMETER:
      for (const fileName of action.fileNames) {
        draft.fileList.find((file) => file.name === fileName)[action.parameterName] = action.parameterValue;
      }
      break;

    case uploadActionTypes.SET_FILE_IS_DELETING:
      draft.fileList.find((file) => file.name === action.fileName).isDeleting = true;
      break;

    default:
      return draft;
  }
  return draft;
});

export default uploadReducer;
