import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import { filterPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import FilterContainer from './Filter/FilterContainer';
import './FiltersGroup.css';

const FiltersGroup = (props) => (
  <div className="parametersGroup">
    <span className="parametersGroupHeader">For What?</span>
    {props.chartFilters.map((filter, filterID) => (
      <FilterContainer
        key={filterID.toString()}
        dashboardID={props.dashboardID}
        chartGroupID={props.chartGroupID}
        filterID={filterID}
        filter={filter}
        chartFilters={props.chartFilters}
      />
    ))}
    <div className="addButtonContainer">
      <Button
        className="addButton"
        text=""
        icon="insert"
        intent={Intent.PRIMARY}
        onClick={props.handleAddFilter}
      />
    </div>
  </div>
);

FiltersGroup.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  chartFilters: PropTypes.arrayOf(filterPropTypes).isRequired,
  handleAddFilter: PropTypes.func.isRequired,
};

export default FiltersGroup;
