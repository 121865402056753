import React from 'react';
import PropTypes from 'prop-types';
import { Toaster, Intent } from '@blueprintjs/core';
import DashboardItem from './DashboardItem';
import { MAX_DASHBOARD_NAME_LENGTH } from '../../util/constants';

const dashboardNameToaster = Toaster.create();

const DashboardItemContainer = (props) => {
  const [removeAlertIsOpen, setRemoveAlertIsOpen] = React.useState(false);
  const [renameAlertIsOpen, setRenameAlertIsOpen] = React.useState(false);
  const [enteredDashboardName, setEnteredDashboardName] = React.useState(props.name);

  const handleRemoveDashboard = () => {
    setRemoveAlertIsOpen(true);
  };

  const cancelRemoveDashboard = () => {
    setRemoveAlertIsOpen(false);
  };

  const confirmRemoveDashboard = () => {
    props.removeDashboard(props.token, props.name);
  };

  const handleRenameDashboard = () => {
    setRenameAlertIsOpen(true);
  };

  const handleEnteredDashboardNameChange = (e) => {
    setEnteredDashboardName(e.target.value);
  };

  const cancelRenameDashboard = () => {
    setRenameAlertIsOpen(false);
    setEnteredDashboardName(props.name);
  };

  const confirmRenameDashboard = () => {
    if (enteredDashboardName === props.name) {
      setRenameAlertIsOpen(false);
    }
    // Validate new name
    if (props.allDashboardNames.filter((name) => name !== props.name).includes(enteredDashboardName)) {
      // check if the entered name is in the array of dashboard names excluding the current dashboard
      dashboardNameToaster.show({
        message: 'There is already another dashboard with this name',
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    } else if (enteredDashboardName.length > MAX_DASHBOARD_NAME_LENGTH) {
      dashboardNameToaster.show({
        message: `The dashboard's name cannot be more than ${MAX_DASHBOARD_NAME_LENGTH} characters`,
        intent: Intent.DANGER,
        icon: 'warning-sign',
      });
    } else {
      props.renameDashboard(props.token, props.name, enteredDashboardName);
    }
  };

  return (
    <DashboardItem
      token={props.token}
      name={props.name}
      description={props.description}
      key={props.name}
      handleRemoveDashboard={handleRemoveDashboard}
      removeAlertIsOpen={removeAlertIsOpen}
      cancelRemoveDashboard={cancelRemoveDashboard}
      confirmRemoveDashboard={confirmRemoveDashboard}
      handleRenameDashboard={handleRenameDashboard}
      renameAlertIsOpen={renameAlertIsOpen}
      enteredDashboardName={enteredDashboardName}
      handleEnteredDashboardNameChange={handleEnteredDashboardNameChange}
      cancelRenameDashboard={cancelRenameDashboard}
      confirmRenameDashboard={confirmRenameDashboard}
    />
  );
};

DashboardItemContainer.propTypes = {
  token: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  allDashboardNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  removeDashboard: PropTypes.func.isRequired,
  renameDashboard: PropTypes.func.isRequired,
};

export default DashboardItemContainer;
