import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { datasetPropTypes, inputFilePropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import datasetsActions from 'AppModules/Data/Actions/datasetsActions';
import FieldsConfiguration from './FieldsConfiguration';

const FieldsConfigurationContainer = (props) => {
  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);


  const handleClickSelect = (itemKey) => {
    const field = props.file.fields.find((el) => el.name === itemKey);
    stableDispatch(datasetsActions.updateFieldParameter(props.dataset.name, props.file.name, field.name, 'selected', !field.selected));
  };

  const handleClickSelectAll = () => {
    const selected = props.file.fields.filter((field) => !field.selected).length > 0;
    stableDispatch(datasetsActions.bulkUpdateFieldParameter(props.dataset.name, props.file.name, props.file.fields.map((field) => field.name), 'selected', selected));
  };

  const handleChangeFieldType = (fieldName, newType) => {
    stableDispatch(datasetsActions.updateFieldParameter(props.dataset.name, props.file.name, fieldName, 'type', newType));
  };

  const handleChangeFieldMapTo = (fieldName, newMapTo) => {
    stableDispatch(datasetsActions.updateFieldParameter(props.dataset.name, props.file.name, fieldName, 'mappedTo', newMapTo));
  };

  return (
    <FieldsConfiguration
      fields={props.file.fields}
      handleClickSelect={handleClickSelect}
      handleClickSelectAll={handleClickSelectAll}
      handleChangeFieldType={handleChangeFieldType}
      handleChangeFieldMapTo={handleChangeFieldMapTo}
      handleClose={props.handleClose}
    />
  );
};

FieldsConfigurationContainer.propTypes = {
  dataset: datasetPropTypes.isRequired,
  file: inputFilePropTypes.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FieldsConfigurationContainer;
