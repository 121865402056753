export default {
  ADD_DATASET: 'ADD_DATASET',
  REMOVE_DATASET: 'REMOVE_DATASET',
  UPDATE_DATASETS: 'UPDATE_DATASETS',
  UPDATE_DATASETS_STATUS: 'UPDATE_DATASETS_STATUS',
  DATASETS_IS_LOADING: 'DATASETS_IS_LOADING',
  LOAD_DATASET_PARAMETERS: 'LOAD_DATASET_PARAMETERS',
  DATASET_CONFIG_IS_LOADING: 'DATASET_CONFIG_IS_LOADING',
  DATASET_CONFIG_IS_SAVING: 'DATASET_CONFIG_IS_SAVING',
  CHANGE_DATASET_PARAMETER: 'CHANGE_DATASET_PARAMETER',
  CHANGE_DATASET_ETL_PARAMETER: 'CHANGE_DATASET_ETL_PARAMETER',
  UPDATE_DATASET_INPUT_FILE_LIST: 'UPDATE_DATASET_INPUT_FILE_LIST',
  UPDATE_DATASET_FILE_INPUT_PARAMETER: 'UPDATE_DATASET_FILE_INPUT_PARAMETER',
  BULK_UPDATE_DATASET_FILE_INPUT_PARAMETER: 'BULK_UPDATE_DATASET_FILE_INPUT_PARAMETER',
  UPDATE_FIELD_PARAMETER: 'UPDATE_FIELD_PARAMETER',
  BULK_UPDATE_FIELD_PARAMETER: 'BULK_UPDATE_FIELD_PARAMETER',
};
