import { add } from 'date-fns';
import { demoMode } from 'utils/appDefinitions';

// eslint-disable-next-line import/no-mutable-exports
let attributeDefinitions = [
  {
    name: 'ga:sessions',
    displayName: 'Traffic (sessions)',
    type: 'metric',
    defaults: {
      metricDisplayType: 'lines',
      numberFormat: '(0,0)',
    },
    useAsFilter: false,
    useAsMainFilter: false,
    filterRules: {
      minAllowed: undefined,
      maxAllowed: undefined,
      canBeEmpty: {
        from: true,
        to: true,
      },
    },
  },
  {
    name: 'ga:transactions',
    displayName: 'Transactions',
    type: 'metric',
    defaults: {
      metricDisplayType: 'bars',
      numberFormat: '(0,0)',
    },
    useAsFilter: false,
    useAsMainFilter: false,
    filterRules: {
      minAllowed: undefined,
      maxAllowed: undefined,
      canBeEmpty: {
        from: true,
        to: true,
      },
    },
  },
  {
    name: 'ga:transactionRevenue',
    displayName: 'Revenue ($)',
    type: 'metric',
    defaults: {
      metricDisplayType: 'lines',
      numberFormat: '(0,0)',
    },
    useAsFilter: false,
    useAsMainFilter: false,
    filterRules: {
      minAllowed: undefined,
      maxAllowed: undefined,
      canBeEmpty: {
        from: true,
        to: true,
      },
    },
  },
  {
    name: 'ga:itemQuantity',
    displayName: 'Sales (units)',
    type: 'metric',
    defaults: {
      metricDisplayType: 'bars',
      numberFormat: '(0,0)',
    },
    useAsFilter: false,
    useAsMainFilter: false,
    filterRules: {
      minAllowed: undefined,
      maxAllowed: undefined,
      canBeEmpty: {
        from: true,
        to: true,
      },
    },
  },
  {
    name: 'ga:revenuePerItem',
    displayName: 'Avg. price ($)',
    type: 'metric',
    defaults: {
      metricDisplayType: 'lines',
      numberFormat: '(0,0)',
    },
    useAsFilter: false,
    useAsMainFilter: false,
    filterRules: {
      minAllowed: undefined,
      maxAllowed: undefined,
      canBeEmpty: {
        from: true,
        to: true,
      },
    },
  },
  {
    name: 'ga:date',
    displayName: 'Date',
    type: 'date',
    defaults: {
      dimensionDisplayType: 'axis',
    },
    useAsFilter: true,
    useAsMainFilter: true,
    filterRules: {
      minAllowed: new Date(2015, 0, 1),
      maxAllowed: add(new Date(), { years: 1 }),
      canBeEmpty: {
        from: false,
        to: false,
      },
      defaultValue: {
        from: add(new Date(), { days: -7 }),
        to: add(new Date(), { days: -1 }),
      },
    },
  },
  {
    name: 'park',
    displayName: 'Park',
    type: 'dimension',
    defaults: {
      dimensionDisplayType: 'colors',
    },
    useAsMainFilter: true,
    useAsFilter: true,
    values: ['SWO', 'APO', 'DCO', 'BGT', 'AIT', 'SWC', 'APC', 'SWT', 'APT', 'BGW', 'WCW', 'SPL', 'SeaWorld Rollup'],
    filterRules: {
      allowMultiSelect: true,
      canBeEmpty: false,
      defaultValue: ['SWO'],
    },
  },
  {
    name: 'ga:productCategoryHierarchy',
    displayName: 'Product Category',
    type: 'dimension',
    defaults: {
      dimensionDisplayType: 'colors',
    },
    useAsFilter: true,
    useAsMainFilter: false,
    values: ['TICK_SD', 'TICK_MD', 'HotelActivity', 'UNKNOWN', 'PASS_B', 'PASS_S', 'PASS_G', 'PASS_P', 'OTHER'],
    filterRules: {
      allowMultiSelect: true,
      canBeEmpty: true,
      defaultValue: [],
    },
  },
  {
    name: 'ga:channelGrouping',
    displayName: 'Channel',
    type: 'dimension',
    defaults: {
      dimensionDisplayType: 'colors',
    },
    useAsFilter: true,
    useAsMainFilter: false,
    values: ['Organic Search', 'Paid Search', 'Direct', 'Email', 'Referral', 'Affiliates', 'Social', 'Paid Social', 'Display', '(Other)'],
    filterRules: {
      allowMultiSelect: true,
      canBeEmpty: true,
      defaultValue: [],
    },
  },
];

for (let i = 0; i < attributeDefinitions.length; i += 1) {
  attributeDefinitions[i].displayValues = attributeDefinitions[i].values;
}

if (demoMode) {
  attributeDefinitions[6].displayName = 'Retailer';
  attributeDefinitions[6].values = [
    'Carrefour',
    'El Corte Ingles',
    'Auchan',
    'Sainsbury',
    'Tesco',
    'E.Leclerc',
    'Lidl',
    'Rewe',
    'Aldi',
    'Kaufland',
    'Morrisons',
    'Edeka',
    'Walmart',
  ];
  attributeDefinitions[6].filterRules.defaultValue = ['Carrefour'];

  // attributeDefinitions[7].displayName = 'Category';
  // attributeDefinitions[7].values = [
  //   'Jupiler 6x33',
  //   'Jupiler 6x25',
  //   'Leffe Blonde 6x33',
  //   'Leffe Brune 6x33',
  //   'Leffe Triple 6x33',
  //   'Grimbergen Brune 6x33',
  //   'Grimbergen Blonde 6x33',
  //   'Rochefort 8',
  //   'Rochefort 10',
  // ];
  // attributeDefinitions[7].filterRules.defaultValue = ['Grimbergen Brune 6x33'];

  attributeDefinitions = [...attributeDefinitions.slice(0, 7), ...attributeDefinitions.slice(8)];
}

export default attributeDefinitions;
