import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Manage from './Manage';
import PageParamsDialog from './PageParamsDialog/PageParamsDialog';
import ganymedeActions from '../Actions/ganymedeActions';

// import offlineRes from './offlineRes.json';
// const a = offlineRes;

const ManageContainer = (props) => {
  const [changeParameterAlertIsOpen, setChangeParameterAlertIsOpen] = React.useState(false);
  const [changeActiveDetails, setChangeActiveDetails] = React.useState({});
  const [alertText, setAlertText] = React.useState('');
  const [paramDialogIsOpen, setParamDialogIsOpen] = React.useState(false);
  const [paramDialogMode, setParamDialogMode] = React.useState('edit');
  const [paramDialogPage, setParamDialogPage] = React.useState({ name: '', active: false });

  const handleClickAddPage = () => {
    setParamDialogMode('add');
    setParamDialogPage({
      name: 'New Page',
      active: true,
      URL: '',
      park: '',
      page: '',
      localIP: '71.43.195.10', // default: SWO
      nonLocalIP: '98.190.240.0', // default: SWO
      compareFromSelector: '',
      compareToSelector: '',
    });
    setParamDialogIsOpen(true);
  };

  const handleChangePageActive = ({ pageName, value }) => {
    setChangeActiveDetails({ pageName, value });
    if (value === true) {
      setAlertText('Are you sure you want to enable this page?');
    } else {
      setAlertText(
        'Are you sure you want to disable this page? This has irremediable effect: snapshots will no longer be taken from now on',
      );
    }
    setChangeParameterAlertIsOpen(true);
  };

  const confirmChangeActive = () => {
    const { pageName, value } = changeActiveDetails;
    props.updatePageParams(pageName, [{ parameter: 'active', value }], props.token);
    setChangeParameterAlertIsOpen(false);
  };

  const handleClickEditPage = (page) => {
    setParamDialogMode('edit');
    setParamDialogPage(page);
    setParamDialogIsOpen(true);
  };

  const handleChangeParam = ({ parameter, value }) => {
    setParamDialogPage({ ...paramDialogPage, [parameter]: value });
  };

  const confirmChangeParams = () => {
    const newParams = ['active', 'park', 'page', 'URL', 'localIP', 'nonLocalIP', 'compareFromSelector', 'compareToSelector'].map((parameterName) => ({
      parameter: parameterName,
      value: paramDialogPage[parameterName],
    }));
    props.updatePageParams(paramDialogPage.name, newParams, props.token);
    setParamDialogIsOpen(false);
  };

  // When pages changes, update paramDialogPage to updated value
  React.useEffect(() => {
    if (paramDialogPage.name) setParamDialogPage(props.pages[paramDialogPage.name]);
  }, [props.pages]);

  return (
    <>
      <Manage
        pages={props.pages}
        isUpdating={props.isUpdating}
        handleClickAddPage={handleClickAddPage}
        paramDialogIsOpen={paramDialogIsOpen}
        setParamDialogIsOpen={setParamDialogIsOpen}
        handleChangePageActive={handleChangePageActive}
        handleClickEditPage={handleClickEditPage}
        changeParameterAlertIsOpen={changeParameterAlertIsOpen}
        setChangeParameterAlertIsOpen={setChangeParameterAlertIsOpen}
        confirmChangeActive={confirmChangeActive}
        alertText={alertText}
      />
      <PageParamsDialog
        isOpen={paramDialogIsOpen}
        setParamDialogIsOpen={setParamDialogIsOpen}
        page={paramDialogPage}
        mode={paramDialogMode}
        handleChangeParam={handleChangeParam}
        confirmChangeParams={confirmChangeParams}
      />
    </>
  );
};

ManageContainer.propTypes = {
  token: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pages: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  updatePageParams: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
  pages: store.ganymedeReducer.pages,
  isUpdating: store.ganymedeReducer.pagesIsUpdating,
});

const mapDispatchToProps = (dispatch) => ({
  updatePageParams: (pageName, parameterValues, token) => dispatch(ganymedeActions.updatePageParams(pageName, parameterValues, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageContainer);
