// API Gateway
const env = 'dev';
const region = 'eu-west-1';
const APIbaseURL = `https://g2gwwe8svf.execute-api.eu-west-1.amazonaws.com/${env}`;
// S3
const s3Config = {
  bucket: 'masterplan-clientx-bucket',
  region,
  uploadFolder: 'uploads/',
};

export { APIbaseURL, s3Config };
