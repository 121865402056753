import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import './MyBreadCrumbs.css';

const MyBreadCrumbs = ({ items }) => (
  <div id="breadcrumbs">
    {items.map((item, i) => (
      <span className="breadcrumbItem" key={item.text}>
        {i > 0 && <Icon className="chevron-right" icon="chevron-right" />}
        <Link to={item.href}>{item.text}</Link>
      </span>
    ))}
  </div>
);

MyBreadCrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })).isRequired,
};

export default MyBreadCrumbs;
