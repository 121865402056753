/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import DatasetConfigurationContainer from './DatasetConfiguration/DatasetConfigurationContainer';

const DatasetContainer = (props) => {
  // console.log('rendering');
  const { datasets, datasetsAreLoading } = useSelector((store) => store.datasetsReducer);
  const { datasetName } = props.match.params;
  const dataset = datasets.find((el) => el.name === datasetName);
  return (datasetsAreLoading
    ? (
      <div style={{ marginTop: '150px' }}>
        <Spinner className="datasetsAreLoading" size={25} />
      </div>
    )
    : dataset
      ? (
        <DatasetConfigurationContainer
          dataset={dataset}
        />
      )
      : null
  );
};

DatasetContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      datasetName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DatasetContainer;
