/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import './Buttons.css';

const UploadButton = (props) => (
  <button type="button" className="uploadButton" {...props}>
    <Icon icon="cloud-upload" iconSize={props.iconSize} />
  </button>
);
UploadButton.propTypes = {
  iconSize: PropTypes.number,
};
UploadButton.defaultProps = {
  iconSize: 16,
};

const RemoveButton = (props) => (
  <button type="button" className="removeButton" {...props}>
    <Icon icon="delete" iconSize={props.iconSize || 16} />
  </button>
);
RemoveButton.propTypes = {
  iconSize: PropTypes.number,
};
RemoveButton.defaultProps = {
  iconSize: 16,
};

const DeleteButton = (props) => (
  <button type="button" className="removeButton">
    <Icon icon="trash" iconSize={props.iconSize || 16} />
  </button>
);
DeleteButton.propTypes = {
  iconSize: PropTypes.number,
};
DeleteButton.defaultProps = {
  iconSize: 16,
};

const BigButton = ({ children, bgColor, onClick, disabled, style }) => (
  <button
    type="button"
    className="bigButton"
    onClick={onClick}
    style={{ ...style, background: bgColor, cursor: disabled ? 'not-allowed' : 'pointer' }}
    disabled={disabled}
  >
    {children}
  </button>
);
BigButton.propTypes = {
  children: PropTypes.element.isRequired,
  bgColor: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};
BigButton.defaultProps = {
  disabled: false,
  style: {},
  onClick: null,
};

export { UploadButton, RemoveButton, DeleteButton, BigButton };
