/* eslint-disable react/prop-types */
import React from 'react';
import ParamDefinitionContainer from './ParamDefinition/ParamDefinitionContainer';
import NumberOfItemsContainer from './NumberOfItems/NumberOfItemsContainer';
import './BulkCategoryDefinition.css';

const BulkCategoryDefinition = ({ categoryName, category }) => (
  <div className="PageContainer BulkCategoryDefinition">
    <h3>{categoryName}</h3>
    <p>{category.description}</p>
    <div className="CategoryParamsContainer">
      <NumberOfItemsContainer categoryName={categoryName} numberOfItems={category.numberOfItems} />
      {Object.keys(category.parameters).map((parameterName) => (
        <ParamDefinitionContainer
          key={parameterName}
          categoryName={categoryName}
          parameterName={parameterName}
          parameter={category.parameters[parameterName]}
        />
      ))}
    </div>
  </div>
);

export default BulkCategoryDefinition;
