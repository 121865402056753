import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@blueprintjs/core';
import './SideBar.css';
import { useLocation } from 'react-router-dom';
import MenuGroup from './MenuGroup';

const SideBar = ({ items }) => {
  const [activeGroup, setActiveGroup] = React.useState(['']);
  const [loadedItem, setLoadedItem] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);

  // When items changes, validate that there are no duplicates (show warning if so)
  const location = useLocation();

  // When mounting or when the URL changes: set the loaded item corresponding to the URL
  React.useEffect(() => {
    for (const item of items) {
      for (const subItem of item.items) {
        const pathName = `/${encodeURI(item.name)}/${encodeURI(subItem)}`;
        if (pathName === window.location.pathname) {
          setLoadedItem(pathName);
        }
      }
    }
  }, [items, location.pathname]);

  const handleClickGroupTitle = (groupTitle) => {
    if (isOpen) {
      if (groupTitle === activeGroup) {
        setActiveGroup('');
      } else {
        setActiveGroup(groupTitle);
      }
    }
  };
  const handleClickLink = (groupName, itemPathName) => {
    setActiveGroup(groupName);
    setLoadedItem(itemPathName);
  };
  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`SideBar ${isOpen ? 'open' : ''}`}>
      <Button className="openCloseButton" minimal onClick={handleOpenClose}>
        <Icon icon={isOpen ? 'cross' : 'menu'} iconSize="25" />
      </Button>
      {items.map((item, id) => (
        <MenuGroup
          key={item.name}
          name={item.name}
          active={item.name === activeGroup}
          iconName={item.iconName}
          items={item.items}
          sidebarIsOpen={isOpen}
          loadedItem={loadedItem}
          // loadedItem={item.name === activeGroup ? loadedItem : null}
          handleClickGroupTitle={handleClickGroupTitle}
          handleClickLink={handleClickLink}
          calculatedTop={`${(121 + id * 55).toString()}px`}
        />
      ))}
      {isOpen ? (
        <footer className="buildNumber">
          {`Build: ${process.env.REACT_APP_BUILD_NUMBER || 'local'}`}
        </footer>
      ) : null}
    </div>
  );
};

SideBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    iconName: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default SideBar;
