import React from 'react';
import PropTypes from 'prop-types';
import { ETLparametersPropTypes, standardPromoETLDefinitionPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import { Slider, RangeSlider, Switch } from '@blueprintjs/core';
import { capitalize } from 'utils/util';

const PantryLoadingParameters = (props) => (
  <div className="ETLParamDefinition">
    <h4>Pantry Loading</h4>
    <div className="ETLparam">
      <Switch
        checked={props.datasetETLparameters.pantryLoading.enabled}
        label="Enabled"
        onChange={() => props.handleChangeETLparameter('pantryLoading', null, 'enabled', !props.datasetETLparameters.pantryLoading.enabled)}
      />
    </div>
    {props.ETLdefinition.pantryLoading.parameters.map((param) => {
      if (param.type === 'slider') {
        return (
          <div key={param.name} className="ETLparam">
            <span className="ETLparamName">{capitalize(param.name)}</span>
            <Slider
              min={param.range[0]}
              max={param.range[1]}
              stepSize={param.step}
              labelStepSize={param.labelStep || param.step}
              value={props.datasetETLparameters.pantryLoading[param.name]}
              onChange={(newValue) => props.handleChangeETLparameter('pantryLoading', null, param.name, newValue)}
            />
          </div>
        );
      } if (param.type === 'range-slider') {
        return (
          <div key={param.name} className="ETLparam">
            <span className="ETLparamName">{capitalize(param.name)}</span>
            <RangeSlider
              min={param.range[0]}
              max={param.range[1]}
              stepSize={param.step}
              labelStepSize={param.labelStep || param.step}
              value={props.datasetETLparameters.pantryLoading[param.name]}
              onChange={(newValue) => props.handleChangeETLparameter('pantryLoading', param.name, null, newValue)}
            />
          </div>
        );
      }
      return null;
    })}
  </div>
);
PantryLoadingParameters.propTypes = {
  datasetETLparameters: ETLparametersPropTypes.isRequired,
  handleChangeETLparameter: PropTypes.func.isRequired,
  ETLdefinition: standardPromoETLDefinitionPropTypes.isRequired,
};

export default PantryLoadingParameters;
