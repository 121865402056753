import React from 'react';
import PropTypes from 'prop-types';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import ETLdefinitions from 'AppModules/Data/Definitions/ETLdefinitions';
import BaselineParameters from './Parameters/BaselineParameters';
import RegularPriceParameters from './Parameters/RegularPriceParameters';
import PantryLoadingParameters from './Parameters/PantryLoadingParameters';
import CannibalizationParameters from './Parameters/CannibalizationParameters';
import './StandardPromoETLParameters.css';

const StandardPromoETLParameters = (props) => {
  const datasetETLparameters = props.dataset.ETLparameters[props.dataset.ETLschema];
  const ETLdefinition = ETLdefinitions.find((schema) => schema.name === props.dataset.ETLschema);
  return (
    <div className="ETLparameters">
      <h3>{`${ETLdefinition.displayName} — ETL Parameters`}</h3>
      <div className="ETLparametersContainer">
        <BaselineParameters
          datasetETLparameters={datasetETLparameters}
          ETLdefinition={ETLdefinition}
          handleChangeETLparameter={props.handleChangeETLparameter}
        />
        <RegularPriceParameters
          datasetETLparameters={datasetETLparameters}
          ETLdefinition={ETLdefinition}
          handleChangeETLparameter={props.handleChangeETLparameter}
        />
      </div>
      <div className="ETLparametersContainer">
        <PantryLoadingParameters
          datasetETLparameters={datasetETLparameters}
          ETLdefinition={ETLdefinition}
          handleChangeETLparameter={props.handleChangeETLparameter}
        />
        <CannibalizationParameters
          datasetETLparameters={datasetETLparameters}
          ETLdefinition={ETLdefinition}
          handleChangeETLparameter={props.handleChangeETLparameter}
        />
      </div>
    </div>
  );
};

StandardPromoETLParameters.propTypes = {
  dataset: datasetPropTypes.isRequired,
  handleChangeETLparameter: PropTypes.func.isRequired,
};

export default StandardPromoETLParameters;
