import React from 'react';
import PropTypes from 'prop-types';
import { chartTypePropTypes } from 'AppModules/Titan/Definitions/propTypes';
import FiltersGroupContainer from './FiltersGroup/FiltersGroupContainer';
import AttributesGroupContainer from './AttributesGroup/AttributesGroupContainer';
import './ChartParameters.css';

const ChartParameters = (props) => (
  <div className="ChartParameters">
    {props.chartType.parameterGroups.map((parameterGroup, parameterGroupID) => (
      <React.Fragment key={parameterGroup.type}>
        {parameterGroupID > 0 && <div className="divider" />}
        {['metrics', 'dimensions', 'comparisons'].includes(parameterGroup.type) && (
          <AttributesGroupContainer
            dashboardID={props.dashboardID}
            chartGroupID={props.chartGroupID}
            parameterGroupID={parameterGroupID}
            chartType={props.chartType}
            parameterGroup={parameterGroup}
          />
        )}
        {parameterGroup.type === 'filters' && (
          <FiltersGroupContainer
            dashboardID={props.dashboardID}
            chartGroupID={props.chartGroupID}
          />
        )}
      </React.Fragment>
    ))}
  </div>
);

ChartParameters.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  chartType: chartTypePropTypes.isRequired,
};

export default ChartParameters;
