/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { sub } from 'date-fns';
import ganymedeActionTypes from './Actions/ganymedeActionTypes';
import { dateReviver } from '../../utils/util';
import definitions from './Definitions/definitions';

const today = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()); // needed to have date set on 00:00
const initialState = localStorage.getItem('ganymedeState') ? JSON.parse(localStorage.getItem('ganymedeState'), dateReviver) : {
// const initialState = {
  pages: {},
  pagesIsUpdating: false,
  screenshots: [],
  screenshotsListIsUpdating: false,
  totalNbOfScreenshots: 0,
  filtersSelection: {
    Date: {
      from: sub(today, { days: 6 }),
      to: today,
    },
    additionalDates: [],
    Park: [],
    'Page Type': [],
    Version: [],
  },
  filters: {
    Date: {
      type: 'date',
      minAllowed: new Date(2019, 8, 25),
    },
    Park: {
      type: 'list',
      values: ['SWO', 'APO', 'DCO', 'BGT', 'AIT', 'SWC', 'APC', 'SWT', 'APT', 'BGW', 'WCW', 'SPL'],
      default: 'SWO',
    },
    'Page Type': {
      type: 'list',
      values: ['Homepage', 'Tickets', 'Fun Cards', 'Annual Pass', 'Limited Time Offers'],
      default: 'Homepage',
    },
    Version: {
      type: 'list',
      values: ['Local', 'Non-local'],
      default: 'Local',
    },
  },
};

// eslint-disable-next-line consistent-return
const ganymedeReducer = produce((draft = initialState, action) => {
  const getAdditionalDateFrom = ({ from, type }) => sub(from, definitions.dateRangeTypes.find((el) => el.name === type).timeDiff);
  const getAdditionalDateTo = ({ to, type }) => sub(to, definitions.dateRangeTypes.find((el) => el.name === type).timeDiff);

  switch (action.type) {
    case ganymedeActionTypes.UPDATE_PAGE_LIST:
      draft.pages = {};
      action.pages.forEach((page) => { draft.pages[page.name] = page; });
      // update filters
      draft.filters.Park.values = [...new Set(action.pages.map((page) => page.park))].sort();
      draft.filters['Page Type'].values = [...new Set(action.pages.map((page) => page.page))].sort();
      // update total nb of screenshots
      draft.totalNbOfScreenshots = action.pages.reduce((acc, current) => acc + (current.itemsCount || 0), 0);
      break;


    case ganymedeActionTypes.SET_UPDATING:
      draft.pagesIsUpdating = action.isUpdating;
      break;


    case ganymedeActionTypes.UPDATE_FILTERED_SCREENSHOTS:
      draft.screenshots = action.screenshots;
      break;


    case ganymedeActionTypes.SET_SCREENSHOTS_LIST_ISUPDATING:
      draft.screenshotsListIsUpdating = action.screenshotsListIsUpdating;
      break;


    case ganymedeActionTypes.UPDATE_PAGE_PARAMS:
      const { pageName, parameterValues } = action;
      if (!draft.pages[pageName]) draft.pages[pageName] = { name: pageName };

      for (const parameterValue of parameterValues) {
        const { parameter, value } = parameterValue;
        draft.pages[pageName][parameter] = value;
      }
      break;


    case ganymedeActionTypes.UPDATE_EXPLORER_FILTER:
      if (action.filterName === 'Date') {
        draft.filtersSelection.Date.from = action.filterValues.from;
        draft.filtersSelection.Date.to = action.filterValues.to;

        // update addionnaldateFilters if they depend on the primary date filter (i.e. if they're anything other than Custom)
        draft.filtersSelection.additionalDates.filter((el) => el.type !== 'Custom').forEach((additionalDateFilter) => {
          additionalDateFilter.from = sub(action.filterValues.from, definitions.dateRangeTypes.find((el) => el.name === additionalDateFilter.type).timeDiff);
          additionalDateFilter.to = sub(action.filterValues.to, definitions.dateRangeTypes.find((el) => el.name === additionalDateFilter.type).timeDiff);
        });
      } else if (action.filterName.slice(0, 14) === 'additionalDate') {
        draft.filtersSelection.additionalDates[action.filterName.slice(14)].from = action.filterValues.from;
        draft.filtersSelection.additionalDates[action.filterName.slice(14)].to = action.filterValues.to;
      } else {
        draft.filtersSelection[action.filterName] = action.filterValues;
      }
      break;


    case ganymedeActionTypes.ADD_DATE_FILTER:
      // by default set the type to PY
      draft.filtersSelection.additionalDates.push({
        type: 'PY',
        from: getAdditionalDateFrom({ from: draft.filtersSelection.Date.from, type: 'PY' }),
        to: getAdditionalDateTo({ to: draft.filtersSelection.Date.to, type: 'PY' }),
      });
      break;


    case ganymedeActionTypes.REMOVE_DATE_FILTER:
      draft.filtersSelection.additionalDates = [
        ...draft.filtersSelection.additionalDates.slice(0, action.additionalDateFilterID),
        ...draft.filtersSelection.additionalDates.slice(action.additionalDateFilterID + 1),
      ];
      break;


    case ganymedeActionTypes.CHANGE_DATE_FILTER_TYPE:
      draft.filtersSelection.additionalDates[action.additionalDateFilterID].type = action.value;
      draft.filtersSelection.additionalDates[action.additionalDateFilterID].from = getAdditionalDateFrom({ from: draft.filtersSelection.Date.from, type: action.value });
      draft.filtersSelection.additionalDates[action.additionalDateFilterID].to = getAdditionalDateTo({ to: draft.filtersSelection.Date.to, type: action.value });
      break;


    case ganymedeActionTypes.SET_FILTERS_FROM_LINK:
      Object.keys(action.filtersSelection).forEach((filterName) => {
        draft.filtersSelection[filterName] = [action.filtersSelection[filterName]];
      });
      break;


    default:
      return draft;
  }
  const locaStorageState = { ...draft };
  locaStorageState.screenshots = [];
  localStorage.setItem('ganymedeState', JSON.stringify(locaStorageState));
});

export default ganymedeReducer;
