import React from 'react';
import FileSelector from './FileSelector/FileSelector';
import FilesToUpload from './FilesToUpload/FilesToUpload';

const FileUploadContainer = () => (
  <div className="PageContainer">
    <h2>Select and upload files</h2>
    <FileSelector />
    <FilesToUpload />
    <div style={{ clear: 'both' }} />
  </div>
);

export default FileUploadContainer;
