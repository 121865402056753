import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Collapse, Intent } from '@blueprintjs/core';
import { chartGroupPropTypes, chartDataPropTypes, chartTypePropTypes } from 'AppModules/Titan/Definitions/propTypes';
import './ChartGroup.css';
import ButtonWithWarning from 'Components/ButtonWithWarning/ButtonWithWarning';
import ChartContainer from './Chart/ChartContainer';
import ChartParametersHeaderContainer from './Chart/ChartParametersHeader/ChartParametersHeaderContainer';
import ChartParametersContainer from './ChartParameters/ChartParametersContainer';

const ChartGroup = (props) => (
  <div className={`ChartGroup ${props.isOpen ? 'isOpen ' : 'isClosed'}`}>
    <header role="button" tabIndex={0} onClick={props.changeIsOpen}>
      <h3>
        <Icon icon={props.chartType.icon} iconSize={28} />
        {props.chartGroup.name}
      </h3>
      <Button icon={props.isOpen ? 'collapse-all' : 'expand-all'} minimal />
    </header>
    <Collapse className="chartGroupContainer" isOpen={props.isOpen} keepChildrenMounted>
      <ChartParametersHeaderContainer dashboardID={props.dashboardID} chartGroupID={props.chartGroupID} />
      <ChartParametersContainer
        dashboardID={props.dashboardID}
        chartGroupID={props.chartGroupID}
        chartType={props.chartType}
      />
      <div className="updateChart">
        <Button
          text="Update"
          icon="refresh"
          large
          intent={props.chartGroup.chartParameters.validation.intent}
          onClick={props.handleUpdateChart}
          disabled={props.chartGroup.chartIsSynced || !props.chartGroup.chartParameters.validation.valid}
          loading={props.dataIsLoading}
        />
        <p className="validationMessage">{props.chartGroup.chartParameters.validation.message}</p>
      </div>
      {Object.keys(props.chartGroup.chartParametersOfLoadedData).length > 0 && props.chartData[0] && (
        /* chartParametersOfLoadedData condition added due to case where chartType changes: there is chartData but user should reload first */
        <ChartContainer
          chartData={props.chartData}
          dashboardID={props.dashboardID}
          chartGroupID={props.chartGroupID}
        />
      )}
      <div className="chartGroupFooter">
        <Button
          text="Duplicate Chart"
          icon="duplicate"
          intent={Intent.PRIMARY}
          onClick={props.handleDuplicateChart}
        />
        <ButtonWithWarning
          className="removeChartButton"
          buttonIcon="trash"
          buttonText="Delete Chart"
          buttonIntent={Intent.DANGER}
          warningBodyText="Are you sure you want to delete this chart? This action cannot be undone"
          warningButtonText="Yes, delete"
          warningIcon="trash"
          warningIntent={Intent.DANGER}
          onConfirm={props.handleRemoveChart}
        />
      </div>
    </Collapse>
  </div>
);

ChartGroup.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  chartGroup: chartGroupPropTypes.isRequired,
  chartType: chartTypePropTypes.isRequired,
  isOpen: PropTypes.bool.isRequired,
  changeIsOpen: PropTypes.func.isRequired,
  handleRemoveChart: PropTypes.func.isRequired,
  handleUpdateChart: PropTypes.func.isRequired,
  handleDuplicateChart: PropTypes.func.isRequired,
  chartData: chartDataPropTypes.isRequired,
  dataIsLoading: PropTypes.bool.isRequired,
};

export default ChartGroup;
