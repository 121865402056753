/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { Switch, Button, Alert, Intent, Spinner } from '@blueprintjs/core';
import { encodeURIobject } from 'utils/util';
import { format } from 'date-fns';
import SimpleTable from '../../../Components/SimpleTable/SimpleTable';
import MyBreadCrumbs from '../../../Components/MyBreadCrumbs/MyBreadCrumbs';

const tableHeaders = [
  {
    title: 'Active',
    width: 7,
    // alignItems: ,
    // alignHeaders: ,
  },
  {
    title: 'Page',
    width: 33,
    // alignItems: ,
    // alignHeaders: ,
  },
  {
    title: 'Snapshots',
    width: 10,
    // alignItems: ,
    // alignHeaders: ,
  },
  {
    title: 'First Snapshot',
    width: 10,
    // alignItems: ,
    // alignHeaders: ,
  },
  {
    title: 'Last Snapshot',
    width: 10,
    // alignItems: ,
    // alignHeaders: ,
  },
  {
    title: 'Parameters',
    width: 10,
    // alignItems: ,
    // alignHeaders: ,
  },
];

const Manage = (props) => {
  const tableItems = Object.keys(props.pages).sort((a, b) => {
    if (a < b) { return -1; }
    if (a > b) { return 1; }
    return 0;
  }).map((pageName) => {
    const page = props.pages[pageName];
    return {
      key: page.name,
      components: [
        <Switch
          checked={page.active}
          disabled={false}
          onChange={() => props.handleChangePageActive({ pageName, value: !page.active })}
        />,
        <div>
          <Link to={`/Ganymede/Explorer?${encodeURIobject({ Park: page.park, 'Page Type': page.page })}`}>
            <p style={{ textAlign: 'left', fontWeight: 'bold' }}>{page.name}</p>
          </Link>
          <p style={{ textAlign: 'left', color: '#bbb' }}>{page.URL}</p>
        </div>,
        <p>{numeral(page.itemsCount).format('0,0') || 0}</p>,
        <p>{page.firstScreenshotTimeStamp ? format(new Date(page.firstScreenshotTimeStamp), 'dd/MM/yyyy - HH:mm') : 'n/a'}</p>,
        <p>{page.lastScreenshotTimeStamp ? format(new Date(page.lastScreenshotTimeStamp), 'dd/MM/yyyy - HH:mm') : 'n/a'}</p>,
        <Button icon="cog" onClick={() => props.handleClickEditPage(page)} />,
      ],
    };
  });

  return (
    <div className="Manage">
      <MyBreadCrumbs
        items={[
          { href: '/', text: 'Home' },
          { href: '/Ganymede', text: 'Ganymede' },
          { href: '/Ganymede/Manage', text: 'Manage' },
        ]}
      />
      <div className="PageContainer">
        <h2 style={{ float: 'left' }}>
          Ganyemede - Manage tracked pages
          {props.isUpdating}
        </h2>
        {props.isUpdating && (
          <div style={{ float: 'left', margin: '5px' }}>
            <Spinner size={Spinner.SIZE_SMALL} />
          </div>
        )}
        <div style={{ float: 'right' }}>
          <Button intent={Intent.PRIMARY} icon="add" text="Add Page" onClick={props.handleClickAddPage} style={{ margin: '0 20px 10px 0' }} />
        </div>
        <SimpleTable selectAll={false} selectOne={false} headers={tableHeaders} items={tableItems} />
        <Alert
          isOpen={props.changeParameterAlertIsOpen}
          icon="warning-sign"
          intent={Intent.DANGER}
          confirmButtonText="Confirm"
          onConfirm={props.confirmChangeActive}
          cancelButtonText="Cancel"
          onCancel={() => props.setChangeParameterAlertIsOpen(false)}
          canEscapeKeyCancel
        >
          {props.alertText}
        </Alert>
      </div>
    </div>
  );
};

Manage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pages: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  handleChangePageActive: PropTypes.func.isRequired,
  changeParameterAlertIsOpen: PropTypes.bool.isRequired,
  setChangeParameterAlertIsOpen: PropTypes.func.isRequired,
  confirmChangeActive: PropTypes.func.isRequired,
  alertText: PropTypes.string.isRequired,
  handleClickEditPage: PropTypes.func.isRequired,
  handleClickAddPage: PropTypes.func.isRequired,
};

export default Manage;
