export default {
  SET_GA_TOKEN: 'SET_GA_TOKEN',
  ADD_DASHBOARD: 'ADD_DASHBOARD',
  REMOVE_DASHBOARD: 'REMOVE_DASHBOARD',
  RENAME_DASHBOARD: 'RENAME_DASHBOARD',
  CHANGE_DASHBOARD_DESCRIPTION: 'CHANGE_DASHBOARD_DESCRIPTION',
  CHANGE_DASHBOARD_FILTERS_DISPLAYMODE: 'CHANGE_DASHBOARD_FILTERS_DISPLAYMODE',
  UPDATE_FILTER_VALUE: 'UPDATE_FILTER_VALUE',
  UPDATE_FILTER_ATTRIBUTE: 'UPDATE_FILTER_ATTRIBUTE',
  ADD_FILTER: 'ADD_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  ADD_CHART: 'ADD_CHART',
  REMOVE_CHART: 'REMOVE_CHART',
  RENAME_CHART: 'RENAME_CHART',
  UPDATE_CHART: 'UPDATE_CHART',
  DUPLICATE_CHART: 'DUPLICATE_CHART',
  CHANGE_CHART_TYPE: 'CHANGE_CHART_TYPE',
  CHANGE_CHARTGROUP_DISPLAYMODE: 'CHANGE_CHARTGROUP_DISPLAYMODE',
  CHANGE_ATTRIBUTE: 'CHANGE_ATTRIBUTE',
  CHANGE_ATTRIBUTE_PARAMETER: 'CHANGE_ATTRIBUTE_PARAMETER',
  ADD_ATTRIBUTE: 'ADD_ATTRIBUTE',
  REMOVE_ATTRIBUTE: 'REMOVE_ATTRIBUTE',
  ADD_COMPARISON: 'ADD_COMPARISON',
  REMOVE_COMPARISON: 'REMOVE_COMPARISON',
  CHANGE_COMPARISON: 'CHANGE_COMPARISON',
  VALIDATE_PARAMETERS: 'VALIDATE_PARAMETERS',
  CHECK_CHART_IS_SYNCED: 'CHECK_CHART_IS_SYNCED',
  SET_DATA_IS_LOADED: 'SET_DATA_IS_LOADED',
};
