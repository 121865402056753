import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import * as titanPropTypes from 'AppModules/Titan/Definitions/propTypes';
import AttributeContainer from './Attribute/AttributeContainer';
import './AttributesGroup.css';

const AttributesGroup = (props) => (
  <div className="parametersGroup">
    <span className="parametersGroupHeader">{props.parameterGroup.displayName}</span>
    {props.selectedAttributes.map((selectedAttribute, attributeID) => (
      <AttributeContainer
        key={attributeID.toString()}
        dashboardID={props.dashboardID}
        chartGroupID={props.chartGroupID}
        parameterGroupID={props.parameterGroupID}
        chartType={props.chartType}
        parameterGroup={props.parameterGroup}
        attributeID={attributeID}
        selectedAttributes={props.selectedAttributes}
        selectedAttribute={selectedAttribute}
      />
    ))}
    <div className="addButtonContainer">
      <Button
        className="addButton"
        text=""
        icon="insert"
        intent={Intent.PRIMARY}
        onClick={props.handleAddAttribute}
      />
    </div>
  </div>
);

AttributesGroup.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  parameterGroupID: PropTypes.number.isRequired,
  chartType: titanPropTypes.chartTypePropTypes.isRequired,
  parameterGroup: titanPropTypes.parameterGroupPropTypes.isRequired,
  selectedAttributes: PropTypes.arrayOf(titanPropTypes.attributePropTypes).isRequired,
  handleAddAttribute: PropTypes.func.isRequired,
};

export default AttributesGroup;
