import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Popover, Position, Icon } from '@blueprintjs/core';
import { attributePropTypes, parameterGroupPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import { parameterTypes } from 'AppModules/Titan/Definitions/chartTypes';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';
import './AttributeIconsParameter.css';

const AttributeIconsParameter = ({ attribute, parameterName, ...props }) => {
  // console.log(props.attribute[parameterName]);

  const [parameterValues, setParameterValues] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setParameterValues(parameterTypes.find((param) => param.name === parameterName).values);
  }, [attribute, parameterName]);

  const handleChangeParameter = (parameterValue) => {
    setIsOpen(false);
    props.changeAttributeParameter(props.dashboardID, props.chartGroupID, props.parameterGroup.type, props.attributeID, parameterName, parameterValue);
  };

  const handlePopoverInteraction = (nextOpenState) => {
    setIsOpen(nextOpenState);
  };

  return (
    <Popover isOpen={isOpen} onInteraction={handlePopoverInteraction} position={Position.BOTTOM_LEFT}>
      <Button
        icon={(
          <Icon
            icon={parameterValues.length > 0 && parameterValues.flat().find((col) => col.name === attribute[parameterName]).icon}
            color={parameterValues.length > 0 && parameterValues.flat().find((col) => col.name === attribute[parameterName]).value}
          />
        )}
        disabled={props.disabled}
        onClick={() => setIsOpen(!isOpen)}
      />
      <div className="iconsGroup">
        {parameterValues.map((itemsGroup) => (
          <div className="iconsSubGroup" key={itemsGroup.map((group) => group.name).flat()}>
            {itemsGroup.map((item) => (
              <Button
                key={item.name}
                icon={<Icon icon={item.icon} color={item.value} />}
                minimal
                onClick={() => handleChangeParameter(item.name)}
                active={item.name === attribute[parameterName]}
              />
            ))}
          </div>
        ))}
      </div>
    </Popover>
  );
};

AttributeIconsParameter.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  parameterGroup: parameterGroupPropTypes.isRequired,
  attributeID: PropTypes.number.isRequired,
  attribute: attributePropTypes.isRequired,
  parameterName: PropTypes.string.isRequired,
  changeAttributeParameter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AttributeIconsParameter.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = (dispatch) => ({
  changeAttributeParameter: (dashboardID, chartGroupID, attributeType, attributeID, parameterName, parameterValue) => {
    dispatch(chartGroupsActions.changeAttributeParameter(dashboardID, chartGroupID, attributeType, attributeID, parameterName, parameterValue));
  },
});

export default connect(null, mapDispatchToProps)(AttributeIconsParameter);
