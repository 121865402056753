import { format, parse, add } from 'date-fns';
import attributeDefinitions from '../Definitions/attributeDefinitions';
import { parksViewIds } from '../util/constants';
import { comparisonDefinitions } from '../Definitions/definitions';

const validateGAquery = (params) => {
  // There must be at least 1 metric and 1 dimension
  if (!params.metrics) return { error: true, message: 'At least one metric must be present' };
  if (!params.dimensions) return { error: true, message: 'At least one dimension must be present' };
  // There must be a filter on park (i.e. park)
  if (!params.filters.find((filter) => filter.name === 'park')) return { error: true, message: 'A filter on Park must be present' };
  // There must be a filter on date
  if (!params.filters.find((filter) => filter.name === 'ga:date')) return { error: true, message: 'A filter on Date must be present (both from and to)' };
  return true;
};

const createRequests = (params) => {
  // 2.1 park
  const parks = params.filters.find((filter) => filter.name === 'park').values;

  // 2.2 Main Date range
  const dateFilter = params.filters.find((filter) => filter.name === 'ga:date').values;
  let dateRanges = [{
    startDate: format(new Date(dateFilter.from), 'yyyy-MM-dd'),
    endDate: format(new Date(dateFilter.to), 'yyyy-MM-dd'),
  }];

  // 2.3 compare periods
  const { comparisonPeriods } = params;
  if (comparisonPeriods) {
    const comparePeriodsFormatted = comparisonPeriods.map((compareDateRange) => ({
      startDate: format(new Date(compareDateRange.from), 'yyyy-MM-dd'),
      endDate: format(new Date(compareDateRange.to), 'yyyy-MM-dd'),
    }));
    dateRanges = [...dateRanges, ...comparePeriodsFormatted];
  }

  // 2.4 metrics
  const metrics = params.metrics.map((metric) => ({
    expression: metric,
  }));

  // 2.5 dimensions
  const dimensions = params.dimensions.filter((dim) => dim !== 'park').map((dimension) => ({
    name: dimension,
  }));

  // 2.6 filters
  const dimensionFilters = params.filters.filter((filter) => {
    if (filter.name === 'ga:date' || filter.name === 'park') return false;
    return attributeDefinitions.find((attr) => attr.name === filter.name).type === 'dimension';
  });
  const filters = dimensionFilters.map((filter) => ({
    dimensionName: filter.name,
    operator: 'IN_LIST',
    expressions: filter.values,
    caseSensitive: true,
  }));
  const dimensionFilterClause = {
    operator: 'AND',
    filters,
  };

  // 2.7 orderBys
  const orderBys = dimensions.length > 0 ? [{
    fieldName: dimensions[0].name,
    orderType: 'VALUE',
    sortOrder: 'ASCENDING',
  }]
    : [];

  // only 1 request per park and per date range
  let requests = [];
  for (const dateRange of dateRanges) {
    const currentDateRangeRequest = parks.map((park) => ({
      viewId: parksViewIds[park].toString(),
      dateRanges: dateRange,
      metrics,
      dimensions,
      dimensionFilterClauses: [dimensionFilterClause],
      orderBys,
      includeEmptyRows: true,
    }));
    requests = [...requests, ...currentDateRangeRequest];
  }
  return requests;
};

const formatGAoutput = (response, params) => {
  const report = response.reports[0];
  const headers = [
    ...report.columnHeader.dimensions || [], // if no dimension in the request, columnHeader doesn't have a dimensions property
    ...report.columnHeader.metricHeader.metricHeaderEntries.map((metricHeader) => metricHeader.name),
  ];

  const rows = report.data.rows.map((row) => ([
    ...row.dimensions ? (row.dimensions.map((rowDimensionValue, dimensionIndex) => {
      const dateIndex = headers.indexOf('ga:date');
      return dimensionIndex === dateIndex ? parse(rowDimensionValue, 'yyyyMMdd', new Date()) : rowDimensionValue;
    })) : [],
    ...row.metrics[0].values.map((rowMetricValue, metricIndex) => {
      const isMetricPercent = report.columnHeader.metricHeader.metricHeaderEntries[metricIndex].type === 'PERCENT';
      return parseFloat(rowMetricValue) / (isMetricPercent ? 100 : 1);
    }),
  ]));

  let totals = report.data.totals[0].values.map(((totalValue, metricIndex) => {
    const isMetricPercent = report.columnHeader.metricHeader.metricHeaderEntries[metricIndex].type === 'PERCENT';
    return parseFloat(totalValue) / (isMetricPercent ? 100 : 1);
  }));
  // need to add total for dimensions too, so that the reponse is a rectangular array (exclude park because that will be added in the aggregation of results (next step))
  const nbDims = params.dimensions.filter((dim) => dim !== 'park').length;
  totals = [...Array(nbDims).fill(null), ...totals];

  return { headers, rows, totals };
};

const splitResponsesByPeriod = (responses, params) => {
  const nbDateRanges = 1 + params.comparisonPeriods.length;
  const parks = params.filters.find((filter) => filter.name === 'park').values;
  const output = [];
  for (let periodId = 0; periodId < nbDateRanges; periodId += 1) {
    const comparisonDefinition = periodId === 0 ? null : comparisonDefinitions.find((comp) => comp.name === params.comparisonPeriods[periodId - 1].name);
    const period = periodId === 0 ? 'Current' : comparisonDefinition.name;
    const data = responses.slice(periodId * parks.length, (periodId + 1) * parks.length);
    output.push({ data, period });
  }
  return output;
};

const getDimensionsOfRow = (row, dimensionsMap) => {
  const newRow = [];
  for (let i = 0; i < row.length; i += 1) {
    if (dimensionsMap[i]) newRow.push(row[i]);
  }
  return newRow;
};

// const getMetricsOfRow = (row, metricsMap) => {
//   const newRow = [];
//   for (let i = 0; i < row.length; i += 1) {
//     if (!metricsMap[i]) newRow.push(row[i]);
//   }
//   return newRow;
// };

const aggregateReportRows = (data, parks) => {
  // /!\ data must be organised by dimensions first and metrics after

  // console.log(data);
  // Find all the combinations of dimensions that exist in the data
  const keyIsADimension = data[0].headers.map((header) => attributeDefinitions.find((attr) => attr.name === header).type !== 'metric');
  const nbDimensions = keyIsADimension.filter((is) => is).length;
  const nbMetrics = keyIsADimension.filter((is) => !is).length;

  const dimensionsData = parks.map((park, parkId) => data[parkId].rows.map((row) => getDimensionsOfRow(row, keyIsADimension)));

  const dimensionsDataAllParks = dimensionsData.flat();
  // Find unique combinations
  const uniqueDimensionsCombinations = [];
  for (let i = 0; i < dimensionsDataAllParks.length; i += 1) {
    const currentValue = dimensionsDataAllParks[i];
    const alreadyIn = uniqueDimensionsCombinations.filter((row) => JSON.stringify(row) === JSON.stringify(currentValue)).length > 0;
    if (!alreadyIn) uniqueDimensionsCombinations.push(currentValue);
  }
  // Aggregate the metrics
  const aggregatedData = uniqueDimensionsCombinations.map((uniqueRow) => {
    let newRow = uniqueRow; // ["SWO", "TICK_SD"]
    // add entries for the metrics
    newRow = [...newRow, ...new Array(nbMetrics).fill(0)]; // ["SWO", "TICK_SD", 0, 0]
    for (let parkId = 0; parkId < parks.length; parkId += 1) {
      // find out if this park data has this combination
      const relevantRowForThisPark = data[parkId].rows.find((parkDataRow) => {
        const parkDataRowDimensions = getDimensionsOfRow(parkDataRow, keyIsADimension);
        return JSON.stringify(parkDataRowDimensions) === JSON.stringify(uniqueRow);
      });
      if (relevantRowForThisPark) {
        for (let metricId = 0; metricId < nbMetrics; metricId += 1) {
          newRow[nbDimensions + metricId] += relevantRowForThisPark[nbDimensions + metricId];
        }
      }
    }
    return newRow;
  });

  return aggregatedData;
};

const aggregateReportTotals = (data, parks) => {
  const output = data[0].totals.map((metric) => (typeof metric === 'number' ? 0 : ''));
  for (let metricId = 0; metricId < data[0].totals.length; metricId += 1) {
    for (let parkId = 0; parkId < parks.length; parkId += 1) {
      if (typeof data[parkId].totals[metricId] === 'number') {
        output[metricId] += data[parkId].totals[metricId] || 0;
      } else {
        output[metricId] = data[parkId].totals[metricId] || 0;
      }
    }
  }
  return output;
};

const aggregateReport = (response, params) => {
  const { data, period } = response;
  const parks = params.filters.find((filter) => filter.name === 'park').values;
  if (params.dimensions.includes('park')) {
    const headers = ['park', ...data[0].headers];
    const rows = parks.map((park, i) => data[i].rows.map((row) => [park, ...row])).flat();
    const totals = parks.map((park, i) => [park, ...data[i].totals]);
    return { period, headers, rows, totals };
  }
  const { headers } = data[0];
  const rows = aggregateReportRows(data, parks);
  const totals = [aggregateReportTotals(data, parks)];
  return { period, headers, rows, totals };
};

const realignDates = (data) => {
  const comparisonDefinition = comparisonDefinitions.find((comp) => comp.name === data.period);
  const { timeDiff } = comparisonDefinition;
  const dateIndex = data.headers.indexOf('ga:date');
  if (dateIndex === -1) return data;
  const dataCopy = JSON.parse(JSON.stringify(data)); // Deep copy of the data object, otherwise the source object ends up modified
  const realignedRows = dataCopy.rows.map((row) => {
    const newRow = row;
    newRow[dateIndex] = add(new Date(row[dateIndex]), timeDiff);
    return newRow;
  });

  return { ...data, rows: realignedRows };
};

const findCombinations = (data) => {
  if (data.length < 2) throw Error('Error finding combinations: source array has less than 2 sub-arrays');
  // get the first 2 arrays
  const [data1, data2, ...rest] = data;
  // Find the combinations of data1 and data2
  const combinations = [];
  for (let i = 0; i < data1.length; i += 1) {
    for (let j = 0; j < data2.length; j += 1) {
      combinations.push(Array.isArray(data1[i]) ? [...data1[i], data2[j]] : [data1[i], data2[j]]);
    }
  }

  if (rest.length > 0) {
    return findCombinations([combinations, ...rest]);
  }
  return combinations;
};

const getUniqueKeys = (data, dimMap) => {
  // Remove metrics from the data tables
  const nonMetricRows = data.map((row) => row.filter((value, valueId) => dimMap[valueId]));
  // console.log(nonMetricRows);

  // split into key and value
  const allKeys = nonMetricRows.map((keyValue) => ({ name: keyValue.join('|'), value: keyValue }));
  // console.log(allKeys);

  // Remove duplicated keys
  const allKeyNames = allKeys.map((key) => key.name);
  const uniqueKeyNames = [...new Set(allKeyNames)];
  return uniqueKeyNames.map((keyName) => allKeys.find((key) => key.name === keyName));
};

const addMissingKeys = (allPeriodsData) => {
  // Map of which header is a dimension or date, and which isn't (e.g. [true, true, true, false, false])
  const dimensionsMap = allPeriodsData[0].headers.map((header) => {
    const headerDefinition = attributeDefinitions.find((attr) => attr.name === header);
    return headerDefinition.type !== 'metric';
  });

  // We want to have all the combinations of the values of each dimension
  const dimensionsData = allPeriodsData.map((data) => data.rows.map((row) => getDimensionsOfRow(row, dimensionsMap))).flat();
  // List unique values for each dimension
  const uniqueDimensionValues = [];
  for (let i = 0; i < dimensionsData[0].length; i += 1) {
    const valuesCurrentDimension = dimensionsData.map((row) => row[i]);
    let uniqueValues = [];
    if (valuesCurrentDimension[0] instanceof Date) {
      const uniqueDateStringValues = [...new Set(valuesCurrentDimension.map((row) => row.toString()))];
      uniqueValues = uniqueDateStringValues.map((row) => new Date(row));
    } else {
      uniqueValues = [...new Set(valuesCurrentDimension)];
    }
    uniqueDimensionValues[i] = uniqueValues;
  }
  // console.log(uniqueDimensionValues);

  // Find all the combinations for all these values
  const nbDimensions = dimensionsMap.reduce((acc, value) => (acc + (value ? 1 : 0)));
  const allCombinations = nbDimensions > 1 ? findCombinations(uniqueDimensionValues) : uniqueDimensionValues[0].map((el) => [el]);
  // console.log(allCombinations);

  const allCombinationKeys = allCombinations.map((comb) => ({ name: comb.join('|'), value: comb }));
  // console.log(allCombinationKeys);

  // const uniqueKeys = getUniqueKeys(allPeriodsData.map((res) => res.rows).flat(), dimensionsMap);
  // console.log(uniqueKeys);

  const enhancedData = allPeriodsData.map((data) => {
    const currentDataKeys = getUniqueKeys(data.rows, dimensionsMap);
    const currentDataKeyNames = currentDataKeys.map((key) => key.name);
    // for each unique key, check if data has it, if not, need to add it with zeroes for the metrics
    const nbMetrics = dimensionsMap.reduce((acc, value) => (acc + (value ? 0 : 1)));

    const additionalRows = [];
    for (const uniqueKey of allCombinationKeys) {
      // If this uniqueKey is not present in the data, add the key's value to the additionalRows
      if (!currentDataKeyNames.includes(uniqueKey.name)) additionalRows.push([...uniqueKey.value, ...new Array(nbMetrics).fill(0)]);
    }
    // console.log(additionalRows);


    // Add additional rows to the data
    const newData = { ...data, rows: [...data.rows, ...additionalRows] };
    return newData;
  });
  return enhancedData;
};

const sortData = (data) => {
  let dimensionsToSortBy = data.headers.filter((header) => ['dimension', 'date'].includes(attributeDefinitions.find((attr) => attr.name === header).type));
  if (dimensionsToSortBy.length > 20) throw Error('More than 20 dimensions to sort by');

  // remove the dimension 'ga:date', we'll sort by that at the end
  dimensionsToSortBy = dimensionsToSortBy.filter((dim) => dim !== 'ga:date');
  // get the index of those dimensions
  const idsToSortBy = dimensionsToSortBy.map((dim) => data.headers.indexOf(dim));

  const sortedRows = data.rows;
  for (let i = 0; i < idsToSortBy.length; i += 1) {
    sortedRows.sort((a, b) => {
      if (a[i] > b[i]) return 1;
      if (a[i] < b[i]) return -1;
      return 0;
    });
  }
  // if dimenions include ga:date, sort by that
  if (data.headers.includes('ga:date')) {
    const dateIndex = data.headers.indexOf('ga:date');
    sortedRows.sort((a, b) => a[dateIndex] - b[dateIndex]);
  }
  const newData = { ...data, rows: sortedRows };
  return newData;
};


export {
  validateGAquery,
  createRequests,
  formatGAoutput,
  splitResponsesByPeriod,
  aggregateReportRows,
  aggregateReportTotals,
  aggregateReport,
  realignDates,
  addMissingKeys,
  sortData,
};
