import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FileToUploadRowContainer from './FileToUploadRow/FileToUploadRowContainer';
import './FilesToUpload.css';

const FilesToUpload = ({ files }) => (
  <div className="filesToUploadSection">
    {files.map((file) => <FileToUploadRowContainer key={file.fileBody.name} file={file} />)}
  </div>
);

FilesToUpload.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    fileBody: PropTypes.object.isRequired,
    presignedS3URL: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = (store) => ({
  files: store.uploadReducer.filesToUpload,
});
export default connect(mapStateToProps)(FilesToUpload);
