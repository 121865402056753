import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Toaster, Intent } from '@blueprintjs/core';
// import { metricsPropsTypes, dimensionsPropsTypes, chartTypePropTypes } from 'AppModules/Titan/Definitions/propTypes';
import filtersActions from 'AppModules/Titan/Actions/filtersActions';
import { filterPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import FiltersGroup from './FiltersGroup';

const FiltersGroupContainer = (props) => {
  const handleAddFilter = () => {
    props.addFilter(props.dashboardID, props.chartGroupID);
  };

  return (
    <FiltersGroup
      dashboardID={props.dashboardID}
      chartGroupID={props.chartGroupID}
      chartFilters={props.chartFilters}
      handleAddFilter={handleAddFilter}
    />
  );
};

FiltersGroupContainer.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  chartFilters: PropTypes.arrayOf(filterPropTypes).isRequired,
  addFilter: PropTypes.func.isRequired,

};

const mapStateToProps = (store, ownProps) => ({
  chartFilters: store.titanReducer.dashboards[ownProps.dashboardID].chartGroups[ownProps.chartGroupID].chartParameters.filters,
});

const mapDispatchToProp = (dispatch) => ({
  addFilter: (dashboardID, chartGroupID, filterAttributeName) => dispatch(filtersActions.addFilter(dashboardID, chartGroupID, filterAttributeName)),
});

export default connect(mapStateToProps, mapDispatchToProp)(FiltersGroupContainer);
