/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import dataGeneratorActions from '../../../Actions/dataGeneratorActions';
import ParamDefinition from './ParamDefinition';
import './ParamDefinition.css';

const ParamDefinitionContainer = (props) => {
  const handleChangeRange = (value) => {
    const rangeValue = [Math.round(value[0] * 10) / 10, Math.round(value[1] * 10) / 10];

    props.updateParameterRangeValue({
      categoryName: props.categoryName,
      parameterName: props.parameterName,
      parameterRangeValue: rangeValue,
    });
  };

  return <ParamDefinition parameter={props.parameter} parameterName={props.parameterName} handleChangeRange={handleChangeRange} />;
};

const mapDispatchToProps = (dispatch) => ({
  updateParameterRangeValue: (options) => dispatch(dataGeneratorActions.updateParameterRangeValue(options)),
});

export default connect(null, mapDispatchToProps)(ParamDefinitionContainer);
