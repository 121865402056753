/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import filtersActions from 'AppModules/Titan/Actions/filtersActions';
import attributeDefinitions from 'AppModules/Titan/Definitions/attributeDefinitions';
import Filter from './Filter';

const FilterContainer = ({ filter, ...props }) => {
  const attributeDefinition = attributeDefinitions.find((attr) => attr.name === filter.name);

  const [filtersSelectionList, setFiltersSelectionList] = React.useState([]);

  React.useEffect(() => {
    let unselectedFilters = [];
    if (attributeDefinition && attributeDefinition.useAsMainFilter) {
      unselectedFilters = [{ name: attributeDefinition.name, displayName: attributeDefinition.displayName }];
    } else {
      for (const attribute of attributeDefinitions.filter((attr) => attr.useAsFilter)) {
        if ((filter.name === attribute.name) || (!props.chartFilters.map((el) => el.name).includes(attribute.name))) {
          unselectedFilters.push({ name: attribute.name, displayName: attribute.displayName });
        }
      }
    }
    setFiltersSelectionList(unselectedFilters);
  }, [attributeDefinition, filter.name, props.chartFilters]);

  const handleChangeFilterAttribute = (newFilterAttributeDisplayName) => {
    // find attribute name corresponding to this displayName
    const newFilterAttributeName = filtersSelectionList.find((attr) => attr.displayName === newFilterAttributeDisplayName).name;
    props.updateFilterAttribute(props.dashboardID, props.chartGroupID, props.filterID, newFilterAttributeName);
  };

  const handleRemoveFilter = () => {
    props.removeFilter(props.dashboardID, props.chartGroupID, props.filterID);
  };

  const handleChangeFilterValue = (values) => {
    props.updateFilterValues(props.dashboardID, props.chartGroupID, props.filterID, values);
  };

  return (
    <Filter
      filter={filter}
      attributeDefinition={attributeDefinition}
      filtersSelectionList={filtersSelectionList}
      handleRemoveFilter={handleRemoveFilter}
      handleChangeFilterAttribute={handleChangeFilterAttribute}
      handleChangeFilterValue={handleChangeFilterValue}
    />
  );
};

FilterContainer.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  filterID: PropTypes.number.isRequired,
  filter: filterPropTypes.isRequired,
  chartFilters: PropTypes.arrayOf(filterPropTypes).isRequired,
  updateFilterAttribute: PropTypes.func.isRequired,
  updateFilterValues: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  updateFilterAttribute: (dashboardID, chartGroupID, filterID, newFilterAttributeName) => {
    dispatch(filtersActions.updateFilterAttribute(dashboardID, chartGroupID, filterID, newFilterAttributeName));
  },
  updateFilterValues: (dashboardID, chartGroupID, filterID, values) => {
    dispatch(filtersActions.updateFilterValues(dashboardID, chartGroupID, filterID, values));
  },
  removeFilter: (dashboardID, chartGroupID, filterID) => {
    dispatch(filtersActions.removeFilter(dashboardID, chartGroupID, filterID));
  },
});

export default connect(null, mapDispatchToProps)(FilterContainer);
