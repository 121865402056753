/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { extractQueryStringParams } from 'utils/util';
import Explorer from './Explorer';
import { screenshotPropTypes } from '../Definitions/ganymedePropTypes';
import ganymedeActions from '../Actions/ganymedeActions';

const ExplorerContainer = (props) => {
  // console.log('Rendering ExplorerContainer');
  // console.log(props);
  // If URL contains filterSelection in a query string, set filtersSelection and redirect to general URL
  const { search } = useLocation();
  if (search !== '') {
    const params = extractQueryStringParams(decodeURIComponent(search));
    // set filtersSelection for each param
    props.setFiltersFromLink(params);

    return <Redirect to="/Ganymede/Explorer" />;
  }
  return (
    <Explorer
      isUpdating={props.isUpdating}
      screenshots={props.screenshots.length > 500 ? props.screenshots.slice(0, 500) : props.screenshots}
    />
  );
};

ExplorerContainer.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
  screenshots: PropTypes.arrayOf(screenshotPropTypes).isRequired,
  setFiltersFromLink: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
  screenshots: store.ganymedeReducer.screenshots,
  isUpdating: store.ganymedeReducer.pagesIsUpdating,
});

const mapDispatchToProps = (dispatch) => ({
  setFiltersFromLink: (filtersSelection) => dispatch(ganymedeActions.setFiltersFromLink(filtersSelection)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExplorerContainer);
