import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { Toaster, Intent } from '@blueprintjs/core';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';
import { chartGroupPropTypes, chartTypePropTypes } from 'AppModules/Titan/Definitions/propTypes';
import titanAPI from 'AppModules/Titan/API/titanAPI';
import { titanGAUseQueryParams } from 'AppModules/Titan/util/constants';
import { chartTypes } from 'AppModules/Titan/Definitions/chartTypes';
import ChartGroup from './ChartGroup';

const queryToaster = Toaster.create();

const ChartGroupContainer = (props) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [chartData, setChartData] = React.useState([]);

  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  const chartQuery = useQuery(['TitanQuery', { GA_token: props.GA_token, ...props.chartGroup.chartQuery }], titanAPI.fetchGAData, titanGAUseQueryParams);

  React.useEffect(() => {
    if (chartQuery.status === 'success') {
      if (chartQuery.data && chartQuery.data[0]) {
        setChartData(chartQuery.data);
        stableDispatch(chartGroupsActions.setDataIsLoaded(props.dashboardID, props.chartGroupID, true));
      }
    } else if (chartQuery.status === 'error') {
      queryToaster.show({
        intent: Intent.DANGER,
        icon: 'warning-sign',
        timeout: 10000,
        message: `Error getting data from Google Analytics for chart ${props.chartGroup.name} 
                  (code ${chartQuery.error.code} - ${chartQuery.error.status}): ${chartQuery.error.message}`,
      });
      stableDispatch(chartGroupsActions.setDataIsLoaded(props.dashboardID, props.chartGroupID, false));
    }
  }, [stableDispatch, props.dashboardID, props.chartGroupID, props.chartGroup.name, chartQuery.status, chartQuery.data, chartQuery.error]);

  const handleRemoveChart = () => {
    stableDispatch(chartGroupsActions.removeChart(props.token, props.dashboardID, props.chartGroupID));
  };

  const handleChangeChartName = (name) => {
    stableDispatch(chartGroupsActions.renameChart(props.token, props.dashboardID, props.chartGroupID, name));
  };

  const handleUpdateChart = () => {
    stableDispatch(chartGroupsActions.updateChart(props.dashboardID, props.chartGroupID));
  };

  const handleDuplicateChart = () => {
    stableDispatch(chartGroupsActions.duplicateChart(props.dashboardID, props.chartGroupID));
  };

  const changeIsOpen = () => {
    stableDispatch(chartGroupsActions.handleChangeChartGroupDisplayMode(!isOpen));
    setIsOpen(!isOpen);
  };

  return (
    <ChartGroup
      dashboardID={props.dashboardID}
      chartGroupID={props.chartGroupID}
      chartGroup={props.chartGroup}
      chartType={props.chartType}
      handleChangeChartName={handleChangeChartName}
      isOpen={isOpen}
      changeIsOpen={changeIsOpen}
      handleRemoveChart={handleRemoveChart}
      handleUpdateChart={handleUpdateChart}
      handleDuplicateChart={handleDuplicateChart}
      chartData={chartData}
      dataIsLoading={chartQuery.status === 'loading'}
    />
  );
};

ChartGroupContainer.propTypes = {
  token: PropTypes.string.isRequired,
  GA_token: PropTypes.string.isRequired,
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  chartGroup: chartGroupPropTypes.isRequired,
  chartType: chartTypePropTypes.isRequired,
};

const mapStateToProps = (store, ownProps) => ({
  token: store.authReducer.token,
  GA_token: store.titanReducer.GA_token,
  chartGroup: store.titanReducer.dashboards[ownProps.dashboardID].chartGroups[ownProps.chartGroupID],
  chartType: chartTypes.find((chartType) => (
    chartType.name === store.titanReducer.dashboards[ownProps.dashboardID].chartGroups[ownProps.chartGroupID].chartParameters.type)),
});

export default connect(mapStateToProps)(ChartGroupContainer);
