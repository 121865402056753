import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import { chartGroupPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import ChartGroupContainer from './ChartGroup/ChartGroupContainer';

const ChartGroups = (props) => (
  <div className="ChartGroups">
    {props.chartGroups.map((chartGroup, chartGroupID) => (
      <ChartGroupContainer
        key={chartGroupID.toString()}
        dashboardID={props.dashboardID}
        chartGroupID={chartGroupID}
      />
    ))}
    <Button
      text="Add Chart"
      icon="insert"
      intent={Intent.PRIMARY}
      onClick={props.handleAddChart}
    />
  </div>
);

ChartGroups.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroups: PropTypes.arrayOf(chartGroupPropTypes).isRequired,
  handleAddChart: PropTypes.func.isRequired,
};

export default ChartGroups;
