import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import { chartTracePropTypes, chartLayoutPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import Plotly from 'plotly.js-cartesian-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import './Chart.css';

const Plot = createPlotlyComponent(Plotly);

const Chart = (props) => (
  <div className="Chart">
    {props.showSortingButton && (
      <Button
        className="sortButton"
        icon={props.sortingOrder === 'original' ? 'sort' : `sort-${props.sortingOrder}`}
        minimal
        onClick={props.handleToggleSortingOrder}
      />
    )}
    <Plot
      data={props.traces}
      layout={{
        ...props.layout,
        width: props.chartWidth,
      }}
      config={{
        displayModeBar: false,
        responsive: true,
      }}
    />
    {/* {props.traces.filter((trace) => trace.yaxis === 'y2') && <Button className="sortButton" icon="sort-asc" minimal />} */}
  </div>
);

Chart.propTypes = {
  traces: PropTypes.arrayOf(chartTracePropTypes).isRequired,
  layout: chartLayoutPropTypes.isRequired,
  chartWidth: PropTypes.number.isRequired,
  showSortingButton: PropTypes.bool.isRequired,
  sortingOrder: PropTypes.string.isRequired,
  handleToggleSortingOrder: PropTypes.func.isRequired,
};

export default Chart;
