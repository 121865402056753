/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import MyMultiSelect from 'Components/MyMultiSelect/MyMultiSelect';
import { filterPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import './ExplorerFilters.css';
import { DateRangeInput } from '@blueprintjs/datetime';
import { Button, HTMLSelect, Intent } from '@blueprintjs/core';
import definitions from '../../Definitions/definitions';

const ExplorerFilters = (props) => (
  <div className="ExplorerFilters">
    <div className="dateFilters">
      <h4>Date Ranges</h4>
      <section className="primaryDateFilter">
        <DateRangeInput
          formatDate={(date) => format(date, 'MM/dd/yy')}
          onChange={([from, to]) => props.handleUpdateFilter('Date', { from, to })}
          value={[props.filtersSelection.Date.from, props.filtersSelection.Date.to]}
          parseDate={(str) => new Date(str)}
          placeholder="MM/DD/YY"
          allowSingleDayRange
          // closeOnSelection={false}
          startInputProps={{ fill: true }}
          endInputProps={{ fill: true }}
        />
        <Button icon="add" intent={Intent.PRIMARY} onClick={props.handleAddDateFilter} minimal disabled={props.filtersSelection.additionalDates.length > 9} />
      </section>
      {props.filtersSelection.additionalDates.map((additionalDateFilter, additionalDateFilterID) => (
        // eslint-disable-next-line react/no-array-index-key
        <section className="additionalDateFilter" key={additionalDateFilterID}>
          <DateRangeInput
            formatDate={(date) => format(date, 'MM/dd/yy')}
            onChange={([from, to]) => props.handleUpdateFilter(`additionalDate${additionalDateFilterID}`, { from, to })}
            value={[additionalDateFilter.from, additionalDateFilter.to]}
            parseDate={(str) => new Date(str)}
            placeholder="MM/DD/YY"
            allowSingleDayRange
            closeOnSelection={false}
            startInputProps={{ fill: true }}
            endInputProps={{ fill: true }}
            disabled={additionalDateFilter.type !== 'Custom'}
          />
          <HTMLSelect
            options={definitions.dateRangeTypes.map((el) => el.name)}
            value={additionalDateFilter.type}
            onChange={(e) => props.handleChangeDateFilterType(additionalDateFilterID, e.target.value)}
            minimal
          />
          <Button icon="trash" intent={Intent.DANGER} onClick={() => props.handleRemoveDateFilter(additionalDateFilterID)} minimal />
        </section>
      ))}
    </div>
    {props.filters.filter((f) => f.type === 'list').map((filter) => (
      <section key={filter.name}>
        <h4>{filter.name}</h4>
        <MyMultiSelect
          items={filter.values}
          selectedItems={props.filtersSelection[filter.name]}
          updateSelectedItems={(newItems) => props.handleUpdateFilter(filter.name, newItems)}
        />
      </section>
    ))}
  </div>
);

ExplorerFilters.propTypes = {
  filters: PropTypes.arrayOf(filterPropTypes).isRequired,
  filtersSelection: PropTypes.object.isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
  handleAddDateFilter: PropTypes.func.isRequired,
  handleRemoveDateFilter: PropTypes.func.isRequired,
  handleChangeDateFilterType: PropTypes.func.isRequired,
};

export default ExplorerFilters;
