/* eslint-disable react/prop-types */
import React from 'react';
import { InputGroup } from '@blueprintjs/core';

const NumberOfItems = ({ category, numberOfItems, handleChangeValue }) => {
  const nbDigits = Math.floor(Math.log10(numberOfItems));
  const startingNumber = `${new Array(nbDigits + 1).join(0)}1`;
  return (
    <div className="ParamDefinition NumberOfItemsDefinition">
      <header>
        <h4>
          Number of
          {' '}
          {category.toLowerCase()}
          :
          {' '}
        </h4>
        <InputGroup
          className="ParamDefinitionInput"
          onChange={handleChangeValue}
          value={numberOfItems}
        />
      </header>
      {category === 'Years' ? (
        <small className="helperText">
          ↳ The
          {' '}
          {category.toLowerCase()}
          {' '}
          will range between
          {' '}
          <b>{(new Date()).getFullYear() + 1 - numberOfItems}</b>
          {' '}
          and
          {' '}
          <b>{(new Date()).getFullYear()}</b>
        </small>
      ) : (
        <small className="helperText">
          ↳ The
          {' '}
          {category.toLowerCase()}
          {' '}
          will range between
          {' '}
          <b>
            &quot;
            {category.slice(0, -1).toLowerCase()}
            {startingNumber}
          </b>
          &quot; and &quot;
          <b>
            {category.slice(0, -1).toLowerCase()}
            {numberOfItems}
          </b>
          &quot;
        </small>
      )}
    </div>
  );
};

export default NumberOfItems;
