import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import DataGeneratorContainer from './DataGenerator/DataGeneratorContainer';
import UploadContainer from './Upload/UploadContainer';
import DatasetsContainer from './Datasets/DatasetsContainer';
import DatasetContainer from './Datasets/Dataset/DatasetContainer';
import uploadActions from './Actions/uploadActions';
import datasetsActions from './Actions/datasetsActions';

const DataPage = () => {
  const { token } = useSelector((store) => store.authReducer);
  const { datasets } = useSelector((store) => store.datasetsReducer);
  const nbDatasets = datasets.length;

  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);
  // When mounting: load the file list and the datasets list
  React.useEffect(() => {
    stableDispatch(uploadActions.updateFileList(token));
    if (nbDatasets === 0) stableDispatch(datasetsActions.loadDatasets(token)); // only loading if no datasets...because user can just reload if wants to update?
  }, [stableDispatch, nbDatasets, token]);

  return (
    <div>
      <Switch>
        <Route exact path="/Data/Data Generator" component={DataGeneratorContainer} />
        <Route exact path="/Data/Upload" component={UploadContainer} />
        <Route exact path="/Data/Datasets" component={DatasetsContainer} />
        <Route exact path="/Data/Dataset/:datasetName" component={DatasetContainer} />
      </Switch>
    </div>
  );
};

export default DataPage;
