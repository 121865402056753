import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Popover, Menu, MenuItem, Position } from '@blueprintjs/core';
import { attributeParameterPropTypes, attributePropTypes, parameterGroupPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import { parameterTypes } from 'AppModules/Titan/Definitions/chartTypes';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';


const AttributeListParameter = ({ attribute, parameterName, ...props }) => {
  // console.log({ parameterDefinition: props.parameterDefinition });
  // console.log({ attribute: props.attribute });
  // console.log({ parameterName: parameterName });

  const [parameterValues, setParameterValues] = React.useState([]);

  React.useEffect(() => {
    setParameterValues(parameterTypes.find((param) => param.name === parameterName).values);
  }, [attribute, parameterName]);

  const handleChangeParameter = (parameterValue) => {
    props.changeAttributeParameter(props.dashboardID, props.chartGroupID, props.parameterGroup.type, props.attributeID, parameterName, parameterValue);
  };

  return (
    <Popover position={Position.BOTTOM_LEFT}>
      <Button
        icon={props.parameterDefinition.values.find((value) => value.name === attribute[parameterName]).icon}
        disabled={props.disabled}
      />
      <Menu>
        {parameterValues.map((param) => (
          <MenuItem
            key={param.name}
            text={param.displayName}
            icon={param.icon}
            active={param.name === attribute[parameterName]}
            onClick={() => handleChangeParameter(param.name)}
          />
        ))}
      </Menu>
    </Popover>
  );
};

AttributeListParameter.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  attributeID: PropTypes.number.isRequired,
  parameterGroup: parameterGroupPropTypes.isRequired,
  attribute: attributePropTypes.isRequired,
  parameterName: PropTypes.string.isRequired,
  parameterDefinition: attributeParameterPropTypes.isRequired,
  changeAttributeParameter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AttributeListParameter.defaultProps = {
  disabled: false,
};

const mapDispatchToProps = (dispatch) => ({
  changeAttributeParameter: (dashboardID, chartGroupID, attributeType, attributeID, parameterName, parameterValue) => {
    dispatch(chartGroupsActions.changeAttributeParameter(dashboardID, chartGroupID, attributeType, attributeID, parameterName, parameterValue));
  },
});

export default connect(null, mapDispatchToProps)(AttributeListParameter);
