import customIcons from '../../../Components/CustomIcons/customIcons';

// const palette1 = [
//   [
//     {
//       name: 'blue',
//       displayName: 'Blue',
//       value: '#2965CC',
//       icon: 'tint',
//     },
//     {
//       name: 'green',
//       displayName: 'Green',
//       value: '#29A634',
//       icon: 'tint',
//     },
//     {
//       name: 'yellow',
//       displayName: 'Yellow',
//       value: '#D99E0B',
//       icon: 'tint',
//     },
//     {
//       name: 'red',
//       displayName: 'Red',
//       value: '#D13913',
//       icon: 'tint',
//     },
//   ], [
//     {
//       name: 'purple',
//       displayName: 'Purple',
//       value: '#8F398F',
//       icon: 'tint',
//     },
//     {
//       name: 'cyan',
//       displayName: 'Cyan',
//       value: '#00B3A4',
//       icon: 'tint',
//     },
//     {
//       name: 'magenta',
//       displayName: 'Magenta',
//       value: '#DB2C6F',
//       icon: 'tint',
//     },
//     {
//       name: 'limegreen',
//       displayName: 'Limegreen',
//       value: '#9BBF30',
//       icon: 'tint',
//     },
//   ], [
//     {
//       name: 'brown',
//       displayName: 'Brown',
//       value: '#96622D',
//       icon: 'tint',
//     },
//     {
//       name: 'lilac',
//       displayName: 'Lilac',
//       value: '#7157D9',
//       icon: 'tint',
//     },
//   ],
// ];

const palette2 = [
  ['#FF6E4A', '#EB532D', '#D13913', '#B83211', '#9E2B0E'].map((color, i) => ({ name: `vermilion${i}`, displayName: `Vermilion ${i}`, value: color, icon: 'tint' })),
  // ['#FF7373', '#F55656', '#DB3737', '#C23030', '#A82A2A'].map((color, i) => ({ name: `red${i}`, displayName: `Red ${i}`, value: color, icon: 'tint' })),
  ['#FF66A1', '#F5498B', '#DB2C6F', '#C22762', '#A82255'].map((color, i) => ({ name: `rose${i}`, displayName: `Rose ${i}`, value: color, icon: 'tint' })),
  // ['#C274C2', '#A854A8', '#8F398F', '#752F75', '#5C255C'].map((color, i) => ({ name: `violet${i}`, displayName: `Violet ${i}`, value: color, icon: 'tint' })),
  ['#AD99FF', '#9179F2', '#7157D9', '#634DBF', '#5642A6'].map((color, i) => ({ name: `indigo${i}`, displayName: `Indigo ${i}`, value: color, icon: 'tint' })),
  // ['#669EFF', '#4580E6', '#2965CC', '#2458B3', '#1F4B99'].map((color, i) => ({ name: `cobalt${i}`, displayName: `Cobalt ${i}`, value: color, icon: 'tint' })),
  ['#48AFF0', '#2B95D6', '#137CBD', '#106BA3', '#0E5A8A'].map((color, i) => ({ name: `blue${i}`, displayName: `Blue ${i}`, value: color, icon: 'tint' })),
  // ['#2EE6D6', '#14CCBD', '#00B3A4', '#00998C', '#008075'].map((color, i) => ({ name: `turquoise${i}`, displayName: `Turquoise ${i}`, value: color, icon: 'tint' })),
  ['#62D96B', '#43BF4D', '#29A634', '#238C2C', '#1D7324'].map((color, i) => ({ name: `forest${i}`, displayName: `Forest ${i}`, value: color, icon: 'tint' })),
  // ['#3DCC91', '#15B371', '#0F9960', '#0D8050', '#0A6640'].map((color, i) => ({ name: `green${i}`, displayName: `Green ${i}`, value: color, icon: 'tint' })),
  ['#D1F26D', '#B6D94C', '#9BBF30', '#87A629', '#728C23'].map((color, i) => ({ name: `lime${i}`, displayName: `Lime ${i}`, value: color, icon: 'tint' })),
  // ['#FFC940', '#F2B824', '#D99E0B', '#BF8C0A', '#A67908'].map((color, i) => ({ name: `gold${i}`, displayName: `Gold ${i}`, value: color, icon: 'tint' })),
  ['#FFB366', '#F29D49', '#D9822B', '#BF7326', '#A66321'].map((color, i) => ({ name: `orange${i}`, displayName: `Orange ${i}`, value: color, icon: 'tint' })),
  // ['#C99765', '#B07B46', '#96622D', '#7D5125', '#63411E'].map((color, i) => ({ name: `sepia${i}`, displayName: `Sepia ${i}`, value: color, icon: 'tint' })),
];

const colorValues = [
  ['#FF6E4A', '#EB532D', '#D13913', '#B83211', '#9E2B0E'], // Vermilion
  ['#48AFF0', '#2B95D6', '#137CBD', '#106BA3', '#0E5A8A'], // Blue
  ['#62D96B', '#43BF4D', '#29A634', '#238C2C', '#1D7324'], // Forest
  ['#FF66A1', '#F5498B', '#DB2C6F', '#C22762', '#A82255'], // Rose
  ['#D1F26D', '#B6D94C', '#9BBF30', '#87A629', '#728C23'], // Lime
  ['#FFB366', '#F29D49', '#D9822B', '#BF7326', '#A66321'], // Orange
  ['#AD99FF', '#9179F2', '#7157D9', '#634DBF', '#5642A6'], // Indigo
];

const colorByPalette2 = [
  ...colorValues.map((val) => val[0]),
  ...colorValues.map((val) => val[2]),
  ...colorValues.map((val) => val[4]),
  ...colorValues.map((val) => val[1]),
  ...colorValues.map((val) => val[3]),
];

const colorDefinitions = palette2;
const colorByDefinitions = colorByPalette2;

const lineTypesDefinitions = [
  [
    {
      name: 'thin-solid',
      displayName: 'thin-solid',
      width: 1,
      dash: 'solid',
      icon: customIcons.thinSolidLine,
    },
    {
      name: 'medium-solid',
      displayName: 'medium-solid',
      width: 2,
      dash: 'solid',
      icon: customIcons.mediumSolidLine,
    },
    {
      name: 'thick-solid',
      displayName: 'thick-solid',
      width: 3,
      dash: 'solid',
      icon: customIcons.thickSolidLine,
    },
  ], [
    {
      name: 'thin-dashed',
      displayName: 'thin-dashed',
      width: 1,
      dash: 'longdash',
      icon: customIcons.thinDashedLine,
    },
    {
      name: 'medium-dashed',
      displayName: 'medium-dashed',
      width: 2,
      dash: 'longdash',
      icon: customIcons.mediumDashedLine,
    },
    {
      name: 'thick-dashed',
      displayName: 'thick-dashed',
      width: 3,
      dash: 'longdash',
      icon: customIcons.thickDashedLine,
    },
  ], [
    {
      name: 'thin-small-dashed',
      displayName: 'thin-small-dashed',
      width: 1,
      dash: 'dash',
      icon: customIcons.thinSmallDashedLine,
    },
    {
      name: 'medium-small-dashed',
      displayName: 'medium-small-dashed',
      width: 2,
      dash: 'dash',
      icon: customIcons.mediumSmallDashedLine,
    },
    {
      name: 'thick-small-dashed',
      displayName: 'thick-small-dashed',
      width: 3,
      dash: 'dash',
      icon: customIcons.thickSmallDashedLine,
    },
  ], [
    {
      name: 'thin-dash-dots',
      displayName: 'thin-dash-dots',
      width: 1,
      dash: 'dashdot',
      icon: customIcons.thinDashDotLine,
    },
    {
      name: 'medium-dash-dots',
      displayName: 'medium-dash-dots',
      width: 2,
      dash: 'dashdot',
      icon: customIcons.mediumDashDotLine,
    },
    {
      name: 'thick-dash-dots',
      displayName: 'thick-dash-dots',
      width: 3,
      dash: 'dashdot',
      icon: customIcons.thickDashDotLine,
    },
  ], [

    {
      name: 'thin-dots',
      displayName: 'thin-dots',
      width: 1,
      dash: 'dot',
      icon: customIcons.thinDotsLine,
    },
    {
      name: 'medium-dots',
      displayName: 'medium-dots',
      width: 2,
      dash: 'dot',
      icon: customIcons.mediumDotsLine,
    },
    {
      name: 'thick-dots',
      displayName: 'thick-dots',
      width: 3,
      dash: 'dot',
      icon: customIcons.thickDotsLine,
    },
  ],
];

const lineTypesByDefinitions = [
  {
    width: 2,
    dash: 'solid',
  },
  {
    width: 2,
    dash: 'dash',
  },
  {
    width: 2,
    dash: 'longdash',
  },
  {
    width: 2,
    dash: 'dashdot',
  },
  {
    width: 2,
    dash: 'dot',
  },
  {
    width: 1,
    dash: 'solid',
  },
  {
    width: 1,
    dash: 'dash',
  },
  {
    width: 1,
    dash: 'longdash',
  },
  {
    width: 1,
    dash: 'dashdot',
  },
  {
    width: 1,
    dash: 'dot',
  },
  {
    width: 3,
    dash: 'solid',
  },
  {
    width: 3,
    dash: 'dash',
  },
  {
    width: 3,
    dash: 'longdash',
  },
  {
    width: 3,
    dash: 'dashdot',
  },
  {
    width: 3,
    dash: 'dot',
  },
];

const comparisonDefinitions = [
  {
    name: 'prior-year',
    displayName: 'Prior Year',
    shortName: 'PY',
    defaults: {
      // comparisonDisplayType: 'color',
      color: colorDefinitions[0][0].name,
      lineType: lineTypesDefinitions[0][1].name,
    },
    type: 'relative',
    timeDiff: {
      years: 0,
      months: 0,
      weeks: 0,
      days: 364,
    },
  },
  {
    name: '2py',
    displayName: '2 Years Prior',
    shortName: '2PY',
    defaults: {
      // comparisonDisplayType: 'color',
      color: colorDefinitions[0][0].name,
      lineType: lineTypesDefinitions[0][1].name,
    },
    type: 'relative',
    timeDiff: {
      years: 0,
      months: 0,
      weeks: 0,
      days: 728,
    },
    cumulativeGap: undefined,
  },
  {
    name: '3py',
    displayName: '3 Years Prior',
    shortName: '3PY',
    defaults: {
      // comparisonDisplayType: 'color',
      color: colorDefinitions[0][0].name,
      lineType: lineTypesDefinitions[0][1].name,
    },
    type: 'relative',
    timeDiff: {
      years: 0,
      months: 0,
      weeks: 0,
      days: 1092,
    },
    cumulativeGap: undefined,
  },
  {
    name: 'prior-month',
    displayName: 'Prior Month',
    shortName: 'PM',
    defaults: {
      // comparisonDisplayType: 'color',
      color: colorDefinitions[0][0].name,
      lineType: lineTypesDefinitions[0][1].name,
    },
    type: 'relative',
    timeDiff: {
      years: 0,
      months: 1,
      weeks: 0,
      days: 0,
    },
  },
  {
    name: 'pw',
    displayName: 'Prior Week',
    shortName: 'PW',
    defaults: {
      // comparisonDisplayType: 'color',
      color: colorDefinitions[0][0].name,
      lineType: lineTypesDefinitions[0][1].name,
    },
    type: 'relative',
    timeDiff: {
      years: 0,
      months: 0,
      weeks: 1,
      days: 0,
    },
  },
  {
    name: '2pw',
    displayName: '2 Weeks Prior',
    shortName: '2PW',
    defaults: {
      // comparisonDisplayType: 'color',
      color: colorDefinitions[0][0].name,
      lineType: lineTypesDefinitions[0][1].name,
    },
    type: 'relative',
    timeDiff: {
      years: 0,
      months: 0,
      weeks: 2,
      days: 0,
    },
  },
  {
    name: '3pw',
    displayName: '3 Weeks Prior',
    shortName: '3PW',
    defaults: {
      // comparisonDisplayType: 'color',
      color: colorDefinitions[0][0].name,
      lineType: lineTypesDefinitions[0][1].name,
    },
    type: 'relative',
    timeDiff: {
      years: 0,
      months: 0,
      weeks: 3,
      days: 0,
    },
  },
];

export {
  colorDefinitions,
  colorByDefinitions,
  lineTypesDefinitions,
  lineTypesByDefinitions,
  comparisonDefinitions,
};
