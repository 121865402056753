/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import NumberOfItems from './NumberOfItems';
import dataGeneratorActions from '../../../Actions/dataGeneratorActions';

const NumberOfItemsContainer = (props) => {
  const handleChangeValue = (e) => {
    const numberOfItems = Math.max(e.target.value, 1);
    props.updateNumberOfItems({
      categoryName: props.categoryName,
      numberOfItems,
    });
  };

  return <NumberOfItems category={props.categoryName} numberOfItems={props.numberOfItems} handleChangeValue={handleChangeValue} />;
};

const mapDispatchToProps = (dispatch) => ({
  updateNumberOfItems: (options) => dispatch(dataGeneratorActions.updateNumberOfItems(options)),
});

export default connect(null, mapDispatchToProps)(NumberOfItemsContainer);
