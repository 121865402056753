const appName = {
  dev: 'MasterPlan-dev',
  production: 'MasterPlan',
};

const sideBarItems = [
  {
    name: 'Data',
    iconName: 'database',
    items: ['Data Generator', 'Upload', 'Datasets'],
  },
  // {
  //   name: 'Analysis',
  //   iconName: 'timeline-line-chart',
  //   items: ['Chart Builder']
  //   // items: ['Dashboard', 'Standard Analyses', 'Custom Analyses', 'ROI Tree', 'Variance Waterfall']
  // },
  // {
  //   name: 'Planning',
  //   iconName: 'timeline-events',
  //   items: ['Overview', 'Calendar', 'Budget Optimizer']
  // }
  {
    name: 'Titan',
    iconName: 'chart',
    items: ['Dashboards'],
  },
  {
    name: 'Ganymede',
    iconName: 'comparison',
    items: ['Manage', 'Explorer'],
  },
];

const demoMode = false;

export { appName, sideBarItems, demoMode };
