import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import { formatFileSize } from '../../../../../../utils/util';
import MyCircularProgressBar from '../../../../../../Components/MyCircularProgressBar/MyCircularProgressBar';

const FileToUploadRow = (props) => (
  <div className="fileToUploadRowSection">
    <MyCircularProgressBar progress={props.progress} isRunning={props.uploading} size={40} />
    <p className="fileName">{props.file.fileBody.name}</p>
    <p className="fileSize">{formatFileSize(props.file.fileBody.size)}</p>
    <Button icon="cloud-upload" onClick={props.handleUploadFile} disabled={props.file.presignedS3URL === '' || props.uploading} />
    <Button icon="trash" onClick={() => props.removeFileToUpload(props.file.fileBody.name)} />
  </div>
);

FileToUploadRow.propTypes = {
  file: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    fileBody: PropTypes.object.isRequired,
    presignedS3URL: PropTypes.string.isRequired,
  }).isRequired,
  handleUploadFile: PropTypes.func.isRequired,
  removeFileToUpload: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  uploading: PropTypes.bool.isRequired,
};

export default FileToUploadRow;
