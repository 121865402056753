const dateRangeTypes = [
  {
    name: 'PY',
    timeDiff: { days: 364 },
  },
  {
    name: 'PPY',
    timeDiff: { days: 728 },
  },
  {
    name: 'Custom',
    timeDiff: { days: 0 },
  },
];

const otherObject = {};

export default {
  dateRangeTypes,
  otherObject,
};
