import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Toaster, Intent } from '@blueprintjs/core';
import { chartDataPropTypes, chartParametersPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import { getNextArrayValue } from 'utils/util';
import Chart from './Chart';
import { calculateChartSize, sortTraces, truncateTraces } from './chartUtils';
import buildTraces from './chartEngine';

const errorToaster = Toaster.create();

const ChartContainer = (props) => {
  // console.log('Rendering');
  const [traces, setTraces] = React.useState([]);
  const [originalTraces, setOriginalTraces] = React.useState([]); // Needed when sorting back to original order
  const [layout, setLayout] = React.useState({});
  const [chartWidth, setChartWidth] = React.useState(calculateChartSize('ROWS'));
  const [sortingOrder, setSortingOrder] = React.useState('original');

  // When window is resized, update chart width
  React.useEffect(() => {
    const handleResize = () => setChartWidth(calculateChartSize('ROWS'));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateLayoutTheme = (currrentLayout) => {
    if (Object.keys(currrentLayout).length > 0) {
      const newLayout = JSON.parse(JSON.stringify(currrentLayout));

      // get light/dark mode colors
      const style = window.getComputedStyle(document.body);
      const gridcolor = style.getPropertyValue('--elevatedBackground').trim();
      const zerolinecolor = style.getPropertyValue('--disabledTextColor').trim();
      const mutedGridcolor = style.getPropertyValue('--appBackground').trim();

      // set values
      newLayout.paper_bgcolor = style.getPropertyValue('--sectionBackground').trim();
      newLayout.plot_bgcolor = style.getPropertyValue('--sectionBackground').trim();
      newLayout.font = { ...newLayout.font, color: style.getPropertyValue('--mutedTextColor').trim() };
      if (currrentLayout.xaxis.showgrid) newLayout.xaxis = { ...currrentLayout.xaxis, gridcolor, zerolinecolor };
      if (currrentLayout.yaxis.showgrid) newLayout.yaxis = { ...currrentLayout.yaxis, gridcolor, zerolinecolor };
      if (currrentLayout.xaxis2.showgrid) newLayout.xaxis2 = { ...currrentLayout.xaxis2, gridcolor: mutedGridcolor, zerolinecolor };
      if (currrentLayout.yaxis2.showgrid) newLayout.yaxis2 = { ...currrentLayout.yaxis2, gridcolor: mutedGridcolor, zerolinecolor };

      return newLayout;
    }
    return null;
  };

  // When darkMode changes, update chart colors
  React.useEffect(() => {
    setLayout((oldLayout) => updateLayoutTheme(oldLayout));
  }, [props.isDarkMode]);

  // When chartData or chartParameters change, rebuild the traces
  React.useEffect(() => {
    try {
      const { chartTraces, chartLayout } = buildTraces(props.chartData, props.chartParameters);
      setLayout(updateLayoutTheme(chartLayout));
      setOriginalTraces(chartTraces); // for when reverting to original sorting order
    } catch (error) {
      errorToaster.show({ message: `Error: ${error.message}`, intent: Intent.DANGER, icon: 'warning-sign' });
    }
  }, [props.chartData, props.chartParameters]);

  // When sortingOrder changes, sort the traces
  React.useEffect(() => {
    const orderedTraces = sortTraces(sortingOrder, originalTraces, props.chartParameters.type);
    // Truncate traces data if necessary
    const truncatedTraces = truncateTraces(orderedTraces, props.chartParameters);
    setTraces(truncatedTraces);
  }, [sortingOrder, originalTraces, props.chartParameters]);


  const handleToggleSortingOrder = () => {
    const sortingOrders = ['original', 'desc', 'asc', 'alphabetical', 'alphabetical-desc'];
    const newSortingOrder = getNextArrayValue(sortingOrders, sortingOrder);
    setSortingOrder(newSortingOrder);
  };

  return (
    traces.length > 0
    && (
      <Chart
        traces={traces}
        layout={layout}
        chartWidth={chartWidth}
        showSortingButton={!props.chartParameters.dimensions.map((attr) => attr.name).includes('ga:date')} // /!\ If allowing sorting when date is present, the sorting algorithm needs to be altered to support sorting on dates (because it uses JSON.parse(JSON.stringify()), which destroys dates)
        handleToggleSortingOrder={handleToggleSortingOrder}
        sortingOrder={sortingOrder}
      />
    )
  );
};

ChartContainer.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  chartData: chartDataPropTypes.isRequired,
  chartParameters: chartParametersPropTypes.isRequired,
};

const mapStateToProps = (store, ownProps) => ({
  isDarkMode: store.userReducer.userSettings.isDarkMode,
  chartParameters: store.titanReducer.dashboards[ownProps.dashboardID].chartGroups[ownProps.chartGroupID].chartParametersOfLoadedData,
});

export default connect(mapStateToProps)(ChartContainer);
