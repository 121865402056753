import titanActionTypes from './titanActionTypes';
import titanChartGroupAPI from '../API/titanChartGroupAPI';

const validateParameters = (dashboardID, chartGroupID) => ({
  type: titanActionTypes.VALIDATE_PARAMETERS,
  dashboardID,
  chartGroupID,
});

const checkChartIsSynced = (dashboardID, chartGroupID) => ({
  type: titanActionTypes.CHECK_CHART_IS_SYNCED,
  dashboardID,
  chartGroupID,
});

const addChart = (token, dashboardID) => async (dispatch) => {
  await titanChartGroupAPI.addChart(token, dashboardID);
  dispatch({
    type: titanActionTypes.ADD_CHART,
    dashboardID,
  });
};

const removeChart = (token, dashboardID, chartGroupID) => async (dispatch) => {
  await titanChartGroupAPI.removeChart(token, dashboardID, chartGroupID);
  dispatch({
    type: titanActionTypes.REMOVE_CHART,
    dashboardID,
    chartGroupID,
  });
};

const renameChart = (dashboardID, chartGroupID, name) => async (dispatch) => {
  await titanChartGroupAPI.renameChart(dashboardID, chartGroupID, name);
  dispatch({
    type: titanActionTypes.RENAME_CHART,
    dashboardID,
    chartGroupID,
    name,
  });
};

const updateChart = (dashboardID, chartGroupID) => (dispatch) => {
  dispatch({
    type: titanActionTypes.UPDATE_CHART,
    dashboardID,
    chartGroupID,
  });
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const duplicateChart = (dashboardID, chartGroupID) => (dispatch) => {
  dispatch({
    type: titanActionTypes.DUPLICATE_CHART,
    dashboardID,
    chartGroupID,
  });
};

const handleChangeChartGroupDisplayMode = () => ({
  // currently not implemented in reducer, doesn't seem necessary to save this in store
  type: titanActionTypes.CHANGE_CHARTGROUP_DISPLAYMODE,
});

const changeChartType = (dashboardID, chartGroupID, chartType) => (dispatch) => {
  dispatch({
    type: titanActionTypes.CHANGE_CHART_TYPE,
    dashboardID,
    chartGroupID,
    chartType,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const addAttribute = (dashboardID, chartGroupID, attributeType) => (dispatch) => {
  dispatch({
    type: titanActionTypes.ADD_ATTRIBUTE,
    dashboardID,
    chartGroupID,
    attributeType,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const removeAttribute = (dashboardID, chartGroupID, attributeType, attributeID) => (dispatch) => {
  dispatch({
    type: titanActionTypes.REMOVE_ATTRIBUTE,
    dashboardID,
    chartGroupID,
    attributeType,
    attributeID,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const changeAttribute = (dashboardID, chartGroupID, attributeType, attributeID, newAttributeName) => (dispatch) => {
  dispatch({
    type: titanActionTypes.CHANGE_ATTRIBUTE,
    dashboardID,
    chartGroupID,
    attributeType,
    attributeID,
    newAttributeName,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const changeAttributeParameter = (dashboardID, chartGroupID, attributeType, attributeID, parameterName, parameterValue) => (dispatch) => {
  dispatch({
    type: titanActionTypes.CHANGE_ATTRIBUTE_PARAMETER,
    dashboardID,
    chartGroupID,
    attributeType,
    attributeID,
    parameterName,
    parameterValue,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const addComparison = (dashboardID, chartGroupID) => (dispatch) => {
  dispatch({
    type: titanActionTypes.ADD_COMPARISON,
    dashboardID,
    chartGroupID,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const removeComparison = (dashboardID, chartGroupID, comparisonID) => (dispatch) => {
  dispatch({
    type: titanActionTypes.REMOVE_COMPARISON,
    dashboardID,
    chartGroupID,
    comparisonID,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const changeComparison = (dashboardID, chartGroupID, comparisonID, newComparisonName) => (dispatch) => {
  dispatch({
    type: titanActionTypes.CHANGE_COMPARISON,
    dashboardID,
    chartGroupID,
    comparisonID,
    newComparisonName,
  });
  dispatch(validateParameters(dashboardID, chartGroupID));
  dispatch(checkChartIsSynced(dashboardID, chartGroupID));
};

const setDataIsLoaded = (dashboardID, chartGroupID, dataIsLoaded) => ({
  type: titanActionTypes.SET_DATA_IS_LOADED,
  dashboardID,
  chartGroupID,
  dataIsLoaded,
});

export default {
  validateParameters,
  addChart,
  removeChart,
  renameChart,
  updateChart,
  duplicateChart,
  changeChartType,
  handleChangeChartGroupDisplayMode,
  addAttribute,
  removeAttribute,
  changeAttribute,
  changeAttributeParameter,
  addComparison,
  removeComparison,
  changeComparison,
  setDataIsLoaded,
};
