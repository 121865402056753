import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Intent, ControlGroup, NumericInput } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import { format } from 'date-fns';
import { attributeDefinitionPropTypes, filterPropTypes } from 'AppModules/Titan/Definitions/propTypes';
import TitanSuggestBox from 'AppModules/Titan/Components/TitanSuggestBox/TitanSuggestBox';
import TitanMultiSelect from 'AppModules/Titan/Components/TitanMultiSelect/TitanMultiSelect';
import './Filter.css';
import { datePickerShortcuts } from 'AppModules/Titan/util/constants';

const Filter = (props) => (
  <div className="Filter">
    <div className="filterSelection">
      <Icon icon="filter" iconSize={20} />
      <TitanSuggestBox
        selectedItem={props.attributeDefinition && props.attributeDefinition.displayName}
        items={props.filtersSelectionList.map((attr) => attr.displayName)}
        handleChange={props.handleChangeFilterAttribute}
      />
      <Button
        icon="trash"
        intent={Intent.DANGER}
        minimal
        onClick={props.handleRemoveFilter}
        disabled={props.attributeDefinition && props.attributeDefinition.useAsMainFilter}
      />
    </div>
    {props.attributeDefinition && (
      <div className="filterValues">
        {props.attributeDefinition.type === 'dimension'
          && (
            <TitanMultiSelect
              items={props.attributeDefinition.values}
              selectedItems={props.filter.values}
              updateSelectedItems={props.handleChangeFilterValue}
            />
          )}
        {props.attributeDefinition.type === 'metric'
          && (
            <ControlGroup>
              <NumericInput
                value={(props.filter.values && props.filter.values.from !== undefined) ? props.filter.values.from : ''}
                onValueChange={(value) => props.handleChangeFilterValue({ from: value, to: props.filter.values && props.filter.values.to })}
                placeholder="From"
                buttonPosition="none"
                min={props.attributeDefinition.minAllowed}
                max={props.attributeDefinition.maxAllowed}
                fill
              />
              <NumericInput
                value={(props.filter.values && props.filter.values.to !== undefined) ? props.filter.values.to : ''}
                onValueChange={(value) => props.handleChangeFilterValue({ from: props.filter.values && props.filter.values.from, to: value })}
                placeholder="To"
                buttonPosition="none"
                min={props.attributeDefinition.minAllowed}
                max={props.attributeDefinition.maxAllowed}
                fill
              />
            </ControlGroup>
          )}
        {props.attributeDefinition.type === 'date'
          && (
            <DateRangeInput
              formatDate={(date) => format(date, 'MM/dd/yy')}
              onChange={([from, to]) => props.handleChangeFilterValue({ from, to })}
              value={props.filter.values ? [props.filter.values.from, props.filter.values.to] : []}
              parseDate={(str) => new Date(str)}
              placeholder="MM/DD/YY"
              allowSingleDayRange
              closeOnSelection={false}
              startInputProps={{ fill: true }}
              endInputProps={{ fill: true }}
              shortcuts={datePickerShortcuts}
            />
          )}
      </div>
    )}
  </div>
);

Filter.propTypes = {
  filter: filterPropTypes.isRequired,
  attributeDefinition: attributeDefinitionPropTypes,
  filtersSelectionList: PropTypes.arrayOf(filterPropTypes).isRequired,
  handleRemoveFilter: PropTypes.func.isRequired,
  handleChangeFilterAttribute: PropTypes.func.isRequired,
  handleChangeFilterValue: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  attributeDefinition: undefined,
};

export default Filter;
