import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
// import { connect, useSelector, useDispatch } from 'react-redux';
import uploadActions from '../../../../Actions/uploadActions';
import FileToUploadRow from './FileToUploadRow';
import './FileToUploadRow.css';

const FileToUploadRowContainer = (props) => {
  const [uploading, setUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const { token } = useSelector((store) => store.authReducer);

  // const dispatch = useDispatch();
  // const stableDispatch = React.useCallback(dispatch, []);

  const updateProgress = (e) => {
    setProgress(Math.round((100 * e.loaded) / e.total));
  };

  const handleUploadFile = async () => {
    setUploading(true);
    await props.uploadFile(props.file, updateProgress, token);
    setUploading(false);
  };

  const removeFileToUpload = async (fileName) => {
    props.removeFileToUpload(fileName);
  };

  return (
    <FileToUploadRow
      file={props.file}
      handleUploadFile={handleUploadFile}
      removeFileToUpload={removeFileToUpload}
      uploading={uploading}
      progress={progress}
    />
  );
};

FileToUploadRowContainer.propTypes = {
  file: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    fileBody: PropTypes.object.isRequired,
    presignedS3URL: PropTypes.string.isRequired,
  }).isRequired,
  uploadFile: PropTypes.func.isRequired,
  removeFileToUpload: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (file, updateProgress, token) => dispatch(uploadActions.uploadFile(file, updateProgress, token)),
  removeFileToUpload: (fileName) => dispatch(uploadActions.removeFileToUpload(fileName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileToUploadRowContainer);
