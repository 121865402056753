/* eslint-disable no-unused-vars */
import axios from 'axios';
import { APIbaseURL } from 'utils/awsConfig';


/* ===================
=====   FILES   ======
=================== */

const getPresignedURL = async (fileName, token) => {
  const URL = `${APIbaseURL}/getPresignedS3URL?fileName=${encodeURIComponent(fileName)}`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const raw_response = await fetch(URL, options);
  const res = await raw_response.json();
  return res;
};

const uploadFile = async (file, presignedS3URL, updateProgress) => {
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/octet-stream',
    },
    onUploadProgress: updateProgress,
  };

  const raw_response = await axios.put(presignedS3URL, file, requestConfig);
  return raw_response.data;
};

const removeFile = async (fileName, token) => {
  const URL = `${APIbaseURL}/file`;
  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: { name: fileName },
  };
  const raw_response = await axios.delete(URL, requestConfig);
  return raw_response.data;
};

const getFileRegister = async (token) => {
  const URL = `${APIbaseURL}/fileregister`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const raw_response = await fetch(URL, options);
  const res = await raw_response.json();
  return res;
};

const updateFileRegister = async (file, token) => {
  const URL = `${APIbaseURL}/fileregister`;
  const options = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(file),
  };
  const raw_response = await fetch(URL, options);
  const res = await raw_response.json();
  return res;
};

// const removeFromFileRegister = async (fileName) => {
//   const URL = `${APIbaseURL}/fileregister`;
//   const options = {
//     method: 'DELETE',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       // "Authorization": accessToken
//     },
//     body: JSON.stringify({ fileName }),
//   };
//   const raw_response = await fetch(URL, options);
//   const res = await raw_response.json();
//   return res;
// };


/* ======================
=====   DATASETS   ======
====================== */

const getDatasetsRegister = async (token) => {
  const URL = `${APIbaseURL}/datasetsregister`;
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const raw_response = await axios.get(URL, requestConfig);
  return raw_response.data;
};

const removeDatasetFromRegister = async (datasetName, token) => ({});

const updateDatasetInputFileList = async (datasetName, inputFiles, token) => {
};

const updateFileParameter = async (fileName, parameterName, parameterValue, token) => {
};

const bulkUpdateFileParameter = async (fileNames, parameterName, parameterValue, token) => {
};

const removeDataset = async (datasetName, token) => {
};

const addDataset = async (datasetName, token) => {
};

const saveDatasetConfig = async (dataset, token) => {
  const URL = `${APIbaseURL}/datasetsregister`;
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const raw_response = await axios.post(URL, dataset, requestConfig);
  return raw_response.data;
};

const loadDatasetConfig = async (datasetName, token) => {
  const URL = `${APIbaseURL}/datasetsregister`;
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: { datasetName },
  };
  const raw_response = await axios.get(URL, requestConfig);
  return raw_response.data;
};


/* ==================
=====   RUNS   ======
================== */

const createRun = async (dataset, token) => {
  const URL = `${APIbaseURL}/run`;
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const raw_response = await axios.post(URL, dataset, requestConfig);
  return raw_response.data;
};

export default {
  getPresignedURL,
  uploadFile,
  getFileRegister,
  updateFileRegister,
  removeFile,
  // removeFromFileRegister,
  getDatasetsRegister,
  removeDatasetFromRegister,
  updateDatasetInputFileList,
  updateFileParameter,
  bulkUpdateFileParameter,
  removeDataset,
  addDataset,
  saveDatasetConfig,
  loadDatasetConfig,
  createRun,
};
