import { Toaster, Position, Intent } from '@blueprintjs/core';
import uploadActionTypes from './uploadActionTypes';
import dataAPI from '../APIs/dataAPI';

const updateFileList = (token) => async (dispatch) => {
  const rawResponse = await dataAPI.getFileRegister(token);
  let { fileList } = rawResponse.res;
  // sort by date
  fileList = fileList.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
  dispatch({
    type: uploadActionTypes.UPDATE_FILE_LIST,
    fileList,
  });
};

const updateFileRegister = (fileEntry, token) => async (dispatch) => {
  try {
    await dataAPI.updateFileRegister({ file: fileEntry }, token);
  } catch (err) {
    Toaster.create({ className: 'uploadFileRegisterToaster', position: Position.TOP }).show({
      message: `Error uploading file register: ${err}`,
      intent: Intent.DANGER,
      icon: 'warning-sign',
    });
  }
  // update the list
  dispatch(updateFileList(token));
};

const addToFileRegister = (file, token) => async (dispatch) => {
  // add uploadDate to file object
  const newFile = {
    name: file.name,
    size: file.size,
    type: file.type,
    role: 'Volumes', // default file role
    selected: true,
    lastModifiedDate: file.lastModifiedDate,
    uploadDate: new Date(),
  };
  dispatch(updateFileRegister(newFile, token));
};

const getPresignedS3URL = async (fileObject, token, dispatch) => {
  const presignedS3URL = await dataAPI.getPresignedURL(fileObject.fileBody.name, token);
  dispatch({
    type: uploadActionTypes.ADD_FILES_TO_UPLOAD,
    files: [{ ...fileObject, presignedS3URL }],
  });
};

const removeFileToUpload = (fileName) => ({
  type: uploadActionTypes.REMOVE_FILES_TO_UPLOAD,
  fileName,
});

const uploadFile = (file, updateProgress, token) => async (dispatch) => {
  const res = await dataAPI.uploadFile(file.fileBody, file.presignedS3URL, updateProgress);
  if (res.error) {
    Toaster.create({ className: 'uploadFileToaster', position: Position.TOP }).show({
      message: `Error uploading file: ${res.error}`,
      intent: Intent.DANGER,
      icon: 'warning-sign',
    });
  } else {
    // Add file to the register
    dispatch(addToFileRegister(file.fileBody, token));
    await setTimeout(() => {
      dispatch(removeFileToUpload(file.fileBody.name));
    }, 500);
  }
};

const addFilesToUpload = (files, token) => {
  // Convert files to regular objects
  const fileObjects = files.map((file) => ({
    fileBody: file,
    presignedS3URL: '',
  }));

  return async (dispatch) => {
    // First, add the files so they appear in the list
    dispatch({
      type: uploadActionTypes.ADD_FILES_TO_UPLOAD,
      files: fileObjects,
    });

    // At the same time, get the pre-signed upload URLs for each file
    const presignedS3URLDispatches = [];
    for (const fileObject of fileObjects) {
      const presignedS3URLDisptachPromise = getPresignedS3URL(fileObject, token, dispatch);
      presignedS3URLDispatches.push(presignedS3URLDisptachPromise);
    }
    await Promise.all(presignedS3URLDispatches);
  };
};

const fileIsDeleting = (fileName) => ({
  type: uploadActionTypes.SET_FILE_IS_DELETING,
  fileName,
});

const removeFile = (fileName, token) => async (dispatch) => {
  dispatch(fileIsDeleting(fileName));
  await dataAPI.removeFile(fileName, token);
  // update the list
  dispatch(updateFileList(token));
};

const updateFileParameter = (fileName, parameterName, parameterValue, token) => async (dispatch) => {
  await dataAPI.updateFileParameter(fileName, parameterName, parameterValue, token);
  dispatch({
    type: uploadActionTypes.UPDATE_FILE_PARAMETER,
    fileName,
    parameterName,
    parameterValue,
  });
};

const bulkUpdateFileParameter = (fileNames, parameterName, parameterValue, token) => async (dispatch) => {
  await dataAPI.bulkUpdateFileParameter(fileNames, parameterName, parameterValue, token);
  dispatch({
    type: uploadActionTypes.BULK_UPDATE_FILE_PARAMETER,
    fileNames,
    parameterName,
    parameterValue,
  });
};


export default {
  addFilesToUpload,
  removeFileToUpload,
  updateFileList,
  removeFile,
  uploadFile,
  addToFileRegister,
  updateFileRegister,
  updateFileParameter,
  bulkUpdateFileParameter,
};
