import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './MyCircularProgressBar.css';

const MyCircularProgressBar = ({ progress, isRunning, size }) => (
  <div className="circularProgressBarContainer" style={{ width: `${size}px`, height: `${size}px` }}>
    <CircularProgressbar
      className="circularProgressBar"
      value={progress}
      text={(isRunning || progress === 100) ? `${progress}%` : ''}
      strokeWidth={10}
      styles={{
        path: {
          stroke: progress === 100 ? '#0F9960' : '#137CBD',
        },
        text: {
          fill: progress === 100 ? '#0F9960' : '#137CBD',
          fontSize: '24px',
        },
      }}
    />
  </div>
);

MyCircularProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  isRunning: PropTypes.bool.isRequired,
  size: PropTypes.number,
};

MyCircularProgressBar.defaultProps = {
  size: 20,
};

export default MyCircularProgressBar;
