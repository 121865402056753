import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Intent, ButtonGroup } from '@blueprintjs/core';
import * as titanPropTypes from 'AppModules/Titan/Definitions/propTypes';
import TitanSuggestBox from 'AppModules/Titan/Components/TitanSuggestBox/TitanSuggestBox';
import AttributeListParameter from './AttributeParameters/AttributeListParameter';
import AttributeIconsParameter from './AttributeParameters/AttributeIconsParameter';
import AttributeParameters from './AttributeParameters/AttributeParameters';
import './Attribute.css';

const Attribute = (props) => (
  <div className="Attribute">
    <div className="attributeSelection">
      <Icon icon={props.parameterGroup.icon} iconSize={20} />
      <TitanSuggestBox
        selectedItem={props.attributeDefinition && props.attributeDefinition.displayName}
        items={props.attributesSelectionList.map((attr) => attr.displayName)}
        handleChange={props.handleChangeAttribute}
      />
      <Button
        icon="trash"
        intent={Intent.DANGER}
        minimal
        onClick={props.handleRemoveAttribute}
        disabled={(props.parameterGroup.minAllowed && props.nbSelectedAttributes <= props.parameterGroup.minAllowed)
          || (props.parameterGroup.maxAllowed && props.nbSelectedAttributes >= props.parameterGroup.maxAllowed)}
      />
    </div>
    {props.attributeDefinition
      && (
        <div className="attributeParameters">
          <ButtonGroup minimal>
            {props.attributeParametersDefinitions.filter((param) => param.type === 'list').map((parameter) => (
              <AttributeListParameter
                key={parameter.name}
                dashboardID={props.dashboardID}
                chartGroupID={props.chartGroupID}
                attributeID={props.attributeID}
                parameterGroup={props.parameterGroup}
                attribute={props.selectedAttribute}
                parameterName={parameter.name}
                parameterDefinition={parameter}
              />
            ))}
            {props.attributeParametersDefinitions.filter((param) => param.type === 'icons').map((parameter) => (
              <AttributeIconsParameter
                key={parameter.name}
                dashboardID={props.dashboardID}
                chartGroupID={props.chartGroupID}
                attributeID={props.attributeID}
                parameterGroup={props.parameterGroup}
                attribute={props.selectedAttribute}
                parameterName={parameter.name}
                parameterDefinition={parameter}
              />
            ))}
            {props.attributeParametersDefinitions.filter((param) => !['list', 'icons'].includes(param.type)).length > 0
              && props.selectedAttribute.name !== 'ga:date'
              && (
                <AttributeParameters
                  dashboardID={props.dashboardID}
                  chartGroupID={props.chartGroupID}
                  attributeID={props.attributeID}
                  parameterGroup={props.parameterGroup}
                  attribute={props.selectedAttribute}
                  parameterDefinitions={props.attributeParametersDefinitions.filter((param) => param.type !== 'list')}
                />
              )}
          </ButtonGroup>
        </div>
      )}
  </div>
);

Attribute.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  attributeID: PropTypes.number.isRequired,
  parameterGroup: titanPropTypes.parameterGroupPropTypes.isRequired,
  selectedAttribute: titanPropTypes.attributePropTypes.isRequired,
  nbSelectedAttributes: PropTypes.number.isRequired,
  attributeDefinition: PropTypes.oneOfType([titanPropTypes.attributeDefinitionPropTypes, titanPropTypes.comparisonDefinitionPropTypes]),
  attributesSelectionList: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, displayName: PropTypes.string })).isRequired,
  handleRemoveAttribute: PropTypes.func.isRequired,
  handleChangeAttribute: PropTypes.func.isRequired,
  attributeParametersDefinitions: PropTypes.arrayOf(titanPropTypes.attributeParameterPropTypes).isRequired,
};

Attribute.defaultProps = {
  attributeDefinition: undefined,
};

export default Attribute;
