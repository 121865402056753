/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as titanPropTypes from 'AppModules/Titan/Definitions/propTypes';
import chartGroupsActions from 'AppModules/Titan/Actions/chartGroupsActions';
import attributeDefinitions from 'AppModules/Titan/Definitions/attributeDefinitions';
import { comparisonDefinitions } from 'AppModules/Titan/Definitions/definitions';
import Attribute from './Attribute';

const AttributeContainer = (props) => {
  const attributeDefinition = ['metrics', 'dimensions'].includes(props.parameterGroup.type)
    ? attributeDefinitions.find((attr) => attr.name === props.selectedAttribute.name)
    : comparisonDefinitions.find((attr) => attr.name === props.selectedAttribute.name);

  const [attributesSelectionList, setAttributesSelectionList] = React.useState([]);

  React.useEffect(() => {
    const allAttributes = ['metrics', 'dimensions'].includes(props.parameterGroup.type)
      ? attributeDefinitions.filter((attr) => props.chartType.parameterGroups[props.parameterGroupID].attributeTypes.includes(attr.type))
      : comparisonDefinitions;

    const unselectedAttributes = [];
    for (const attr of allAttributes) {
      if ((props.selectedAttribute && props.selectedAttribute.name === attr.name) || (!props.selectedAttributes.map((el) => el.name).includes(attr.name))) {
        unselectedAttributes.push({ name: attr.name, displayName: attr.displayName });
      }
    }
    setAttributesSelectionList(unselectedAttributes);
  }, [props.chartType, props.parameterGroupID, props.parameterGroup.type, props.selectedAttributes, props.selectedAttribute]);

  const handleChangeAttribute = (newAttributeDisplayName) => {
    // find attribute name corresponding to this displayName
    const newAttributeName = attributesSelectionList.find((attr) => attr.displayName === newAttributeDisplayName).name;
    props.changeAttribute(props.dashboardID, props.chartGroupID, props.parameterGroup.type, props.attributeID, newAttributeName);
  };

  const handleRemoveAttribute = () => {
    props.removeAttribute(props.dashboardID, props.chartGroupID, props.parameterGroup.type, props.attributeID);
  };

  const handleChangeParameter = () => {

  };

  return (
    <Attribute
      dashboardID={props.dashboardID}
      chartGroupID={props.chartGroupID}
      attributeID={props.attributeID}
      parameterGroup={props.parameterGroup}
      selectedAttribute={props.selectedAttribute}
      nbSelectedAttributes={props.selectedAttributes.length}
      attributeDefinition={attributeDefinition}
      attributesSelectionList={attributesSelectionList}
      handleRemoveAttribute={handleRemoveAttribute}
      handleChangeAttribute={handleChangeAttribute}
      attributeParametersDefinitions={props.chartType.parameterGroups[props.parameterGroupID].parameters}
      handleChangeParameter={handleChangeParameter}
    />
  );
};

AttributeContainer.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  chartGroupID: PropTypes.number.isRequired,
  parameterGroupID: PropTypes.number.isRequired,
  attributeID: PropTypes.number.isRequired,
  chartType: titanPropTypes.chartTypePropTypes.isRequired,
  parameterGroup: titanPropTypes.parameterGroupPropTypes.isRequired,
  selectedAttributes: PropTypes.arrayOf(titanPropTypes.attributePropTypes).isRequired,
  selectedAttribute: titanPropTypes.attributePropTypes.isRequired,
  changeAttribute: PropTypes.func.isRequired,
  removeAttribute: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  changeAttribute: (dashboardID, chartGroupID, attributeType, attributeID, newAttributeName) => {
    dispatch(chartGroupsActions.changeAttribute(dashboardID, chartGroupID, attributeType, attributeID, newAttributeName));
  },
  removeAttribute: (dashboardID, chartGroupID, attributeType, attributeID) => {
    dispatch(chartGroupsActions.removeAttribute(dashboardID, chartGroupID, attributeType, attributeID));
  },
});

export default connect(null, mapDispatchToProps)(AttributeContainer);
