import React from 'react';
import PropTypes from 'prop-types';
import MyBreadCrumbs from 'Components/MyBreadCrumbs/MyBreadCrumbs';
import DashboardHeaderContainer from './DashboardHeader/DashboardHeaderContainer';
// import GlobalFiltersContainer from './Filters/GlobalFiltersContainer';
import ChartGroupsContainer from './ChartGroups/ChartGroupsContainer';
import './Dashboard.css';

const Dashboard = (props) => (
  <div className="dashboard">
    <MyBreadCrumbs
      items={[
        { href: '/', text: 'Home' },
        { href: '/Titan', text: 'Titan' },
        { href: '/Titan/Dashboards', text: 'Dashboards' },
        { href: `/Titan/Dashobard/${props.dashboardName}`, text: props.dashboardName },
      ]}
    />
    <div className="PageContainer">
      <DashboardHeaderContainer dashboardID={props.dashboardID} />
      {/* <GlobalFiltersContainer dashboardID={props.dashboardID} chartGroupID={-1} /> */}
      <ChartGroupsContainer dashboardID={props.dashboardID} />
    </div>
  </div>
);

Dashboard.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  dashboardName: PropTypes.string.isRequired,
};

export default Dashboard;
