import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@blueprintjs/core';
import './EditableContent.css';

const EditableContent = (props) => {
  const [enteredValue, setEnteredValue] = React.useState(props.text);
  const [isEditing, setIsEditing] = React.useState(false);
  const inputRef = useRef(null);

  const handleEditButtonClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleChangeEneteredValue = (e) => {
    setEnteredValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setIsEditing(false);
      props.onChange(enteredValue);
    }
    if (e.keyCode === 27) {
      setIsEditing(false);
      setEnteredValue(props.text);
    }
  };

  let className;
  if (isEditing) {
    className = 'hidden';
  } else if (!props.text) {
    className = !props.text;
  } else {
    className = 'placeholder';
  }

  return (
    <div className="editableContent">
      <input
        className={isEditing ? '' : 'hidden'}
        ref={inputRef}
        type="text"
        value={enteredValue}
        onChange={handleChangeEneteredValue}
        onKeyDown={handleKeyDown}
      />
      <span className={className}>{props.text || props.placeholder}</span>
      <Button className="editButton" minimal icon="edit" onClick={handleEditButtonClick} />
    </div>
  );
};

EditableContent.propTypes = {
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default EditableContent;
