/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
const addDashboard = async (token) =>
  // to be implemented once AWS backend is created
  null;
const removeDashboard = async (token, dashboardName) =>
  // to be implemented once AWS backend is created
  null;
const renameDashboard = async (token, oldDashboardName, newDashboardName) =>
  // to be implemented once AWS backend is created
  null;
const changeDashboardDescription = async (token, dashboardName, newDescription) =>
  // to be implemented once AWS backend is created
  null;
export default {
  addDashboard,
  removeDashboard,
  renameDashboard,
  changeDashboardDescription,
};
