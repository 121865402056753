import { setISOWeek, startOfWeek, differenceInDays, addDays, format, parse, subDays } from 'date-fns';

const finalYear = (new Date()).getFullYear();
const smoothingWindow = 17;
const VAT = 1.21;

const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const randomFloatFromInterval = (min, max) => Math.random() * (max - min + 1) + min;

const findSundayWeek1 = (year) => {
  const date1 = new Date(year, 1, 1); // 1st Feb
  const date2 = setISOWeek(date1, 2);
  const date3 = startOfWeek(date2);
  return date3; // US style beginning of week 2 (Sunday) is intl end of week 1 (Sunday)
};

const buildDataTables = (categories) => {
  const nbProducts = categories.Products.numberOfItems;
  const nbRetailers = categories.Retailers.numberOfItems;
  const nbYears = categories.Years.numberOfItems;
  const tactics = categories.Tactics.items;
  const displays = categories.Displays.items;

  const tableData = [['Product', 'Retailer', 'Week', 'Volume', 'Volume Seasonality']];

  // MAIN LOOP
  for (let i = 1; i <= nbProducts; i += 1) {
    const nbDigits_products = Math.floor(Math.log10(nbProducts)) + 1;
    const product = `product${(`0000000${i}`).slice(-1 * nbDigits_products)}`;

    const productParameters = Object.keys(categories.Products.parameters).map((parameterName) => {
      const values = categories.Products.parameters[parameterName].value;
      return randomFloatFromInterval(values[0], values[1]);
    });

    const [productBaseVolume, productGrowthPct, productSeasonality] = productParameters;
    const productGrowth = productGrowthPct / 100;

    // console.log(`productSeasonality: ${productSeasonality}`);

    for (let j = 1; j <= nbRetailers; j += 1) {
      const nbDigits_retailers = Math.floor(Math.log10(nbRetailers)) + 1;
      const retailer = `retailer${(`0000000${j}`).slice(-1 * nbDigits_retailers)}`;

      const retailerParameters = Object.keys(categories.Retailers.parameters).map((parameterName) => {
        const values = categories.Retailers.parameters[parameterName].value;
        return randomIntFromInterval(values[0], values[1]);
      });

      const retailerBaseVolume = retailerParameters[0];
      const retailerGrowthPct = retailerParameters[1];
      const retailerGrowth = retailerGrowthPct / 100;

      let previousWeekVolume = null;

      for (let k = 1; k <= nbYears; k += 1) {
        const year = finalYear - nbYears + k;
        let weekEndingDate = findSundayWeek1(year);
        const nbWeeksInYear = year === 2011 || year === 2015 || year === 2022 ? 53 : 52;
        // Retailer Good Year Index
        const yearGoodYearIndexValues = categories.Years.parameters['Good year index'].value;
        const yearGoodYearIndexPct = randomIntFromInterval(yearGoodYearIndexValues[0], yearGoodYearIndexValues[1]);
        const yearGoodYearIndex = yearGoodYearIndexPct / 100;
        // console.log(`yearGoodYearIndex: ${yearGoodYearIndex}`);

        for (let weekNumber = 1; weekNumber <= nbWeeksInYear; weekNumber += 1) {
          let volume;
          // 1. Volume = baseVolume * retailerSize
          if (!previousWeekVolume) {
            volume = productBaseVolume * retailerBaseVolume;
          } else {
            volume = previousWeekVolume
              * (1 + productGrowth / nbWeeksInYear)
              * (1 + retailerGrowth / nbWeeksInYear)
              * (1 + yearGoodYearIndex / nbWeeksInYear);
          }

          // 2. Seasonality
          const jan01Before = new Date(year, 0, 1);
          const jan01After = new Date(year + 1, 0, 1);
          const distToJan01Before = differenceInDays(weekEndingDate, jan01Before) + 365;
          const distToJan01After = differenceInDays(jan01After, weekEndingDate);
          const distToJan01 = Math.min(distToJan01Before, distToJan01After);
          const volume_seasonality = volume * (1 + ((productSeasonality - 1) * distToJan01) / (365 / 2));

          // return;

          previousWeekVolume = volume;
          tableData.push([product, retailer, format(weekEndingDate, 'dd/MM/yyyy'), Math.round(volume), Math.round(volume_seasonality)]);

          weekEndingDate = addDays(weekEndingDate, 7);
        }
      }
    }
  }

  // 2ND PASS: Smoothing + events + random factor
  const volumesTable = [['Product', 'Retailer', 'Week', 'Volume', 'Sales']];
  const eventsTable = [['Product', 'Retailer', 'Start Date', 'End Date', 'Tactic', 'Display', 'Cost']];
  const financialsTable = [['Product', 'Retailer', 'Week', 'Price To Retailer', 'COGS']];
  const completeTable = [['Product', 'Retailer', 'Week', 'Volume', 'Sales', 'Start Date', 'End Date', 'Tactic', 'Display', 'Cost']];
  let currentProductRetailer = '';
  let currentProductRetailerVolumes = [];
  for (let i = 1; i <= nbProducts; i += 1) {
    const nbDigits_products = Math.floor(Math.log10(nbProducts)) + 1;
    const product = `product${(`0000000${i}`).slice(-1 * nbDigits_products)}`;

    // Product PTC
    const [minPTC, maxPTC] = categories.Products.parameters.Price.value;
    const productPTC = randomIntFromInterval(minPTC, maxPTC) - (Math.random < 0.5 ? 0.05 : 0.55);

    // Product Random Factor
    const [minRandomFactor, maxRandomFactor] = categories.Products.parameters['Random factor'].value;
    const productRandomFactor = randomFloatFromInterval(minRandomFactor, maxRandomFactor);

    // Product COGS
    const [minGM, maxGM] = categories.Products.parameters['Gross Margin (%)'].value;
    const productGrossMargin = randomFloatFromInterval(minGM, maxGM) / 100;
    const productCOGS = Math.round((productPTC / VAT) * (1 - productGrossMargin) * 100) / 100;

    for (let j = 1; j <= nbRetailers; j += 1) {
      const nbDigits_retailers = Math.floor(Math.log10(nbRetailers)) + 1;
      const retailer = `retailer${(`0000000${j}`).slice(-1 * nbDigits_retailers)}`;

      // Retailer share of Promo Investment
      const [minShare, maxShare] = categories.Retailers.parameters['Investment (%)'].value;
      const retailerPromoInvestmentShare = randomIntFromInterval(minShare, maxShare) / 100;

      // Price to Retailer
      const [minRM, maxRM] = categories.Retailers.parameters['Retailer Margin (%)'].value;
      const retailerGrossMargin = randomFloatFromInterval(minRM, maxRM) / 100;
      const productPTR = Math.round((productPTC / VAT) * (1 - retailerGrossMargin) * 100) / 100;

      currentProductRetailer = tableData.filter((el) => el[0] === product && el[1] === retailer);
      currentProductRetailerVolumes = currentProductRetailer.map((el) => el[4]);

      // Events
      let event = false;
      let eventPreviousWeek = false;
      let startDate = 0;
      let endDate = 0;
      let endDateLastEvent = parse(currentProductRetailer[0][2], 'dd/MM/yyyy', new Date());
      let eventDuration = 0;
      let eventVolume = 0;
      let tactic = '';
      let tacticDiscount = 0;
      let tacticUplift = 0;
      let display = '';
      let displayUplift = 0;
      let displayCost = 0;

      let currentRowNb = 0;
      for (const newRow of currentProductRetailer) {
        currentRowNb += 1;
        const currentDate = parse(newRow[2], 'dd/MM/yyyy', new Date());

        // Volume Smoothing
        const nbWeeksInWindow_before = Math.min(smoothingWindow, currentRowNb - 1);
        const nbWeeksInWindow_after = Math.min(smoothingWindow, currentProductRetailer.length - currentRowNb);
        const nbWeeksInWindow = 1 + nbWeeksInWindow_before + nbWeeksInWindow_after;

        const refVolumesArray = currentProductRetailerVolumes.slice(
          currentRowNb - 1 - nbWeeksInWindow_before,
          currentRowNb + nbWeeksInWindow_after,
        );
        let volumeSmoothed = refVolumesArray.reduce((a, b) => a + b, 0);
        volumeSmoothed /= nbWeeksInWindow;

        // Events
        if (!eventPreviousWeek) {
          // There shouldn't be an event too close to the previous one
          const weeksSinceLastEvent = differenceInDays(currentDate, endDateLastEvent) / 7;

          let probability1 = 0;
          if (weeksSinceLastEvent > 4) probability1 = (0.1 * weeksSinceLastEvent) / 20;

          if (Math.random() < probability1) {
            event = true;

            // calculate start date
            const daysBeforeEndOfWeek = Math.floor(1 + Math.random() * 5.99);
            startDate = subDays(currentDate, daysBeforeEndOfWeek);
            endDate = addDays(startDate, 3);
            eventDuration = differenceInDays(endDate, startDate);
            endDateLastEvent = endDate;

            // Assign Tactic
            const tacticNumber = Math.floor(Math.random() * tactics.length);
            tactic = tactics[tacticNumber].itemName;
            tacticDiscount = tactics[tacticNumber].parameterValues.Discount / 100;
            if (typeof tacticDiscount === 'string') tacticDiscount = parseInt(tacticDiscount, 10);
            tacticUplift = tactics[tacticNumber].parameterValues.Uplift / 100;
            if (typeof tacticUplift === 'string') tacticUplift = parseInt(tacticUplift, 10);

            // Assign Display
            const displayNumber = Math.floor(Math.random() * displays.length);
            display = displays[displayNumber].itemName;
            displayCost = displays[displayNumber].parameterValues.Cost;
            if (typeof displayCost === 'string') displayCost = parseInt(displayCost, 10);
            displayUplift = displays[displayNumber].parameterValues.Uplift / 100;
            if (typeof displayUplift === 'string') displayUplift = parseInt(displayUplift, 10);

            eventPreviousWeek = true;
          }
        } else {
          // If there was an event the previous week
          // Define probability that event continues this week: 1-week events should be rare
          let probability2 = 1;
          if (eventDuration < 7) {
            probability2 = 0.97; // 3% chance of 1-week event
          } else {
            probability2 = 1 / ((2 * eventDuration) / 7);
          }

          if (Math.random() < probability2) {
            // Event continues this week
            event = true;
            // update end date
            const daysBeforeEndOfWeek = Math.floor(1 + Math.random() * 5.99);
            endDate = subDays(currentDate, daysBeforeEndOfWeek);
            eventDuration = differenceInDays(endDate, startDate);
            endDateLastEvent = endDate;
          } else {
            // Event ended previous week
            // Write complete event to Event Calendar
            // Event Calendar structure: ['Product', 'Retailer', 'Start Date', 'End Date', 'Tactic', 'Display', 'Investment']
            const eventCost = displayCost + eventVolume * tacticDiscount * (productPTC / 1.21) * (1 - retailerPromoInvestmentShare);
            const thisEvent = [product, retailer, format(startDate, 'dd/MM/yyyy'), format(endDate, 'dd/MM/yyyy'), tactic, display, eventCost];
            eventsTable.push(thisEvent);
            // reset event, tactic and display
            event = false;
            eventPreviousWeek = false;
            eventVolume = 0;
            tactic = '';
            tacticDiscount = 0;
            tacticUplift = 0;
            display = '';
            displayCost = 0;
            displayUplift = 0;
            startDate = 0;
            endDate = 0;
          }
        }

        // Random Factor
        const randomFactor = (productRandomFactor * Math.random()) / 10;

        const newVolume = Math.round(volumeSmoothed * (1 + tacticUplift) * (1 + displayUplift) * (1 + randomFactor));
        const sales = Math.round(100 * newVolume * productPTC) / 100;

        // update eventVolume
        if (event) eventVolume += newVolume;

        volumesTable.push([product, retailer, format(currentDate, 'dd/MM/yyyy'), newVolume, sales]);

        financialsTable.push([product, retailer, format(currentDate, 'dd/MM/yyyy'), productPTR, productCOGS]);

        completeTable.push([
          product,
          retailer,
          format(currentDate, 'dd/MM/yyyy'),
          newVolume,
          sales,
          event,
          startDate ? format(startDate, 'dd/MM/yyyy') : null,
          endDate ? format(endDate, 'dd/MM/yyyy') : null,
          tactic,
          display,
          productPTR,
          productCOGS,
        ]);
      }
    }
  }

  // console.table(completeTable);
  // console.table(volumesTable);
  // console.table(eventsTable);
  // console.table(financialsTable);
  return { volumesTable, eventsTable, financialsTable };
};

export default buildDataTables;
