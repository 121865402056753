import React from 'react';
import PropTypes from 'prop-types';
import { Suggest } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';

const TitanSuggestBox = (props) => {
  const onItemSelect = (item) => {
    props.handleChange(item);
  };

  const elementRef = React.useRef(null);
  React.useEffect(() => {
    elementRef.current.focus();
    return () => { };
  }, [elementRef]);

  // eslint-disable-next-line no-unused-vars
  const itemRenderer = (item, { index, handleClick, query }) => <MenuItem key={item} text={item} onClick={handleClick} active={item === props.selectedItem} />;

  return (
    <Suggest
      inputValueRenderer={(item) => item}
      items={props.items}
      itemRenderer={itemRenderer}
      onItemSelect={onItemSelect}
      selectedItem={props.selectedItem}
      fill
      inputProps={{ inputRef: elementRef }}
    />
  );
};

TitanSuggestBox.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItem: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};
TitanSuggestBox.defaultProps = {
  selectedItem: undefined,
};

export default TitanSuggestBox;
