/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';
import MyBreadCrumbs from '../../../Components/MyBreadCrumbs/MyBreadCrumbs';
import ExplorerFiltersContainer from './ExplorerFilters/ExplorerFiltersContainer';
import ExplorerScreenshotsListContainer from './ExplorerScreenshotsList/ExplorerScreenshotsListContainer';
import { screenshotPropTypes } from '../Definitions/ganymedePropTypes';

const Explorer = (props) => (
  <div className="Explorer">
    <MyBreadCrumbs
      items={[
        { href: '/', text: 'Home' },
        { href: '/Ganymede', text: 'Ganymede' },
        { href: '/Ganymede/Explorer', text: 'Explorer' },
      ]}
    />
    <div className="PageContainer">
      <h2 style={{ float: 'left' }}>
        Ganyemede - Explorer
        {/* {props.isUpdating} */}
      </h2>
      {props.isUpdating && (
        <div style={{ float: 'left', margin: '5px 5px 5px 15px' }}>
          <Spinner size={Spinner.SIZE_SMALL} />
        </div>
      )}
      <ExplorerFiltersContainer />
      <ExplorerScreenshotsListContainer
        isUpdating={props.isUpdating}
        screenshots={props.screenshots}
      />
    </div>
  </div>
);

Explorer.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
  screenshots: PropTypes.arrayOf(screenshotPropTypes).isRequired,
};

export default Explorer;
