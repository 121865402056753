/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Toaster, Intent } from '@blueprintjs/core';
import ListCategoryDefinition from './ListCategoryDefinition';
import dataGeneratorActions from '../../Actions/dataGeneratorActions';

const errorToaster = Toaster.create();

const ListCategoryDefinitionContainer = (props) => {
  const [newItemName, setNewItemName] = React.useState('');

  const handleChangeNewItemName = (e) => {
    setNewItemName(e.target.value);
  };

  const handleChangeItemName = (itemName, newName) => {
    props.changeItemName({
      categoryName: props.categoryName,
      oldItemName: itemName,
      newItemName: newName,
    });
  };

  const handleAddItem = () => {
    const itemName = newItemName;
    // Skip if item with that name already present
    const alreadyExists = props.items ? props.items.filter((item) => item.itemName === itemName).length : false;
    if (itemName === '') {
      errorToaster.show({ message: 'Error: the new item\'s name cannot be empty', icon: 'warning-sign', intent: Intent.DANGER });
    } else if (alreadyExists) {
      errorToaster.show({ message: `Error: the item "${itemName}" already exists`, icon: 'warning-sign', intent: Intent.DANGER });
    } else {
      props.addItem({
        categoryName: props.categoryName,
        itemName,
      });
      setNewItemName('');
    }
  };

  const handleEditItem = (itemName, parameterName, newValue) => {
    props.editItem({
      categoryName: props.categoryName,
      itemName,
      parameterName,
      newValue,
    });
  };

  const handleRemoveItem = (itemName) => {
    props.removeItem({
      categoryName: props.categoryName,
      itemName,
    });
  };

  return (
    <ListCategoryDefinition
      items={props.items}
      newItemName={newItemName}
      category={props.category}
      categoryName={props.categoryName}
      handleChangeNewItemName={handleChangeNewItemName}
      handleChangeItemName={handleChangeItemName}
      handleAddItem={handleAddItem}
      handleEditItem={handleEditItem}
      handleRemoveItem={handleRemoveItem}
    />
  );
};

const maptStateToProps = (store, ownProps) => ({
  items: store.dataGeneratorReducer.dataGeneratorParameters.categories[ownProps.categoryName].items,
});

const mapDispatchToProps = (dispatch) => ({
  addItem: (options) => dispatch(dataGeneratorActions.addCategoryItem(options)),
  changeItemName: (options) => dispatch(dataGeneratorActions.changeCategoryItemName(options)),
  editItem: (options) => dispatch(dataGeneratorActions.editCategoryItem(options)),
  removeItem: (options) => dispatch(dataGeneratorActions.removeCategoryItem(options)),
});

export default connect(maptStateToProps, mapDispatchToProps)(ListCategoryDefinitionContainer);
