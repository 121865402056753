/* eslint-disable react/prop-types */
import React from 'react';
import { Button, InputGroup } from '@blueprintjs/core';

const ItemDefinition = (props) => {
  const { itemName, parameterValues } = props;
  return (
    <tr>
      <td key="name">
        {itemName}
      </td>
      {Object.keys(parameterValues).map((parameterName) => (
        <td key={parameterName}>
          <InputGroup
            className="parameterValueInput"
            value={parameterValues[parameterName]}
            onChange={(e) => {
              props.handleEditItem(itemName, parameterName, e.target.value);
            }}
          />
        </td>
      ))}
      <td>
        <Button icon="trash" onClick={() => props.handleRemoveItem(props.itemName)} />
      </td>
    </tr>
  );
};
export default ItemDefinition;
