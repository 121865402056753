import dataGeneratorActionTypes from '../Actions/dataGeneratorActionTypes';

const localDataGeneratorParameters = JSON.parse(localStorage.getItem('dataGeneratorParameters'));
// const localDataGeneratorParameters = null;

const initialDataGeneratorParameters = {
  dataGeneratorParameters: {
    categories: {
      Products: {
        type: 'bulk',
        numberOfItems: 20,
        description: 'Defines the products: volume magnitude, growth, seasonality, price amd COGS level',
        parameters: {
          'Volume index': {
            description: 'Defines the overall volume level for each product',
            type: 'range',
            range: {
              from: 0,
              to: 1000,
              stepSize: 10,
              labelStepSize: 100,
            },
            value: [30, 300],
          },
          'Growth (%)': {
            description: 'Defines the yearly growth for each product',
            type: 'range',
            range: {
              from: -100,
              to: 100,
              stepSize: 1,
              labelStepSize: 20,
            },
            value: [-10, 30],
          },
          Seasonality: {
            description:
              'Defines the seasonality for each product: 1 means no seasonality, while 2 means the volume in summer will be twice the volume in winter',
            type: 'range',
            range: {
              from: 0,
              to: 5,
              stepSize: 0.1,
              labelStepSize: 0.5,
            },
            value: [0.5, 2],
          },
          'Random factor': {
            description:
              'Defines the random factor for each product: 0 means perfectly flat volumes week on week, while 1 means quite noisy volumes',
            type: 'range',
            range: {
              from: 0,
              to: 5,
              stepSize: 0.1,
              labelStepSize: 0.5,
            },
            value: [0.1, 2],
          },
          Price: {
            description: 'Defines the levels of consumer price for each product',
            type: 'range',
            range: {
              from: 0,
              to: 30,
              stepSize: 1,
              labelStepSize: 5,
            },
            value: [2, 12],
          },
          'Gross Margin (%)': {
            description: 'Defines the levels of gross margin for each product (ratio of COGS to consumer price)',
            type: 'range',
            range: {
              from: 0,
              to: 80,
              stepSize: 5,
              labelStepSize: 10,
            },
            value: [30, 70],
          },
        },
      },
      Retailers: {
        type: 'bulk',
        numberOfItems: 6,
        description: 'Defines the retailers: size, growth,...',
        parameters: {
          'Volume index': {
            description: 'Defines the overall volume level for each retailer',
            type: 'range',
            range: {
              from: 0,
              to: 100,
              stepSize: 10,
              labelStepSize: 10,
            },
            value: [10, 100],
          },
          'Growth (%)': {
            description: 'Defines the yearly growth for each retailer',
            type: 'range',
            range: {
              from: -100,
              to: 100,
              stepSize: 1,
              labelStepSize: 20,
            },
            value: [-5, 30],
          },
          'Investment (%)': {
            description: 'Defines the share of the promotion cost that the retailers typically invest',
            type: 'range',
            range: {
              from: 0,
              to: 100,
              stepSize: 1,
              labelStepSize: 20,
            },
            value: [5, 70],
          },
          'Retailer Margin (%)': {
            description: 'Defines the levels of margin for each retailer (ratio of retailer price to consumer price)',
            type: 'range',
            range: {
              from: 0,
              to: 80,
              stepSize: 1,
              labelStepSize: 10,
            },
            value: [2, 10],
          },
        },
      },
      Tactics: {
        type: '',
        description: 'Create tactics: discount and typical uplift observed',
        parameters: {
          Uplift: {
            type: 'number',
          },
          Discount: {
            type: 'number',
          },
        },
        items: [
          {
            itemName: '20% off',
            parameterValues: {
              Uplift: 15,
              Discount: 20,
            },
          },
        ],
      },
      Displays: {
        type: '',
        description: 'Create displays: typical uplift and associated cost',
        parameters: {
          Uplift: { type: 'number' },
          Cost: { type: 'number' },
        },
        items: [
          {
            itemName: 'Ailse Head 1',
            parameterValues: {
              Uplift: 20,
              Cost: 6000,
            },
          },
        ],
      },
      Years: {
        type: 'bulk',
        numberOfItems: 3,
        description: 'Defines the time period of the generated data, as well as whether each year was "a good year"',
        parameters: {
          'Good year index': {
            description: 'Defines the volumes growth throughout the year',
            type: 'range',
            range: {
              from: -50,
              to: 50,
              stepSize: 10,
              labelStepSize: 10,
            },
            value: [-10, 20],
          },
        },
      },
    },
  },
};

const initialState = localDataGeneratorParameters || initialDataGeneratorParameters;

const dataGeneratorReducer = (state = initialState, payload) => {
  let newState;
  let categoryName;
  let parameterName;
  let parameterRangeValue;
  let numberOfItems;
  let oldItemName;
  let newItemName;
  let itemName;
  let newItemsArray;
  const newParameterValues = {};
  let newValue;
  switch (payload.type) {
    case dataGeneratorActionTypes.UPDATE_PARAMETER:
      categoryName = payload.options.categoryName;
      parameterName = payload.options.parameterName;
      parameterRangeValue = payload.options.parameterRangeValue;

      newState = {
        ...state,
        dataGeneratorParameters: {
          ...state.dataGeneratorParameters,
          categories: {
            ...state.dataGeneratorParameters.categories,
            [categoryName]: {
              ...state.dataGeneratorParameters.categories[categoryName],
              parameters: {
                ...state.dataGeneratorParameters.categories[categoryName].parameters,
                [parameterName]: {
                  ...state.dataGeneratorParameters.categories[categoryName].parameters[parameterName],
                  value: parameterRangeValue,
                },
              },
            },
          },
        },
      };

      localStorage.setItem('dataGeneratorParameters', JSON.stringify(newState));
      return newState;

    case dataGeneratorActionTypes.UPDATE_NUMBER_OF_ITEMS:
      categoryName = payload.options.categoryName;
      numberOfItems = payload.options.numberOfItems;

      newState = {
        ...state,
        dataGeneratorParameters: {
          ...state.dataGeneratorParameters,
          categories: {
            ...state.dataGeneratorParameters.categories,
            [categoryName]: {
              ...state.dataGeneratorParameters.categories[categoryName],
              numberOfItems,
            },
          },
        },
      };

      localStorage.setItem('dataGeneratorParameters', JSON.stringify(newState));
      return newState;

    case dataGeneratorActionTypes.CHANGE_CATEGORY_ITEM_NAME:
      categoryName = payload.options.categoryName;
      oldItemName = payload.options.oldItemName;
      newItemName = payload.options.newItemName;

      newItemsArray = state.dataGeneratorParameters.categories[categoryName].items.map((item) => (
        item.itemName === oldItemName ? { ...item, itemName: newItemName } : item
      ));

      newState = {
        ...state,
        dataGeneratorParameters: {
          ...state.dataGeneratorParameters,
          categories: {
            ...state.dataGeneratorParameters.categories,
            [categoryName]: {
              ...state.dataGeneratorParameters.categories[categoryName],
              items: newItemsArray,
            },
          },
        },
      };

      localStorage.setItem('dataGeneratorParameters', JSON.stringify(newState));
      return newState;

    case dataGeneratorActionTypes.ADD_CATEGORY_ITEM:
      categoryName = payload.options.categoryName;
      itemName = payload.options.itemName;

      for (const key of Object.keys(state.dataGeneratorParameters.categories[categoryName].parameters)) {
        const parameterDefinition = state.dataGeneratorParameters.categories[categoryName].parameters[key];
        newParameterValues[key] = parameterDefinition.type === 'number' ? 0 : '';
      }

      newItemsArray = [
        ...state.dataGeneratorParameters.categories[categoryName].items,
        {
          itemName,
          parameterValues: newParameterValues,
        },
      ];

      newState = {
        ...state,
        dataGeneratorParameters: {
          ...state.dataGeneratorParameters,
          categories: {
            ...state.dataGeneratorParameters.categories,
            [categoryName]: {
              ...state.dataGeneratorParameters.categories[categoryName],
              items: newItemsArray,
            },
          },
        },
      };

      localStorage.setItem('dataGeneratorParameters', JSON.stringify(newState));
      return newState;

    case dataGeneratorActionTypes.EDIT_CATEGORY_ITEM:
      categoryName = payload.options.categoryName;
      parameterName = payload.options.parameterName;
      itemName = payload.options.itemName;
      newValue = payload.options.newValue;

      // console.log(state.dataGeneratorParameters.categories[categoryName].items);

      newItemsArray = state.dataGeneratorParameters.categories[categoryName].items.map((item) => (item.itemName === itemName
        ? {
          ...item,
          parameterValues: {
            ...item.parameterValues,
            [parameterName]: newValue,
          },
        }
        : item));

      // console.log(newItemsArray);

      newState = {
        ...state,
        dataGeneratorParameters: {
          ...state.dataGeneratorParameters,
          categories: {
            ...state.dataGeneratorParameters.categories,
            [categoryName]: {
              ...state.dataGeneratorParameters.categories[categoryName],
              items: newItemsArray,
            },
          },
        },
      };

      localStorage.setItem('dataGeneratorParameters', JSON.stringify(newState));
      return newState;

    case dataGeneratorActionTypes.REMOVE_CATEGORY_ITEM:
      categoryName = payload.options.categoryName;
      itemName = payload.options.itemName;

      newItemsArray = state.dataGeneratorParameters.categories[categoryName].items.filter((item) => item.itemName !== itemName);

      newState = {
        ...state,
        dataGeneratorParameters: {
          ...state.dataGeneratorParameters,
          categories: {
            ...state.dataGeneratorParameters.categories,
            [categoryName]: {
              ...state.dataGeneratorParameters.categories[categoryName],
              items: newItemsArray,
            },
          },
        },
      };

      localStorage.setItem('dataGeneratorParameters', JSON.stringify(newState));
      return newState;

    default:
      return state;
  }
};

export default dataGeneratorReducer;
