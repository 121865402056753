/* eslint-disable no-param-reassign */
import produce from 'immer';
import datasetsActionTypes from '../Actions/datasetsActionTypes';

// const defaultETLparameters = {
//   standardPromoETL: {
//     baseline: {
//       method: 'exponential-moving-average',
//       constant: {},
//       'moving-average': {
//         window: [-13, 13],
//       },
//       'exponential-moving-average': {
//         window: [-13, 13],
//         'smoothing-factor': 0.6,
//       },
//     },
//     regularPrice: {
//       method: 'moving-average',
//       constant: {},
//       'moving-average': {
//         window: -13,
//         percentile: 95,
//       },
//     },
//     pantryLoading: {
//       enabled: true,
//       window: 2,
//     },
//     cannibalization: {
//       enabled: false,
//     },
//   },
// };

// const testDatasets = [
//   {
//     name: 'First Run',
//     creationDate: new Date(2020, 2, 24),
//     lastRunDate: new Date(2020, 4, 12),
//     size: 371934532,
//     dataSource: 'files',
//     status: 'ready',
//     runStatus: '',
//     runProgress: 100,
//     selected: false,
//     isDeleting: false,
//     files: [],
//     ETLschema: 'standardPromoETL',
//     ETLparameters: defaultETLparameters,
//   },
//   {
//     name: 'Second Run',
//     creationDate: new Date(2020, 3, 9),
//     lastRunDate: new Date(2020, 4, 14),
//     size: 371934532,
//     dataSource: 'files',
//     status: 'running',
//     runStatus: '',
//     runProgress: 63,
//     selected: false,
//     isDeleting: false,
//     files: [],
//     ETLschema: 'standardPromoETL',
//     ETLparameters: defaultETLparameters,
//   },
//   {
//     name: 'Third Run',
//     creationDate: new Date(2020, 4, 12),
//     lastRunDate: new Date(2020, 4, 15),
//     size: 371934532,
//     dataSource: 'files',
//     status: 'error',
//     runStatus: '',
//     runProgress: 0,
//     selected: false,
//     isDeleting: false,
//     files: [],
//     ETLschema: 'standardPromoETL',
//     ETLparameters: defaultETLparameters,
//   },
// ];

const initialState = {
  datasets: [],
  datasetsAreLoading: true,
  datasetConfigIsSaving: false,
  datasetConfigIsLoading: false,
  // customMetrics: [
  //   {
  //     name: 'ROI',
  //     type: 'relative',
  //     formula: '[Incremental Profits]/[Event Cost] - 1',
  //   },
  // ],
};

const datasetsReducer = produce((draft = initialState, action) => {
  const getDatasetID = (datasetName) => draft.datasets.map((el) => el.name).indexOf(datasetName);
  const getFileID = (datasetName, fileName) => draft.datasets[getDatasetID(datasetName)].files.map((el) => el.name).indexOf(fileName);
  const getFieldID = (datasetName, fileName, fieldName) => draft
    .datasets[getDatasetID(datasetName)]
    .files[getFileID(datasetName, fileName)]
    .fields.map((el) => el.name).indexOf(fieldName);

  switch (action.type) {
    case datasetsActionTypes.UPDATE_DATASETS:
      draft.datasets = action.datasets;
      break;


    case datasetsActionTypes.UPDATE_DATASETS_STATUS:
      action.datasetsStatus.forEach((dataset) => {
        Object.keys(dataset.status).forEach((attr) => {
          draft.datasets.find((el) => el.name === dataset.name)[attr] = dataset.status[attr];
        });
      });
      break;


    case datasetsActionTypes.DATASETS_IS_LOADING:
      draft.datasetsAreLoading = action.datasetsAreLoading;
      break;


    case datasetsActionTypes.DATASET_CONFIG_IS_SAVING:
      draft.datasetConfigIsSaving = action.datasetConfigIsSaving;
      break;


    case datasetsActionTypes.DATASET_CONFIG_IS_LOADING:
      draft.datasetConfigIsLoading = action.datasetConfigIsLoading;
      break;


    case datasetsActionTypes.LOAD_DATASET_PARAMETERS:
      draft.datasets[draft.datasets.map((el) => el.name).indexOf(action.datasetName)] = action.dataset;
      break;


    case datasetsActionTypes.CHANGE_DATASET_PARAMETER:
      draft.datasets[getDatasetID(action.datasetName)][action.parameterName] = action.parameterValue;
      break;


    case datasetsActionTypes.CHANGE_DATASET_ETL_PARAMETER:
      if (action.category) {
        if (action.subcategory) {
          draft.datasets[getDatasetID(action.datasetName)]
            .ETLparameters[action.ETLschema][action.category][action.subcategory][action.parameterName] = action.parameterValue;
        } else {
          draft.datasets[getDatasetID(action.datasetName)]
            .ETLparameters[action.ETLschema][action.category][action.parameterName] = action.parameterValue;
        }
      } else {
        draft.datasets[getDatasetID(action.datasetName)].ETLparameters[action.ETLschema][action.parameterName] = action.parameterValue;
      }
      break;


    case datasetsActionTypes.UPDATE_DATASET_INPUT_FILE_LIST:
      draft.datasets[getDatasetID(action.datasetName)].files = action.newInputFiles;
      break;


    case datasetsActionTypes.UPDATE_DATASET_FILE_INPUT_PARAMETER:
      draft.datasets[getDatasetID(action.datasetName)].files[getFileID(action.datasetName, action.fileName)][action.parameterName] = action.parameterValue;
      break;


    case datasetsActionTypes.BULK_UPDATE_DATASET_FILE_INPUT_PARAMETER:
      for (const fileName of action.fileNames) {
        draft.datasets[getDatasetID(action.datasetName)].files[getFileID(action.datasetName, fileName)][action.parameterName] = action.parameterValue;
      }
      break;


    case datasetsActionTypes.UPDATE_FIELD_PARAMETER:
      draft.datasets[getDatasetID(action.datasetName)]
        .files[getFileID(action.datasetName, action.fileName)]
        .fields[getFieldID(action.datasetName, action.fileName, action.fieldName)][action.parameterName] = action.parameterValue;
      break;


    case datasetsActionTypes.BULK_UPDATE_FIELD_PARAMETER:
      for (const fieldName of action.fieldNames) {
        draft.datasets[getDatasetID(action.datasetName)]
          .files[getFileID(action.datasetName, action.fileName)]
          .fields[getFieldID(action.datasetName, action.fileName, fieldName)][action.parameterName] = action.parameterValue;
      }
      break;


    default:
      return draft;
  }
  return draft;
});

export default datasetsReducer;
