import React from 'react';
import PropTypes from 'prop-types';
import { HTMLSelect, Button, Intent, EditableText, Icon } from '@blueprintjs/core';
import SimpleTable from 'Components/SimpleTable/SimpleTable';
import { fieldPropTypes } from 'AppModules/Data//Definitions/dataPropTypes';
import './FieldsConfiguration.css';

const FieldsConfiguration = ({ handleChangeFieldMapTo, handleChangeFieldType, ...props }) => {
  const [tableItems, setTableItems] = React.useState([]);

  const tableHeaders = [
    {
      title: 'Field Name',
      width: 30,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Map To',
      width: 30,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Type',
      width: 20,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Validation',
      width: 20,
      alignHeader: 'center',
      alignItems: 'center',
    },
  ];

  // When fileList changes: build tableItems array
  React.useEffect(() => {
    const newTableItems = props.fields.map((field) => ({
      selected: field.selected,
      key: field.name,
      components: [
        <p>{field.name}</p>,
        <span>
          <EditableText
            defaultValue={field.mappedTo}
            onConfirm={(value) => handleChangeFieldMapTo(field.name, value)}
            confirmOnEnterKey
          />
          <Icon icon="edit" style={{ marginLeft: '5px', color: 'var(--disabledTextColor)' }} />
        </span>,
        <HTMLSelect
          options={['metric', 'dimension', 'date']}
          value={field.type}
          onChange={(e) => handleChangeFieldType(field.name, e.currentTarget.value)}
          minimal
        />,
        <span>
          <Icon icon="tick-circle" intent={Intent.SUCCESS} style={{ marginRight: '10px' }} />
          {field.validation || 'OK'}
        </span>,
      ],
    }));
    setTableItems(newTableItems);
  }, [props.fields, handleChangeFieldMapTo, handleChangeFieldType]);


  return (
    <div className="FieldsConfiguration">
      <div className="importExportButtons">
        <Button icon="export" text="Export" intent={Intent.PRIMARY} />
        <Button icon="import" text="Import" intent={Intent.PRIMARY} />
      </div>
      <SimpleTable
        className="fieldsConfigurationTable"
        headers={tableHeaders}
        items={tableItems}
        selectAll
        handleClickSelectAll={props.handleClickSelectAll}
        selectOne
        handleClickSelect={props.handleClickSelect}
      />
      <div className="closeButton">
        <Button text="close" onClick={props.handleClose} />
      </div>
    </div>
  );
};

FieldsConfiguration.propTypes = {
  fields: PropTypes.arrayOf(fieldPropTypes).isRequired,
  handleChangeFieldType: PropTypes.func.isRequired,
  handleChangeFieldMapTo: PropTypes.func.isRequired,
  handleClickSelectAll: PropTypes.func.isRequired,
  handleClickSelect: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FieldsConfiguration;
