/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Intent } from '@blueprintjs/core';
import attributeDefinitions from '../Definitions/attributeDefinitions';
import { comparisonDefinitions } from '../Definitions/definitions';
import { chartTypes } from '../Definitions/chartTypes';
import titanActionTypes from '../Actions/titanActionTypes';
// import { comparisonDefinitions } from '../Definitions/definitions';
import { addSuffixIfNecessary, dateReviver } from '../../../utils/util';
import { validateParameters } from '../Definitions/chartParametersRules';
import buildChartQuery from './buildChartQuery';
import { attributeDefaultProps, comparisonDefaultProps } from '../Definitions/propTypes';

// The initial Filters are the ones that are main filters
const mainFilters = attributeDefinitions.filter((attr) => attr.useAsMainFilter).map((attr) => ({
  name: attr.name,
  values: attr.filterRules.defaultValue,
}));

const defaultChartGroup = {
  name: 'My Chart #1',
  chartIsSynced: false,
  chartQuery: {},
  chartParameters: {
    validation: { valid: true, intent: Intent.PRIMARY },
    type: 'lines-bars',
    metrics: [attributeDefaultProps],
    dimensions: [attributeDefaultProps],
    filters: [
      ...mainFilters,
    ],
    comparisons: [],
  },
  chartParametersOfLoadedData: {},
};

const initialState = localStorage.getItem('titanDashboards') ? JSON.parse(localStorage.getItem('titanDashboards'), dateReviver) : {
  GA_token: '',
  dashboards: [
    {
      name: 'New Dashboard',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      dashboardControls: {
        displayMode: 'expanded',
      },
      globalFilters: [
        // {
        //   name: 'ga:productCategoryHierarchy',
        //   values: ['TICK_SD', 'TICK_MD'],
        // },
        // {
        //   name: 'TEST_NUMERICAL_ATTRIBUTE',
        //   values: {
        //     from: 3,
        //     to: 4,
        //   },
        // },
      ],
      chartGroups: [defaultChartGroup],
    },
  ],
};

const emptyDashboard = {
  name: 'New Dashboard',
  description: '',
  dashboardControls: {
    attributeDefinitions,
    displayMode: 'expanded',
  },
  chartGroups: [],
};

const titanReducer = produce((draft = initialState, action) => {
  let dashboardID;
  let chartGroupsArray;
  let currentChartGroup;
  let currentAttribute;
  let currentAttributeDefinition;
  let currentAttributeParameters;
  let chartType;
  let attributesArray;
  let comparisonsArray;
  let chartParametersString;
  let chartQuery;
  let chartQueryLoadedData;
  let currentChartParameters;
  let chartParametersOfLoadedData;
  let chartParametersOfLoadedDataString;
  let chartIsSynced;
  let parametersValidation;

  switch (action.type) {
    case titanActionTypes.SET_GA_TOKEN:
      draft.GA_token = action.token;
      break;


    /* ==================================
    ==========    DASHBOARD    ==========
    =================================== */
    case titanActionTypes.ADD_DASHBOARD:
      draft.dashboards.push({ ...emptyDashboard, name: addSuffixIfNecessary('New Dashboard', draft.dashboards.map((dashboard) => dashboard.name)) });
      break;


    case titanActionTypes.REMOVE_DASHBOARD:
      draft.dashboards = draft.dashboards.filter((dashboard) => dashboard.name !== action.dashboardName);
      break;


    case titanActionTypes.RENAME_DASHBOARD:
      dashboardID = draft.dashboards.map((dashboard) => dashboard.name).indexOf(action.oldDashboardName);
      draft.dashboards[dashboardID].name = action.newDashboardName;
      break;


    case titanActionTypes.CHANGE_DASHBOARD_DESCRIPTION:
      dashboardID = draft.dashboards.map((dashboard) => dashboard.name).indexOf(action.dashboardName);
      draft.dashboards[dashboardID].description = action.newDescription;
      break;


    /* ===================================
    ==========    CHARTGROUP    ==========
    ==================================== */
    case titanActionTypes.ADD_CHART:
      draft.dashboards[action.dashboardID].chartGroups.push({
        ...defaultChartGroup,
        name: addSuffixIfNecessary('New Chart', draft.dashboards[action.dashboardID].chartGroups.map((chart) => chart.name)),
      });
      break;


    case titanActionTypes.DUPLICATE_CHART:
      draft.dashboards[action.dashboardID].chartGroups = [
        ...draft.dashboards[action.dashboardID].chartGroups.slice(0, action.chartGroupID + 1),
        {
          ...draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID],
          name: addSuffixIfNecessary(
            draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].name,
            draft.dashboards[action.dashboardID].chartGroups.map((chart) => chart.name),
          ),
        },
        ...draft.dashboards[action.dashboardID].chartGroups.slice(action.chartGroupID + 1),
      ];
      break;


    case titanActionTypes.REMOVE_CHART:
      chartGroupsArray = draft.dashboards[action.dashboardID].chartGroups;
      draft.dashboards[action.dashboardID].chartGroups = [
        ...chartGroupsArray.slice(0, action.chartGroupID),
        ...chartGroupsArray.slice(action.chartGroupID + 1),
      ];
      break;


    case titanActionTypes.RENAME_CHART:
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].name = action.name;
      break;


    case titanActionTypes.CHANGE_CHART_TYPE:
      currentChartGroup = draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID];

      // Reset parameters
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID] = {
        ...defaultChartGroup,
        name: currentChartGroup.name,
        chartParameters: {
          ...currentChartGroup.chartParameters,
          type: action.chartType,
        },
        // chartParametersOfLoadedData: {
        //   ...currentChartGroup.chartParametersOfLoadedData,
        //   type: action.chartType,
        // },
      };

      break;


    /* ==================================
    ==========    ATTRIBUTE    ==========
    =================================== */
    case titanActionTypes.ADD_ATTRIBUTE:
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters[action.attributeType].push(attributeDefaultProps);
      break;


    case titanActionTypes.REMOVE_ATTRIBUTE:
      attributesArray = draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters[action.attributeType];
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters[action.attributeType] = [
        ...attributesArray.slice(0, action.attributeID),
        ...attributesArray.slice(action.attributeID + 1),
      ];
      break;


    case titanActionTypes.CHANGE_ATTRIBUTE:
      // find corresponding attribute to add default parameters if none present already
      currentAttribute = draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters[action.attributeType][action.attributeID];
      currentAttributeDefinition = ['metrics', 'dimensions'].includes(action.attributeType)
        ? attributeDefinitions.find((attr) => attr.name === action.newAttributeName)
        : comparisonDefinitions.find((attr) => attr.name === action.newAttributeName);
      // Find parameters that should be present
      chartType = draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.type;

      currentAttributeParameters = chartTypes.find((chart) => (
        chart.name === chartType)).parameterGroups.find((group) => group.type === action.attributeType).parameters;
      // Assign default value to parameter if none present
      for (const parameter of currentAttributeParameters) {
        if (!currentAttribute[parameter.name]) {
          // use the default of the attributeDefinition, if there is none use the default of the parameter
          currentAttribute[parameter.name] = currentAttributeDefinition.defaults[parameter.name] || parameter.defaultValue;
        }
      }
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters[action.attributeType][action.attributeID] = {
        ...currentAttribute,
        name: action.newAttributeName,
      };
      break;


    case titanActionTypes.CHANGE_ATTRIBUTE_PARAMETER:
      draft.dashboards[action.dashboardID]
        .chartGroups[action.chartGroupID]
        .chartParameters[action.attributeType][action.attributeID][action.parameterName] = action.parameterValue;
      break;


    case titanActionTypes.VALIDATE_PARAMETERS:
      currentChartParameters = JSON.parse(JSON.stringify(draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters), dateReviver);
      parametersValidation = validateParameters(currentChartParameters); // { valid, message, intent }
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.validation = parametersValidation;
      break;


    /* ===============================
    ==========    FILTER    ==========
    ================================ */
    case titanActionTypes.UPDATE_FILTER_ATTRIBUTE:
      if (action.chartGroupID === -1) {
        draft.dashboards[action.dashboardID].globalFilters[action.filterID].name = action.attributeName;
        draft.dashboards[action.dashboardID].globalFilters[action.filterID].values = undefined;
      } else {
        draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.filters[action.filterID].name = action.attributeName;
        draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.filters[action.filterID].values = undefined;
      }
      break;


    case titanActionTypes.UPDATE_FILTER_VALUE:
      if (action.chartGroupID === -1) {
        draft.dashboards[action.dashboardID].globalFilters[action.filterID].values = action.values;
      } else {
        draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.filters[action.filterID].values = action.values;
      }
      break;


    case titanActionTypes.ADD_FILTER:
      if (action.chartGroupID === -1) {
        draft.dashboards[action.dashboardID].globalFilters.push({ name: action.filterAttributeName, values: undefined });
      } else {
        draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.filters.push({ name: undefined, values: undefined });
      }
      break;


    case titanActionTypes.REMOVE_FILTER:
      if (action.chartGroupID === -1) {
        draft.dashboards[action.dashboardID].globalFilters = [
          ...draft.dashboards[action.dashboardID].globalFilters.slice(0, action.filterID, action.filterID + 1),
          ...draft.dashboards[action.dashboardID].globalFilters.slice(action.filterID + 1),
        ];
      } else {
        draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.filters = [
          ...draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.filters.slice(0, action.filterID, action.filterID + 1),
          ...draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.filters.slice(action.filterID + 1),
        ];
      }
      break;


    /* ===================================
    ==========    COMPARISON    ==========
    ==================================== */
    case titanActionTypes.ADD_COMPARISON:
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.comparisons.push(comparisonDefaultProps);
      break;


    case titanActionTypes.REMOVE_COMPARISON:
      comparisonsArray = draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.comparisons;
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.comparisons = [
        ...comparisonsArray.slice(0, action.comparisonID),
        ...comparisonsArray.slice(action.comparisonID + 1),
      ];
      break;


    case titanActionTypes.CHANGE_COMPARISON:
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters.comparisons[action.comparisonID].name = action.newComparisonName;
      break;


    /* ==============================
    ==========    CHART    ==========
    ================================ */
    case titanActionTypes.UPDATE_CHART:
      currentChartParameters = draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters;
      chartParametersOfLoadedData = draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParametersOfLoadedData;

      chartQuery = buildChartQuery(currentChartParameters);
      chartQueryLoadedData = Object.keys(chartParametersOfLoadedData).length > 0 ? buildChartQuery(chartParametersOfLoadedData) : '';
      // if chartQuery is different from the one of the loaded data, then update the state in the chartQuery (this will trigger the data fetching through useQuery)
      if (JSON.stringify(chartQuery) !== JSON.stringify(chartQueryLoadedData)) {
        draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartQuery = chartQuery;
      } else {
        // if chartQuery obejcts corresponding to old and new chartParameters are equal, then the query won't happen and we should set chartParametersOfLoadedData = currentChartParameters;
        draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParametersOfLoadedData = currentChartParameters;
      }
      break;

    case titanActionTypes.SET_DATA_IS_LOADED:
      currentChartParameters = draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters;
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParametersOfLoadedData = currentChartParameters;
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartIsSynced = action.dataIsLoaded;
      if (action.dataIsLoaded) {
        draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParametersOfLoadedData = currentChartParameters;
      }
      break;

    case titanActionTypes.CHECK_CHART_IS_SYNCED:
      chartParametersString = JSON.stringify(draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParameters);
      chartParametersOfLoadedDataString = JSON.stringify(draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartParametersOfLoadedData);
      chartIsSynced = chartParametersString === chartParametersOfLoadedDataString;
      draft.dashboards[action.dashboardID].chartGroups[action.chartGroupID].chartIsSynced = chartIsSynced;
      break;


    default:
      return draft;
  }
  localStorage.setItem('titanDashboards', JSON.stringify(draft));
  return draft;
});

export default titanReducer;
