import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Checkbox, Button, Intent, Spinner } from '@blueprintjs/core';
import numeral from 'numeral';
import ButtonWithWarning from 'Components/ButtonWithWarning/ButtonWithWarning';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import ScreenshotRowContainer from './ScreenshotRow/ScreenshotRowContainer';
import './ExplorerScreenshotsList.css';

const ExplorerScreenshotsList = (props) => (
  <div className="ExplorerScreenshotsList">
    <div className="screenshotsControls">
      <div className="controlButtons">
        <Button
          className="compareScreenshotsButton"
          text="Compare"
          icon="comparison"
          disabled={props.selectedScreenshotKeys.length !== 2}
          onClick={props.handleCompareScreenshots}
        />
        <Button
          className="removeLocalScreenshotsButton"
          text="Remove local copy"
          icon="trash"
          intent={Intent.WARNING}
          onClick={props.handleRemoveLocalScreenshots}
          disabled={props.selectedScreenshotKeys.length === 0}
          minimal
        />
        <ButtonWithWarning
          className="removeAllLocalScreenshotsButton"
          buttonText="Remove all local copies"
          buttonIcon="trash"
          buttonIntent={Intent.DANGER}
          warningBodyText="Are you sure you want to remove all local screenshots? All screenshots will need to be downloaded again"
          warningButtonText="Yes, remove"
          warningIcon="warning-sign"
          warningIntent={Intent.WARNING}
          onConfirm={props.removeAllLocalScreenshotsButton}
          minimal
        />
      </div>
      <p className="screenshotsCounts">
        {`${numeral(props.totalNbOfScreenshots).format('0,0')} screenshots (${numeral(props.localNbOfScreenshots).format('0,0')} saved on this device)`}
      </p>
    </div>
    <table className="ExplorerScreenshotsListTable" cellSpacing="0" cellPadding="0">
      <thead>
        <tr>
          <th style={{ width: '5%', textAlign: 'center' }}>
            <Checkbox
              checked={props.screenshots.length === props.selectedScreenshotKeys.length}
              indeterminate={props.screenshots.length !== props.selectedScreenshotKeys.length && props.selectedScreenshotKeys.length > 0}
              onChange={props.handleSelectAllScreenshots}
            />
          </th>
          {props.tableHeaders.map((header) => (
            <th
              key={header.title}
              style={{ width: `${header.width * 0.95}%`, textAlign: header.alignHeader || ' center' }}
              onClick={() => props.handleSortItems(header.title)}
            >
              {props.sorting.headerTitle === header.title ? (
                <Icon style={{ margin: '0 4px 0 -20px' }} icon={props.sorting.ascending ? 'arrow-up' : 'arrow-down'} />
              ) : null}
              {header.title}
            </th>
          ))}
        </tr>
      </thead>
      {props.screenshotsListIsUpdating
        ? (
          <tbody className="loading">
            <tr>
              <td colSpan={props.tableHeaders.length + 1} style={{ height: `${35 * props.screenshots.length}px` }}><Spinner size={25} /></td>
            </tr>
          </tbody>
        )
        : (
          <tbody>
            {props.screenshots.map((screenshot) => (
              <ScreenshotRowContainer
                key={screenshot.key}
                screenshot={screenshot}
                selected={props.selectedScreenshotKeys.includes(screenshot.key)}
                isDeleting={props.deletingLocalCopyKeys.includes(screenshot.key)}
                handleSelectScreenshot={props.handleSelectScreenshot}
                handleSelectAllScreenshots={props.handleSelectAllScreenshots}
                aScreenshotIsLoading={props.aScreenshotIsLoading}
                setAScreenshotIsLoading={props.setAScreenshotIsLoading}
                handleDisplayScreenshot={props.handleDisplayScreenshot}
                updateNbOfLocalScreenshotKeys={props.updateNbOfLocalScreenshotKeys}
              />
            ))}
          </tbody>
        )}
    </table>
  </div>
);

ExplorerScreenshotsList.propTypes = {
  tableHeaders: PropTypes.arrayOf(PropTypes.shape({
    alignHeader: PropTypes.string,
    alignItems: PropTypes.string,
    title: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  })).isRequired,
  sorting: PropTypes.shape({
    attribute: PropTypes.string.isRequired,
    ascending: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string.isRequired,
  }).isRequired,
  handleSortItems: PropTypes.func.isRequired,
  screenshots: PropTypes.arrayOf(screenshotPropTypes).isRequired,
  selectedScreenshotKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  deletingLocalCopyKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectScreenshot: PropTypes.func.isRequired,
  handleSelectAllScreenshots: PropTypes.func.isRequired,
  aScreenshotIsLoading: PropTypes.bool.isRequired,
  setAScreenshotIsLoading: PropTypes.func.isRequired,
  handleDisplayScreenshot: PropTypes.func.isRequired,
  handleCompareScreenshots: PropTypes.func.isRequired,
  handleRemoveLocalScreenshots: PropTypes.func.isRequired,
  removeAllLocalScreenshotsButton: PropTypes.func.isRequired,
  totalNbOfScreenshots: PropTypes.number.isRequired,
  localNbOfScreenshots: PropTypes.number.isRequired,
  updateNbOfLocalScreenshotKeys: PropTypes.func.isRequired,
  screenshotsListIsUpdating: PropTypes.bool.isRequired,
};

export default ExplorerScreenshotsList;
