import React from 'react';
import { connect } from 'react-redux';
import { Icon, Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import MyBreadCrumbs from 'Components/MyBreadCrumbs/MyBreadCrumbs';
import Dashboard from './Dashboard';

const DashboardContainer = (props) => {
  // get dashboardName from URL
  const dashboardName = decodeURI(props.match.params.dashboardName);

  return props.dashboardID === -1 ? (
    <div className="dashboard">
      <MyBreadCrumbs
        items={[
          { href: '/', text: 'Home' },
          { href: '/Titan', text: 'Titan' },
          { href: '/Titan/Dashboards', text: 'Dashboards' },
        ]}
      />
      <div className="notFound">
        <Icon icon="error" intent={Intent.WARNING} iconSize={32} />
        <p>This dashboard does not exist</p>
      </div>
    </div>
  ) : (
      // eslint-disable-next-line react/jsx-indent
      <Dashboard dashboardID={props.dashboardID} dashboardName={dashboardName} />
      // eslint-disable-next-line indent
    );
};

DashboardContainer.propTypes = {
  dashboardID: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dashboardName: PropTypes.string,
    }),
  }).isRequired,
};


const mapStateToProps = (store, ownProps) => {
  const dashboardID = store.titanReducer.dashboards.map((dashboard) => dashboard.name).indexOf(ownProps.match.params.dashboardName);
  if (dashboardID === -1) return { dashboardID };
  return {
    dashboardID,
    dashboardName: store.titanReducer.dashboards[dashboardID].name,
  };
};

export default connect(mapStateToProps)(DashboardContainer);
