/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ganymedeActions from 'AppModules/Ganymede/Actions/ganymedeActions';
import ExplorerFilters from './ExplorerFilters';

const ExplorerFiltersContainer = (props) => {
  const filtersArray = Object.entries(props.filters).map(([key, value]) => ({ name: key, ...value }));

  // When filtersSelection change: fetch corresponding list of screenshots from the server
  React.useEffect(() => {
    // if no park is selected, all parks should be in the list (to allow querying on the server, becase park is the DB's primary key)
    const selection = { ...props.filtersSelection };
    if (selection.Park.length === 0) {
      selection.Park = props.filters.Park.values;
    }
    props.updateScreenshots(props.token, selection);

    // TODO: props.filters should be in the list of dependencies. Need to implement system where updateScreenshots is not triggered if props.filters didn't change
  }, [props.filtersSelection, props.updateScreenshots]);

  return (
    <ExplorerFilters
      filters={filtersArray}
      filtersSelection={props.filtersSelection}
      handleUpdateFilter={props.updateFilter}
      handleAddDateFilter={props.addDateFilter}
      handleRemoveDateFilter={props.removeDateFilter}
      handleChangeDateFilterType={props.changeDateFilterType}
    />
  );
};

ExplorerFiltersContainer.propTypes = {
  token: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  filtersSelection: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  addDateFilter: PropTypes.func.isRequired,
  removeDateFilter: PropTypes.func.isRequired,
  changeDateFilterType: PropTypes.func.isRequired,
  updateScreenshots: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  filters: store.ganymedeReducer.filters,
  filtersSelection: store.ganymedeReducer.filtersSelection,
  token: store.authReducer.token,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: (filterName, filterValues) => dispatch(ganymedeActions.updateExplorerFilter(filterName, filterValues)),
  addDateFilter: () => dispatch(ganymedeActions.addDateFilter()),
  removeDateFilter: (additionalDateFilterID) => dispatch(ganymedeActions.removeDateFilter(additionalDateFilterID)),
  changeDateFilterType: (additionalDateFilterID, value) => dispatch(ganymedeActions.changeDateFilterType(additionalDateFilterID, value)),
  updateScreenshots: (token, filtersSelection) => dispatch(ganymedeActions.updateScreenshots(token, filtersSelection)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExplorerFiltersContainer);
