import dataGeneratorActionTypes from './dataGeneratorActionTypes';

const updateParameterRangeValue = (options) => ({
  type: dataGeneratorActionTypes.UPDATE_PARAMETER,
  options,
});

const updateNumberOfItems = (options) => ({
  type: dataGeneratorActionTypes.UPDATE_NUMBER_OF_ITEMS,
  options,
});

const addCategoryItem = (options) => ({
  type: dataGeneratorActionTypes.ADD_CATEGORY_ITEM,
  options,
});

const changeCategoryItemName = (options) => ({
  type: dataGeneratorActionTypes.CHANGE_CATEGORY_ITEM_NAME,
  options,
});

const editCategoryItem = (options) => ({
  type: dataGeneratorActionTypes.EDIT_CATEGORY_ITEM,
  options,
});

const removeCategoryItem = (options) => ({
  type: dataGeneratorActionTypes.REMOVE_CATEGORY_ITEM,
  options,
});

export default {
  updateParameterRangeValue,
  updateNumberOfItems,
  addCategoryItem,
  changeCategoryItemName,
  editCategoryItem,
  removeCategoryItem,
};
