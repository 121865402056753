import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Button, Intent, Dialog, FormGroup, InputGroup } from '@blueprintjs/core';
import ButtonWithWarning from 'Components/ButtonWithWarning/ButtonWithWarning';
import { pagePropTypes } from '../../Definitions/ganymedePropTypes';
import './PageParamsDialog.css';

const PageParamsDialog = (props) => (
  <Dialog
    className="paramDialog"
    isOpen={props.isOpen}
    onClose={() => props.setParamDialogIsOpen(false)}
    title={props.mode === 'edit' ? `${props.page.name} - Edit Parameters` : 'Add New Page'}
    icon={props.mode === 'edit' ? 'cog' : 'add'}
    style={{ width: '800px' }}
  >
    <div className="parametersContent">
      <Switch
        checked={props.page.active}
        disabled={false}
        onChange={() => props.handleChangeParam({ parameter: 'active', value: !props.page.active })}
        label="Active"
      />
      <FormGroup
        className={props.page.name === '' ? 'invalid' : ''}
        label="Name"
        labelFor="name-input"
        labelInfo="(required)"
        helperText={props.page.name === '' ? 'This field cannot be empty' : ''}
        intent={props.page.name === '' ? Intent.WARNING : null}
      >
        <InputGroup
          id="name-input"
          placeholder="e.g. SWO Tickets"
          value={props.page.name}
          onChange={(e) => props.handleChangeParam({ parameter: 'name', value: e.target.value })}
          disabled={props.mode === 'edit'}
          intent={props.page.name === '' ? Intent.WARNING : null}
        />
      </FormGroup>
      <FormGroup
        className={props.page.URL === '' ? 'invalid' : ''}
        label="URL"
        labelFor="URL-input"
        labelInfo="(required)"
        helperText={props.page.URL === '' ? 'This field cannot be empty' : ''}
        intent={props.page.URL === '' ? Intent.WARNING : null}
      >
        <InputGroup
          id="URL-input"
          placeholder="e.g. https://seaworld.com/orlando/tickets/"
          value={props.page.URL}
          onChange={(e) => props.handleChangeParam({ parameter: 'URL', value: e.target.value })}
          intent={props.page.URL === '' ? Intent.WARNING : null}
        />
      </FormGroup>
      <div className="parametersContent_left">
        <FormGroup
          className={props.page.park === '' ? 'invalid' : ''}
          label="Park"
          labelFor="park-input"
          labelInfo="(required)"
          helperText={props.page.park === '' ? 'This field cannot be empty' : ''}
          intent={props.page.park === '' ? Intent.WARNING : null}
        >
          <InputGroup
            id="park-input"
            placeholder="e.g. SWO"
            value={props.page.park}
            onChange={(e) => props.handleChangeParam({ parameter: 'park', value: e.target.value })}
            intent={props.page.park === '' ? Intent.WARNING : null}
          />
        </FormGroup>
        <FormGroup
          className={props.page.localIP === '' ? 'invalid' : ''}
          label="Local IP address"
          labelFor="localIP-input"
          labelInfo="(required)"
          helperText={props.page.localIP === '' ? 'This field cannot be empty' : ''}
          intent={props.page.localIP === '' ? Intent.WARNING : null}
        >
          <InputGroup
            id="localIP-input"
            placeholder="e.g. 71.43.195.10"
            value={props.page.localIP}
            onChange={(e) => props.handleChangeParam({ parameter: 'localIP', value: e.target.value })}
            intent={props.page.localIP === '' ? Intent.WARNING : null}
          />
        </FormGroup>
        <FormGroup
          className={props.page.nonLocalIP === '' ? 'invalid' : ''}
          label="Non-Local IP address"
          labelFor="nonLocalIP-input"
          labelInfo="(required)"
          helperText={props.page.nonLocalIP === '' ? 'This field cannot be empty' : ''}
          intent={props.page.nonLocalIP === '' ? Intent.WARNING : null}
        >
          <InputGroup
            id="nonLocalIP-input"
            placeholder="e.g. 71.43.195.10"
            value={props.page.nonLocalIP}
            onChange={(e) => props.handleChangeParam({ parameter: 'nonLocalIP', value: e.target.value })}
            intent={props.page.nonLocalIP === '' ? Intent.WARNING : null}
          />
        </FormGroup>
      </div>
      <div className="parametersContent_right">
        <FormGroup
          className={props.page.page === '' ? 'invalid' : ''}
          label="Page"
          labelFor="page-input"
          labelInfo="(required)"
          helperText={props.page.page === '' ? 'This field cannot be empty' : ''}
          intent={props.page.page === '' ? Intent.WARNING : null}
        >
          <InputGroup
            id="page-input"
            placeholder="e.g. Tickets"
            value={props.page.page}
            onChange={(e) => props.handleChangeParam({ parameter: 'page', value: e.target.value })}
            intent={props.page.page === '' ? Intent.WARNING : null}
          />
        </FormGroup>
        <FormGroup
          label="Compare From Selector"
          labelFor="compareFromSelector-input"
          labelInfo="(required)"
        >
          <InputGroup
            id="compareFromSelector-input"
            placeholder="e.g. #page-content"
            value={props.page.compareFromSelector}
            onChange={(e) => props.handleChangeParam({ parameter: 'compareFromSelector', value: e.target.value })}
          />
        </FormGroup>
        <FormGroup
          label="Compare To Selector"
          labelFor="compareToSelector-input"
          labelInfo="(required)"
        >
          <InputGroup
            id="compareToSelector-input"
            placeholder="e.g. .breadcrumbs__container"
            value={props.page.compareToSelector}
            onChange={(e) => props.handleChangeParam({ parameter: 'compareToSelector', value: e.target.value })}
          />
        </FormGroup>
      </div>
      <div className="buttons">
        <Button intent={Intent.WARNING} onClick={() => props.setParamDialogIsOpen(false)}>Cancel</Button>
        <ButtonWithWarning
          buttonText={props.mode === 'edit' ? 'Save' : 'Create'}
          buttonIntent={Intent.PRIMARY}
          buttonIcon={props.mode === 'edit' ? 'updated' : 'add'}
          warningBodyText={props.mode === 'edit'
            ? 'Are you sure you want to modify these parameters? This action cannot be undone'
            : 'Are you sure you want to create this page? Screenshots will be taken daily from now on'}
          warningIntent={Intent.WARNING}
          warningIcon="warning-sign"
          warningButtonText={props.mode === 'edit' ? 'Yes, Save' : 'Yes, Add'}
          onConfirm={props.confirmChangeParams}
          disabled={
            Object.keys(props.page).filter(
              (key) => !['compareFromSelector', 'compareToSelector'].includes(key),
            ).reduce((acc, curr) => (acc || props.page[curr] === ''),
              false)
          }
        />
      </div>
    </div>
  </Dialog>
);

PageParamsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setParamDialogIsOpen: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['edit', 'add']).isRequired,
  page: pagePropTypes.isRequired,
  handleChangeParam: PropTypes.func.isRequired,
  confirmChangeParams: PropTypes.func.isRequired,
};

export default PageParamsDialog;
