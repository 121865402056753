/* eslint-disable react/prop-types */
import React from 'react';
import { RangeSlider } from '@blueprintjs/core';

const ParamDefinition = (props) => {
  const { description, type, range, value } = props.parameter;

  return (
    <div className="ParamDefinition">
      <h4>{props.parameterName}</h4>
      {type === 'range' && (
        <RangeSlider
          className="rangeSlider"
          min={range.from}
          max={range.to}
          stepSize={range.stepSize}
          labelStepSize={range.labelStepSize}
          labelPrecision={range.labelPrecision}
          onChange={props.handleChangeRange}
          value={value}
        />
      )}
      <small className="description">{description}</small>
      <small className="helperText">
        ↳ The
        {' '}
        {props.parameterName.toLowerCase()}
        {' '}
        will range between
        {' '}
        <b>{value[0]}</b>
        {' '}
        and
        {' '}
        <b>{value[1]}</b>
      </small>
    </div>
  );
};

export default ParamDefinition;
