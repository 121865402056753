/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import { datasetsPulseFrequency_slow, datasetsPulseFrequency_fast } from 'utils/constants';
import datasetsActions from 'AppModules/Data/Actions/datasetsActions';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import DatasetConfiguration from './DatasetConfiguration';

const DatasetConfigurationContainer = (props) => {
  const [activeTabId, setActiveTabId] = React.useState('inputs');
  const [datasetsPulseIsOn, setDatasetsPulseIsOn] = React.useState(true);

  const { token } = useSelector((store) => store.authReducer);
  // get fileList from uploadReducer to send to loadDatasetConfig (so the dataset file list can be rebuild after loading the dataste config from cloud)
  const { datasetsAreLoading, datasetConfigIsSaving, datasetConfigIsLoading } = useSelector((store) => store.datasetsReducer);

  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  const datasetsPulse = async () => {
    stableDispatch(datasetsActions.updateDatasetsStatus(token));
  };

  // When mounting and if datasetsPulseIsOn is true: get params from localStorage & set datasetPulseID
  React.useEffect(() => {
    let datasetsPulseID;
    if (datasetsPulseIsOn) { // Set up recurent function to check status of datasets
      datasetsPulseID = setInterval(datasetsPulse, props.dataset.status === 'Running' ? datasetsPulseFrequency_fast : datasetsPulseFrequency_slow);
    } else {
      clearInterval(datasetsPulseID);
    }
    // clear datasetPulseID when unmounting
    return () => {
      clearInterval(datasetsPulseID);
    };
  }, [datasetsPulseIsOn, props.dataset.status]);

  const handleClickDatasetPulse = () => {
    setDatasetsPulseIsOn(!datasetsPulseIsOn);
  };

  const handleDatasetConfigTabChange = (tabId) => {
    setActiveTabId(tabId);
  };

  const handleSaveConfiguration = () => {
    stableDispatch(datasetsActions.saveDatasetConfig(props.dataset, token));
  };
  const handleLoadConfiguration = () => {
    stableDispatch(datasetsActions.loadDatasetConfig(props.dataset.name, token));
  };

  return (
    <DatasetConfiguration
      dataset={props.dataset}
      datasetsAreLoading={datasetsAreLoading}
      activeTabId={activeTabId}
      handleDatasetConfigTabChange={handleDatasetConfigTabChange}
      handleSaveConfiguration={handleSaveConfiguration}
      handleLoadConfiguration={handleLoadConfiguration}
      datasetConfigIsSaving={datasetConfigIsSaving}
      datasetConfigIsLoading={datasetConfigIsLoading}
      datasetsPulseIsOn={datasetsPulseIsOn}
      handleClickDatasetPulse={handleClickDatasetPulse}
    />
  );
};

DatasetConfigurationContainer.propTypes = {
  dataset: datasetPropTypes.isRequired,
};

export default DatasetConfigurationContainer;
