import React from 'react';
import PropTypes from 'prop-types';
import MyCircularProgressBar from 'Components/MyCircularProgressBar/MyCircularProgressBar';
import { Intent, Icon } from '@blueprintjs/core';
import ButtonWithWarning from 'Components/ButtonWithWarning/ButtonWithWarning';
import { capitalize } from 'utils/util';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import './RunDefinition.css';

const RunDefinition = (props) => {
  const isRunning = props.dataset.status === 'Running';

  return (
    <div className="RunDefinition">
      <ButtonWithWarning
        buttonText="Start Run"
        buttonIntent={Intent.PRIMARY}
        warningBodyText="Start Run? This will save the configuration to cloud"
        warningButtonText="Yes, save configuration and start run"
        warningIcon="cog"
        warningIntent={Intent.PRIMARY}
        onConfirm={props.handleClickRun}
        disabled={isRunning}
      />
      <div className="progressBar">
        <MyCircularProgressBar progress={props.dataset.runProgress || 0} isRunning={isRunning} size={60} />
      </div>
      <div className="runStatus">
        {props.dataset.status === 'Ready' && <Icon icon="tick-circle" iconSize={24} intent={Intent.SUCCESS} />}
        {props.dataset.status === 'Failed' && <Icon icon="error" iconSize={24} intent={Intent.DANGER} />}
        <span>
          {capitalize(props.dataset.status)}
          {props.dataset.status === 'Running' && <span className="runStatusMessage">{`...${props.dataset.runStatus}`}</span>}
        </span>
      </div>
    </div>

  );
};

RunDefinition.propTypes = {
  dataset: datasetPropTypes.isRequired,
  handleClickRun: PropTypes.func.isRequired,
};

export default RunDefinition;
