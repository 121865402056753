import React from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import datasetsActions from 'AppModules/Data/Actions/datasetsActions';
import RunParametersDefinition from './RunParametersDefinition';

const RunParametersDefinitionContainer = (props) => {
  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  const handleChangeETLschema = (newETLschema) => {
    stableDispatch(datasetsActions.changeDatasetParameter(props.dataset.name, 'ETLschema', newETLschema));
  };

  return props.dataset.dataSource !== 'files' ? null : (
    <RunParametersDefinition
      dataset={props.dataset}
      handleChangeETLschema={handleChangeETLschema}
    />
  );
};

RunParametersDefinitionContainer.propTypes = {
  dataset: datasetPropTypes.isRequired,
};

export default RunParametersDefinitionContainer;
