const layout = {
  height: 300,
  showlegend: true,
  margin: {
    t: 50,
    b: 50,
    l: 50,
    r: 50,
  },
  // transition: {
  //   duration: 200, // 0.2s
  // },
  xaxis: {
    showgrid: false,
  },
  yaxis: {
    showgrid: true,
    rangemode: 'tozero',
    zeroline: true,
    zerolinecolor: '#ccc',
    automargin: true,
    gridcolor: '#ddd',
  },
  xaxis2: {
    showgrid: false,
  },
  yaxis2: {
    showgrid: true,
    rangemode: 'tozero',
    zeroline: true,
    zerolinecolor: '#ccc',
    automargin: true,
    // showgrid: false,
    gridcolor: '#f5f5f5',
  },
  legend: {
    // itemclick: 'toggleothers',
    // itemdoulbeclick: 'toggle',
    x: 1.08,
  },
};

export default layout;
