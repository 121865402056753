/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';


// const metricsPropTypes = PropTypes.shape({
//   name: PropTypes.string,
//   axis: PropTypes.string,
//   displayMode: PropTypes.string,
// });

// const dimensionsPropTypes = PropTypes.shape({
//   name: PropTypes.string,
//   axis: PropTypes.string,
//   displayMode: PropTypes.string,
//   maxDisplayed: PropTypes.number,
//   showOther: PropTypes.bool,
//   showTotal: PropTypes.bool,
// });

const attributeParameterPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.any,
});

const colorPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
});

const lineTypePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  dash: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
});

const attributeDefinitionPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['metric', 'dimension', 'date']).isRequired,
  defaults: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  useAsFilter: PropTypes.bool.isRequired,
  useAsMainFilter: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  filterRules: PropTypes.shape({
    allowMultiSelect: PropTypes.bool,
    minAllowed: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
    maxAllowed: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
    canBeEmpty: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({ from: PropTypes.bool, to: PropTypes.bool })]),
    defaultValue: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.oneOfType([
        PropTypes.shape({ from: PropTypes.number, to: PropTypes.number }),
        PropTypes.shape({ from: PropTypes.instanceOf(Date), to: PropTypes.instanceOf(Date) }),
      ]),
    ]),
  }),
});

const attributePropTypes = PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]));

const attributeDefaultProps = {
  name: undefined,
};

const comparisonDefinitionPropTypes = PropTypes.shape({
  name: PropTypes.string,
  displayName: PropTypes.string,
  shortName: PropTypes.string,
  defaults: PropTypes.shape({
    // comparisonDisplayType: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    lineType: PropTypes.string.isRequired,
  }),
  type: PropTypes.oneOf(['relative']).isRequired,
  timeDiff: PropTypes.shape({
    years: PropTypes.number.isRequired,
    months: PropTypes.number.isRequired,
    weeks: PropTypes.number.isRequired,
    days: PropTypes.number.isRequired,
  }).isRequired,
});

const comparisonPropTypes = PropTypes.shape({
  name: PropTypes.string,
});

const comparisonDefaultProps = {
  name: undefined,
  parameters: undefined,
};

// const attributePropTypes = PropTypes.oneOfType([metricPropTypes, dimensionPropTypes, comparisonPropTypes]);

const filterPropTypes = PropTypes.shape({
  name: PropTypes.string,
  values: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      from: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
      to: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
    })]),
});

const parameterGroupPropTypes = PropTypes.shape({
  type: PropTypes.string,
  displayName: PropTypes.string,
  icon: PropTypes.string,
  minAllowed: PropTypes.number.isRequired,
  maxAllowed: PropTypes.number,
  attributeTypes: PropTypes.arrayOf(PropTypes.string),
  parameters: PropTypes.arrayOf(attributeParameterPropTypes),
});

const chartTypePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  parameterGroups: PropTypes.arrayOf(parameterGroupPropTypes),
});

const chartParametersPropTypes = PropTypes.shape({
  type: PropTypes.string,
  validation: PropTypes.shape({
    valid: PropTypes.bool.isRequired,
    intent: PropTypes.string.isRequired,
  }),
  metrics: PropTypes.arrayOf(attributePropTypes).isRequired,
  dimensions: PropTypes.arrayOf(attributePropTypes).isRequired,
  filters: PropTypes.arrayOf(filterPropTypes).isRequired,
  comparisons: PropTypes.arrayOf(comparisonPropTypes),
});

const chartGroupPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  chartParameters: chartParametersPropTypes,
});

const chartDataPropTypes = PropTypes.arrayOf(PropTypes.shape({
  period: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]))).isRequired,
  totals: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]))).isRequired,
}));

const chartTracePropTypes = PropTypes.shape({
  x: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)])).isRequired,
  y: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)])).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  line: PropTypes.shape({
    color: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    dash: PropTypes.string.isRequired,
  }),
  mode: PropTypes.string,
  marker: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }),
});

const chartLayoutPropTypes = PropTypes.shape({

});

export {
  attributeParameterPropTypes,
  colorPropTypes,
  lineTypePropTypes,
  attributeDefinitionPropTypes,
  attributePropTypes,
  attributeDefaultProps,
  comparisonDefinitionPropTypes,
  comparisonDefaultProps,

  filterPropTypes,
  parameterGroupPropTypes,
  chartTypePropTypes,
  chartParametersPropTypes,
  chartGroupPropTypes,
  chartTracePropTypes,
  chartLayoutPropTypes,
  chartDataPropTypes,
  comparisonPropTypes,
};
