import titanActionTypes from './titanActionTypes';
import titanOverviewAPI from '../API/titanOverviewAPI';

const setGA_token = (token) => ({
  type: titanActionTypes.SET_GA_TOKEN,
  token,
});

const addDashboard = (token) => async (dispatch) => {
  await titanOverviewAPI.addDashboard(token);
  dispatch({
    type: titanActionTypes.ADD_DASHBOARD,
  });
};

const removeDashboard = (token, dashboardName) => async (dispatch) => {
  await titanOverviewAPI.removeDashboard(token, dashboardName);
  dispatch({
    type: titanActionTypes.REMOVE_DASHBOARD,
    dashboardName,
  });
};

const renameDashboard = (token, oldDashboardName, newDashboardName) => async (dispatch) => {
  await titanOverviewAPI.renameDashboard(token, oldDashboardName, newDashboardName);
  dispatch({
    type: titanActionTypes.RENAME_DASHBOARD,
    oldDashboardName,
    newDashboardName,
  });
};

export default {
  setGA_token,
  addDashboard,
  removeDashboard,
  renameDashboard,
};
