import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';

const TitanMultiSelect = (props) => {
  const onItemSelect = (newItem) => {
    if (props.selectedItems.includes(newItem)) {
      // if selected, unselect it
      props.updateSelectedItems(props.selectedItems.filter((item) => item !== newItem));
    } else {
      // if not selected, add it
      props.updateSelectedItems([...props.selectedItems, newItem]);
    }
  };

  const onItemRemove = (removedItem) => {
    props.updateSelectedItems(props.selectedItems.filter((item) => item !== removedItem));
  };

  const removeAllItems = () => {
    props.updateSelectedItems([]);
  };

  const clearButton = props.items.length > 0 ? <Button icon="cross" minimal onClick={removeAllItems} /> : undefined;

  const filterItems = (query, item, index, exactMatch) => {
    const normalizedItem = item.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedItem === normalizedQuery;
    }
    return normalizedItem.includes(normalizedQuery);
  };
  // eslint-disable-next-line no-unused-vars
  const itemRenderer = (item, { index, handleClick, query }) => {
    const itemIsActive = props.selectedItems.includes(item);
    return (
      <MenuItem
        style={{ margin: '4px 0' }}
        key={item}
        text={item}
        onClick={handleClick}
        active={itemIsActive}
        icon={itemIsActive ? 'tick' : 'blank'}
      />
    );
  };

  const tagRenderer = (item) => item;

  return (
    <MultiSelect
      // inputValueRenderer={item => item}
      items={props.items}
      selectedItems={props.selectedItems}
      itemPredicate={filterItems}
      itemRenderer={itemRenderer}
      onItemSelect={onItemSelect}
      tagRenderer={tagRenderer}
      tagInputProps={{ onRemove: onItemRemove, rightElement: clearButton }}
      fill="true"
    />
  );
};

TitanMultiSelect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  updateSelectedItems: PropTypes.func.isRequired,
};

TitanMultiSelect.defaultProps = {
  selectedItems: [],
};

export default TitanMultiSelect;
