import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import ReactCompareImage from 'react-compare-image';

const ScreenshotOvelay = ({ isOpen, handleClose, imgData, ...otherProps }) => {
  if (!imgData[0]) return null;
  return (
    <Dialog
      style={{
        width: otherProps.width,
        height: otherProps.height,
      }}
      isOpen={isOpen}
      onClose={handleClose}
      transitionDuration={-1}
      usePortal={navigator.userAgent.search('Firefox') === -1}
    >
      {imgData.length > 1 ? (
        <ReactCompareImage
          leftImage={imgData[0].src}
          // leftImageLabel={imgData[0].key}
          rightImage={imgData[1].src}
          // rightImageLabel={imgData[1].key}
          aspectRatio="wider"
          rightImageCss={{ objectPosition: 'top' }}
          leftImageCss={{ objectPosition: 'top' }}
          handle={<></>}
        />
      ) : (
        <img src={imgData[0].src} style={{ height: `${otherProps.height}px`, width: `${otherProps.width}px` }} alt="snapshot" />
      )}
    </Dialog>
  );
};

ScreenshotOvelay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  imgData: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
  })).isRequired,
};

export default ScreenshotOvelay;
