import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import { datasetsPulseFrequency_slow } from 'utils/constants';
import Datasets from './Datasets';
import datasetsActions from '../Actions/datasetsActions';

const DatasetsContainer = () => {
  const [datasetsPulseIsOn, setDatasetsPulseIsOn] = React.useState(true);

  const { token } = useSelector((store) => store.authReducer);
  const { datasets, datasetsAreLoading } = useSelector((store) => store.datasetsReducer);

  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  const datasetsPulse = async () => {
    stableDispatch(datasetsActions.updateDatasetsStatus(token));
  };

  // When mounting and if datasetsPulseIsOn is true: get params from localStorage & set datasetPulseID
  React.useEffect(() => {
    let datasetsPulseID;
    if (datasetsPulseIsOn) { // Set up recurent function to check status of datasets
      datasetsPulseID = setInterval(datasetsPulse, datasetsPulseFrequency_slow);
    } else {
      clearInterval(datasetsPulseID);
    }
    // clear datasetPulseID when unmounting
    return () => {
      clearInterval(datasetsPulseID);
    };
  }, [datasetsPulseIsOn]);

  const handleClickDatasetPulse = () => {
    setDatasetsPulseIsOn(!datasetsPulseIsOn);
  };

  const handleClickSelectAll = () => { };

  const handleClickSelect = () => { };

  const handleAddDataset = () => {
    // props.addDataset()
  };

  const handleDuplicateDataset = () => { };

  const handleDeleteDataset = (datasetName) => {
    stableDispatch(datasetsActions.removeDataset(datasetName, token));
  };

  return (
    <Datasets
      datasets={datasets}
      handleAddDataset={handleAddDataset}
      handleDuplicateDataset={handleDuplicateDataset}
      handleDeleteDataset={handleDeleteDataset}
      handleClickSelectAll={handleClickSelectAll}
      handleClickSelect={handleClickSelect}
      isLoading={datasetsAreLoading}
      datasetsPulseIsOn={datasetsPulseIsOn}
      handleClickDatasetPulse={handleClickDatasetPulse}
    />
  );
};

export default DatasetsContainer;
