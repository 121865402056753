import cloneDeep from 'lodash.clonedeep';
import { colorDefinitions, colorByDefinitions, lineTypesDefinitions, lineTypesByDefinitions, comparisonDefinitions } from 'AppModules/Titan/Definitions/definitions';
import attributeDefinitions from 'AppModules/Titan/Definitions/attributeDefinitions';
import chartBaseLayout from './chartBaseLayout';

const getColorValues = (data, dimensions) => {
  const colorAttributes = dimensions.filter((attr) => attr.dimensionDisplayType === 'colors');

  if (colorAttributes.length > 1) {
    throw Error(`there cannot be more than one Color attribute. 
                 The following attributeDefinitions are displayed by Color: ${colorAttributes.map((attr) => attr.displayName).join(', ')}`);
  } else if (colorAttributes.length === 1) {
    const colorBy_Attribute = colorAttributes[0];
    //  2.2 Get the values for this attribute
    const attributeIndex = data.headers.indexOf(colorBy_Attribute.name);
    const attributeValues = [...new Set(data.rows.map((row) => row[attributeIndex]))]; // using new Set to get unique values only

    const colors = attributeValues.map((value, i) => ({
      value: colorByDefinitions[i % colorByDefinitions.length],
      attributeValue: value,
    }));
    return { colorBy_Attribute, colors };
  } else {
    return { colorBy_Attribute: undefined, colors: [undefined] };
  }
};

const getLineTypeValues = (data, dimensions) => {
  const lineTypeAttributes = dimensions.filter((attr) => attr.dimensionDisplayType === 'line-types');

  if (lineTypeAttributes.length > 1) {
    throw Error(`there cannot be more than one Line Type attribute.
                 The following attributeDefinitions are displayed by Line Type: ${lineTypeAttributes.map((attr) => attr.displayName).join(', ')}`);
  } else if (lineTypeAttributes.length === 1) {
    const lineTypeBy_Attribute = lineTypeAttributes[0];
    //  2.2 Get the values for this attribute
    const attributeIndex = data.headers.indexOf(lineTypeBy_Attribute.name);
    const attributeValues = [...new Set(data.rows.map((row) => row[attributeIndex]))]; // using new Set to get unique values only

    const lineTypes = attributeValues.map((value, i) => ({
      width: lineTypesByDefinitions[i % lineTypesByDefinitions.length].width,
      dash: lineTypesByDefinitions[i % lineTypesByDefinitions.length].dash,
      attributeValue: value,
    }));
    return { lineTypeBy_Attribute, lineTypes };
  } else {
    return { lineTypeBy_Attribute: undefined, lineTypes: [undefined] };
  }
};

const getDimensionsOfRow = (row, dimensionsMap) => {
  const newRow = [];
  for (let i = 0; i < row.length; i += 1) {
    if (dimensionsMap[i]) newRow.push(row[i]);
  }
  return newRow;
};

const summarizeData = (data) => {
  // /!\ data must be organised by dimensions first and metrics after

  // Find all the combinations of dimensions that exist in the data
  const keyIsADimension = data.headers.map((header) => attributeDefinitions.find((attr) => attr.name === header).type !== 'metric');
  const nbDimensions = keyIsADimension.filter((is) => is).length;
  const nbMetrics = keyIsADimension.filter((is) => !is).length;

  const dimensionsData = data.rows.map((row) => getDimensionsOfRow(row, keyIsADimension));

  // Find unique combinations
  const uniqueDimensionsCombinations = [];
  for (let i = 0; i < dimensionsData.length; i += 1) {
    const currentValue = dimensionsData[i];
    const alreadyIn = uniqueDimensionsCombinations.filter((row) => JSON.stringify(row) === JSON.stringify(currentValue)).length > 0;
    if (!alreadyIn) uniqueDimensionsCombinations.push(currentValue);
  }
  // console.log(uniqueDimensionsCombinations);

  // Aggregate the metrics
  const summarizedData = uniqueDimensionsCombinations.map((uniqueRow) => {
    let newRow = uniqueRow; // ["SWO", "TICK_SD"]
    // add entries for the metrics
    newRow = [...newRow, ...new Array(nbMetrics).fill(0)]; // ["SWO", "TICK_SD", 0, 0]
    // console.log(newRow);

    const relevantRows = data.rows.filter((dataRow) => {
      const dataRowDimensions = getDimensionsOfRow(dataRow, keyIsADimension);
      return JSON.stringify(dataRowDimensions) === JSON.stringify(uniqueRow);
    });
    // console.log(relevantRows);

    const aggregatedRow = relevantRows.reduce((result, relevantRow) => {
      for (let metricId = 0; metricId < nbMetrics; metricId += 1) {
        // eslint-disable-next-line no-param-reassign
        result[nbDimensions + metricId] += relevantRow[nbDimensions + metricId];
      }
      return result;
    }, newRow);

    return aggregatedRow;
  });

  return summarizedData;
};

const aggregateData = (data, dimensionToAggregate) => {
  const dataCopy = cloneDeep(data);
  const dimensionAttributeId = data.headers.indexOf(dimensionToAggregate);

  // Replace value of dimensionToAggregate to "All"
  const newDataRows = dataCopy.rows.map((row) => {
    const newRow = row;
    newRow[dimensionAttributeId] = 'All';
    return newRow;
  });
  // console.log(newDataRows);

  const summarizedRows = summarizeData({ ...dataCopy, rows: newDataRows });
  // console.log(summarizedRows);

  return { ...dataCopy, rows: summarizedRows };
};

const getColorTraces = (data, chartParameters, metricAttribute, nameArray, periodId) => {
  const { colorBy_Attribute, colors } = getColorValues(data, chartParameters.dimensions);
  // Get color definition for this metricAttribute or comparison period if periodId > 0
  const currentColor = periodId === 0
    ? colorDefinitions.flat().find((el) => el.name === metricAttribute.color).value
    : colorDefinitions.flat().find((el) => el.name === chartParameters.comparisons[periodId - 1].color).value;
  const currentLineType = periodId === 0
    ? lineTypesDefinitions.flat().find((el) => el.name === metricAttribute.lineType)
    : lineTypesDefinitions.flat().find((el) => el.name === chartParameters.comparisons[periodId - 1].lineType);
  const { width, dash } = currentLineType;

  // Name should include the metric name only if there is more than 1 metric
  const name = chartParameters.metrics.length > 1 ? [...nameArray, attributeDefinitions.find((attr) => attr.name === metricAttribute.name).displayName] : nameArray;

  if (!colorBy_Attribute || metricAttribute.excludeFromGroupByColor) {
    let aggregatedData = null;
    if (colorBy_Attribute && metricAttribute.excludeFromGroupByColor) aggregatedData = aggregateData(data, colorBy_Attribute.name); // need to aggregate the data

    return [
      {
        name,
        color: currentColor,
        lineType: { width, dash },
        data: aggregatedData || data,
      },
    ];
  }
  return colors.map((color) => {
    const colorAttributeId = data.headers.indexOf(colorBy_Attribute.name);
    const filteredData = {
      ...data,
      rows: data.rows.filter((row) => row[colorAttributeId] === color.attributeValue),
    };

    return ({
      name: [...name, color.attributeValue],
      color: color.value,
      lineType: { width, dash },
      data: filteredData,
    });
  });
};

const getLineTypeTraces = (data, chartParameters, metricAttribute, nameArray, color, periodId) => {
  const { lineTypeBy_Attribute, lineTypes } = getLineTypeValues(data, chartParameters.dimensions);
  // Get lineType definition for this metricAttribute or comparison period if periodId > 0
  const currentLineType = periodId === 0
    ? lineTypesDefinitions.flat().find((el) => el.name === metricAttribute.lineType)
    : lineTypesDefinitions.flat().find((el) => el.name === chartParameters.comparisons[periodId - 1].lineType);
  const { width, dash } = currentLineType;

  if (!lineTypeBy_Attribute || metricAttribute.excludeFromGroupByLineType) {
    let aggregatedData = null;
    if (lineTypeBy_Attribute && metricAttribute.excludeFromGroupByLineType) aggregatedData = aggregateData(data, lineTypeBy_Attribute.name); // need to aggregate the data

    return [
      {
        name: nameArray,
        color,
        lineType: { width, dash },
        data: aggregatedData || data,
        metricAttribute,
      },
    ];
  }
  return lineTypes.map((lineType) => {
    const lineTypeAttributeId = data.headers.indexOf(lineTypeBy_Attribute.name);
    const filteredData = {
      ...data,
      rows: data.rows.filter((row) => row[lineTypeAttributeId] === lineType.attributeValue),
    };
    return ({
      name: [...nameArray, lineType.attributeValue],
      color,
      lineType: { width: lineType.width, dash: lineType.dash },
      data: filteredData,
      metricAttribute,
    });
  });
};

const getAllMetricTraces = (periodData, chartParameters, periodName, periodId) => {
  const tracesPerMetric = chartParameters.metrics.map((metricAttribute) => {
    const periodDisplayName = periodId > 0 ? [comparisonDefinitions.find((comp) => comp.name === periodName).shortName] : [];
    const colorTraces = getColorTraces(periodData, chartParameters, metricAttribute, periodDisplayName, periodId);
    const colorAndLineTypeTraces = colorTraces.map((colorTrace) => {
      const { data, name, color } = colorTrace;
      const currentColorTraces = getLineTypeTraces(data, chartParameters, metricAttribute, name, color, periodId);
      // console.log(currentColorTraces);
      return currentColorTraces;
    });
    const currentMetricTraces = colorAndLineTypeTraces.flat();
    // console.log(currentMetricTraces);
    return currentMetricTraces;
  });
  const allMetricsTraces = tracesPerMetric.flat();
  // console.log(allMetricsTraces);
  return allMetricsTraces;
};

const getTraceDefinitions = (currentTrace) => {
  const currentTraceCopy = cloneDeep(currentTrace);
  const { metricDisplayType } = currentTraceCopy.metricAttribute;
  let traceType;
  let traceMode;
  let barMode;
  let barNorm = '';
  switch (metricDisplayType) {
    case 'lines':
      traceType = 'scatter';
      traceMode = 'lines';
      break;
    case 'bars':
      traceType = 'bar';
      barMode = 'group';
      break;
    case 'stacked-bars':
      traceType = 'bar';
      barMode = 'stack';
      break;
    case 'full-stacked-bars':
      traceType = 'bar';
      barMode = 'stack';
      barNorm = 'percent';
      break;
    default:
      traceType = 'scatter';
      traceMode = 'lines';
      break;
  }

  const traceLine = traceMode === 'lines' ? {
    color: currentTraceCopy.color,
    width: currentTraceCopy.lineType.width,
    dash: currentTraceCopy.lineType.dash,
  } : null;
  const traceMarker = traceType === 'bar' ? {
    color: currentTraceCopy.color,
  } : null;

  return {
    traceLine,
    traceMarker,
    traceType,
    traceMode,
    barMode,
    barNorm,
  };
};

const assignTraceDefinition = (trace, chartParameters, dimensionAttribute, periodId) => {
  const traceDefinition = getTraceDefinitions(trace);

  const metricAttributeId = trace.data.headers.indexOf(trace.metricAttribute.name);
  const dimensionAttributeId = trace.data.headers.indexOf(dimensionAttribute.name);

  const finalTrace = {
    x: chartParameters.type === 'lines-bars' ? trace.data.rows.map((row) => row[dimensionAttributeId]) : trace.data.rows.map((row) => row[metricAttributeId]),
    y: chartParameters.type === 'lines-bars' ? trace.data.rows.map((row) => row[metricAttributeId]) : trace.data.rows.map((row) => row[dimensionAttributeId]),
    orientation: chartParameters.type === 'lines-bars' ? 'v' : 'h',
    name: trace.name.join(' - '),
    type: traceDefinition.traceType,
    connectgaps: true,
    metricAttribute: trace.metricAttribute, // Needed for further processing
    traceDefinition, // Needed for further processing
  };


  // Add line properties if applicable
  if (traceDefinition.traceLine) {
    finalTrace.line = traceDefinition.traceLine;
    finalTrace.mode = traceDefinition.traceMode;
  } else if (traceDefinition.traceMarker) {
    finalTrace.marker = traceDefinition.traceMarker;
  }

  // Add axis for the trace
  if (chartParameters.type === 'lines-bars') {
    finalTrace.yaxis = trace.metricAttribute.axisType === 'secondary' ? 'y2' : 'y';
  } else if (chartParameters.type === 'vertical-lines-bars') {
    finalTrace.xaxis = trace.metricAttribute.axisType === 'secondary' ? 'x2' : 'x';
  } else {
    throw Error('Chart Type not supported by this chart Engine');
  }

  if (chartParameters.comparisons.length > 0) {
    finalTrace.legendgroup = `group${periodId === 0 ? '' : periodId}`;
  }

  return finalTrace;
};

const defineLayout = (layout, traces, chartParameters) => {
  const layoutCopy = cloneDeep(layout);

  if (chartParameters.type === 'lines-bars') {
    for (const currentTrace of traces) {
      if (currentTrace.metricAttribute.axisType === 'primary') {
        layoutCopy.yaxis = {
          ...layoutCopy.yaxis,
          title: attributeDefinitions.find((attr) => attr.name === currentTrace.metricAttribute.name).displayName,
        };
      } else if (currentTrace.metricAttribute.axisType === 'secondary') {
        layoutCopy.yaxis = {
          ...layoutCopy.yaxis,
        };
        layoutCopy.yaxis2 = {
          ...layoutCopy.yaxis2,
          title: attributeDefinitions.find((attr) => attr.name === currentTrace.metricAttribute.name).displayName,
          side: 'right',
          overlaying: 'y',
        };
      }
      // Add barmode & barnorm if necessary
      const { traceType, barMode, barNorm } = currentTrace.traceDefinition;
      if (traceType === 'bar') {
        layoutCopy.barmode = barMode;
        layoutCopy.barnorm = barNorm;
        // layoutCopy.bargap = 0.45;
        // layoutCopy.bargroupgap = 0.05;
      }
    }
  } else if (chartParameters.type === 'vertical-lines-bars') {
    // Switch over the axes in the layout
    const { xaxis, xaxis2, yaxis, yaxis2 } = layoutCopy;
    layoutCopy.xaxis = yaxis;
    layoutCopy.xaxis2 = yaxis2;
    layoutCopy.yaxis = xaxis;
    layoutCopy.yaxis2 = xaxis2;

    for (const currentTrace of traces) {
      if (currentTrace.metricAttribute.axisType === 'primary') {
        layoutCopy.xaxis = {
          ...layoutCopy.xaxis,
          title: attributeDefinitions.find((attr) => attr.name === currentTrace.metricAttribute.name).displayName,
        };
      } else if (currentTrace.metricAttribute.axisType === 'secondary') {
        layoutCopy.xaxis2 = {
          ...layoutCopy.xaxis2,
          title: attributeDefinitions.find((attr) => attr.name === currentTrace.metricAttribute.name).displayName,
          side: 'top',
          overlaying: 'x',
        };
      }
      // Add barmode & barnorm if necessary
      const { traceType, barMode, barNorm } = currentTrace.traceDefinition;
      if (traceType === 'bar') {
        layoutCopy.barmode = barMode;
        layoutCopy.barnorm = barNorm;
        // layoutCopy.bargap = 0.45;
        // layoutCopy.bargroupgap = 0.05;
      }
    }
  } else {
    throw Error('Chart Type not supported by this chart Engine');
  }

  return layoutCopy;
};

const buildTraces = (chartData, chartParameters) => {
  // Get the dimension attribute
  const dimensionAttribute = chartParameters.dimensions.find((dim) => dim.dimensionDisplayType === 'axis');
  const layout = cloneDeep(chartBaseLayout);

  // 1. Loop over the time period
  const traces = chartData.map((periodData, periodId) => {
    const periodName = periodData.period;
    // console.log(periodData);
    // console.log(chartParameters);

    // Get all the traces: 1 per metric, per color grouping and per lineType grouping
    const currentPeriodTraces = getAllMetricTraces(periodData, chartParameters, periodName, periodId);
    // console.log(currentPeriodTraces);

    // Add in trace lines and bars definitions, axes, legend group
    const tracesWithDefinitions = currentPeriodTraces.map((currentTrace) => assignTraceDefinition(currentTrace, chartParameters, dimensionAttribute, periodId));

    return tracesWithDefinitions.reverse();
  });

  const chartTraces = traces.flat();
  const chartLayout = defineLayout(layout, chartTraces, chartParameters);

  return {
    chartTraces,
    chartLayout,
  };
};

export default buildTraces;
