import { Toaster, Intent } from '@blueprintjs/core';
import cloneDeep from 'lodash.clonedeep';
import dataAPI from '../APIs/dataAPI';
import ETLdefinitions from '../Definitions/ETLdefinitions';
import datasetsActionTypes from './datasetsActionTypes';

const myToaster = Toaster.create();

/* *********************
 *****   HELPERS   *****
********************* */

const reformatDates = (dataset) => {
  const newDataset = cloneDeep(dataset);
  newDataset.creationDate = new Date(dataset.creationDate);
  newDataset.lastRunDate = new Date(dataset.lastRunDate);
  for (const [i, file] of dataset.files.entries()) {
    newDataset.files[i].uploadDate = new Date(file.uploadDate);
    newDataset.files[i].lastModifiedDate = new Date(file.lastModifiedDate);
  }
  return newDataset;
};


/* ***********************
 *****   FUNCTIONS   *****
*********************** */

const loadDatasets = (token) => async (dispatch) => {
  dispatch({ type: datasetsActionTypes.DATASETS_IS_LOADING, datasetsAreLoading: true });
  const datasets = await dataAPI.getDatasetsRegister(token);
  dispatch({
    type: datasetsActionTypes.UPDATE_DATASETS,
    datasets: datasets.map((dataset) => reformatDates(dataset)),
  });
  dispatch({ type: datasetsActionTypes.DATASETS_IS_LOADING, datasetsAreLoading: false });
};

const updateDatasetsStatus = (token) => async (dispatch) => {
  const datasets = await dataAPI.getDatasetsRegister(token);
  // keep only status-related info
  const datasetStatusAttributes = ['datasetSize', 'runProgress', 'runStatus', 'status', 'lastRunDate', 'error'];
  const datasetsStatus = datasets.map((el) => {
    const output = { name: el.name, status: {} }; // adding name as key of datasets to update in reducer
    datasetStatusAttributes.forEach((attr) => { output.status[attr] = el[attr]; });
    output.status.lastRunDate = new Date(output.status.lastRunDate);
    return output;
  });
  dispatch({
    type: datasetsActionTypes.UPDATE_DATASETS_STATUS,
    datasetsStatus,
  });
};

const removeDataset = (datasetName, token) => async (dispatch) => {
  await dataAPI.removeDataset(datasetName, token);
  dispatch({
    type: datasetsActionTypes.REMOVE_DATASET,
    datasetName,
  });
};

const addDataset = (datasetName, token) => async (dispatch) => {
  await dataAPI.addDataset(datasetName, token);
  dispatch({
    type: datasetsActionTypes.ADD_DATASET,
    datasetName,
  });
};

const changeDatasetParameter = (datasetName, parameterName, parameterValue) => ({
  type: datasetsActionTypes.CHANGE_DATASET_PARAMETER,
  datasetName,
  parameterName,
  parameterValue,
});

const changeDatasetETLParameter = (datasetName, ETLschema, category, subcategory, parameterName, parameterValue) => ({
  type: datasetsActionTypes.CHANGE_DATASET_ETL_PARAMETER,
  datasetName,
  ETLschema,
  category,
  subcategory,
  parameterName,
  parameterValue,
});

// for each file in fileList, use inputFiles' parameters if available, otherwise create default parameter set
const rebuildDatasetFileList = (fileList, dataset) => {
  const newInputFiles = fileList.map((file) => {
  // if this file is already present in the inputFiles, for each field, use inputFiles' parameters if available, otherwise create default parameter set
    const currentInputFile = dataset.files.find((inputFile) => inputFile.name === file.name);
    // re-use existing parameters for each 'fields' property
    const fields = file.fields.map((field) => {
      const currentInputFileField = currentInputFile && currentInputFile.fields.find((inputFileField) => inputFileField.name === field.name);
      return currentInputFileField || { ...field, selected: true, mappedTo: field.name };
    });

    const defaultInputFile = {
      name: file.name,
      size: file.size,
      type: file.type,
      fields,
      lastModifiedDate: file.lastModifiedDate,
      uploadDate: file.uploadDate,
      role: ETLdefinitions.find((schema) => schema.name === dataset.ETLschema).fileRoles[0].name,
      selected: true,
    };
    return currentInputFile || defaultInputFile;
  });
  return newInputFiles;
};

const updateDatasetInputFileList = (dataset, fileList, token) => async (dispatch) => {
  // for each file in fileList, use inputFiles' parameters if available, otherwise create default parameter set
  const newInputFiles = rebuildDatasetFileList(fileList, dataset);

  await dataAPI.updateDatasetInputFileList(dataset.name, newInputFiles, token);

  dispatch({
    type: datasetsActionTypes.UPDATE_DATASET_INPUT_FILE_LIST,
    datasetName: dataset.name,
    newInputFiles,
  });
};

const updateInputFileParameter = (datasetName, fileName, parameterName, parameterValue) => ({
  type: datasetsActionTypes.UPDATE_DATASET_FILE_INPUT_PARAMETER,
  datasetName,
  fileName,
  parameterName,
  parameterValue,
});

const bulkUpdateInputFileParameter = (datasetName, fileNames, parameterName, parameterValue) => ({
  type: datasetsActionTypes.BULK_UPDATE_DATASET_FILE_INPUT_PARAMETER,
  datasetName,
  fileNames,
  parameterName,
  parameterValue,
});

const updateFieldParameter = (datasetName, fileName, fieldName, parameterName, parameterValue) => ({
  type: datasetsActionTypes.UPDATE_FIELD_PARAMETER,
  datasetName,
  fileName,
  fieldName,
  parameterName,
  parameterValue,
});

const bulkUpdateFieldParameter = (datasetName, fileName, fieldNames, parameterName, parameterValue) => ({
  type: datasetsActionTypes.BULK_UPDATE_FIELD_PARAMETER,
  datasetName,
  fileName,
  fieldNames,
  parameterName,
  parameterValue,
});

const saveDatasetConfig = (dataset, token) => async (dispatch) => {
  dispatch({ type: datasetsActionTypes.DATASET_CONFIG_IS_SAVING, datasetConfigIsSaving: true });
  let output;
  try {
    await dataAPI.saveDatasetConfig(dataset, token);
    myToaster.show({ message: 'Dataset configuration successfully saved to cloud', icon: 'tick', intent: Intent.SUCCESS });
    output = { sucess: true };
  } catch (err) {
    myToaster.show({ message: `Error saving dataset configuration: ${err}`, icon: 'error', intent: Intent.DANGER });
    output = { err };
  }
  dispatch({ type: datasetsActionTypes.DATASET_CONFIG_IS_SAVING, datasetConfigIsSaving: false });
  return output;
};

const loadDatasetConfig = (datasetName, token) => async (dispatch) => {
  dispatch({ type: datasetsActionTypes.DATASET_CONFIG_IS_LOADING, datasetConfigIsLoading: true });
  try {
    const dataset = await dataAPI.loadDatasetConfig(datasetName, token);
    // convert dates from strings to Date objects
    const reformatedDataset = reformatDates(dataset);
    dispatch({
      type: datasetsActionTypes.LOAD_DATASET_PARAMETERS,
      datasetName,
      dataset: reformatedDataset,
    });

    myToaster.show({ message: 'Dataset configuration successfully loaded from cloud', icon: 'tick', intent: Intent.SUCCESS });
  } catch (err) {
    myToaster.show({ message: `Error loading dataset configuration: ${err}`, icon: 'error', intent: Intent.DANGER });
  }
  dispatch({ type: datasetsActionTypes.DATASET_CONFIG_IS_LOADING, datasetConfigIsLoading: false });
};

const createRun = (dataset, token) => async (dispatch) => {
  const saveCongifRes = await dispatch(saveDatasetConfig(dataset, token));
  if (saveCongifRes && saveCongifRes.err) {
    myToaster.show({ message: 'Error saving dataset configuration. Run cancelled', intent: Intent.DANGER });
  } else {
    setTimeout(() => { myToaster.show({ message: 'Starting Run...', icon: 'cog', intent: Intent.PRIMARY }); }, 500);
    const createRunRes = await dataAPI.createRun(dataset, token);
    if (createRunRes.err) {
      myToaster.show({ message: `Error creating Run: ${createRunRes.err}`, icon: 'error', intent: Intent.DANGER });
    }
  }
};

export default {
  loadDatasets,
  updateDatasetsStatus,
  removeDataset,
  addDataset,
  changeDatasetParameter,
  changeDatasetETLParameter,
  updateDatasetInputFileList,
  updateInputFileParameter,
  bulkUpdateInputFileParameter,
  updateFieldParameter,
  bulkUpdateFieldParameter,
  saveDatasetConfig,
  loadDatasetConfig,
  createRun,
};
