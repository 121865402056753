/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import localforage from 'localforage';
import { screenshotPropTypes } from 'AppModules/Ganymede/Definitions/ganymedePropTypes';
import { Intent, Toaster } from '@blueprintjs/core';
import ganymedeActions from 'AppModules/Ganymede/Actions/ganymedeActions';
import ScreenshotOvelay from '../ScreenshotOvelay/ScreenshotOvelay';
import ExplorerScreenshotsList from './ExplorerScreenshotsList';

const imgZoomOutFactor = 0.8;
const infoToaster = Toaster.create();
const compareToaster = Toaster.create();

const ExplorerScreenshotsListContainer = (props) => {
  const [orderedScreenshots, setOrderedScreenshots] = React.useState(props.screenshots);
  const [selectedScreenshotKeys, setSelectedScreenshotKeys] = React.useState([]);
  const [deletingLocalCopyKeys, setDeletingLocalCopyKeys] = React.useState([]);
  const [overlayImgData, setOverlayImgData] = React.useState([]);
  const [screenshotOverlayIsOpen, setScreenshotOverlayIsOpen] = React.useState(false);
  const [aScreenshotIsLoading, setAScreenshotIsLoading] = React.useState(false);
  const [localNbOfScreenshots, setLocalNbOfScreenshots] = React.useState(0);
  const [sorting, setSorting] = React.useState({ attribute: 'timeStamp', ascending: false, headerTitle: 'Date' });

  const updateNbOfLocalScreenshotKeys = async (isSubscribed = true) => {
    const allKeys = await localforage.keys();
    const nb = allKeys.filter((key) => key.includes('ganymedeScreenshotData_'));
    if (isSubscribed) {
      setLocalNbOfScreenshots(nb.length);
    }
  };

  // When mounting, count the number of screenshots saved locally
  React.useEffect(() => {
    let isSubscribed = true;
    updateNbOfLocalScreenshotKeys(isSubscribed);
    return () => { isSubscribed = false; };
  }, []);

  const sortScreenshots = (screenshotItems) => {
    const newOrderedScreenshots = [...screenshotItems].sort((a, b) => {
      if (sorting.ascending) {
        if (a[sorting.attribute] > b[sorting.attribute]) return 1;
        if (a[sorting.attribute] < b[sorting.attribute]) return -1;
        return 0;
      }
      if (a[sorting.attribute] < b[sorting.attribute]) return 1;
      if (a[sorting.attribute] > b[sorting.attribute]) return -1;
      return 0;
    });
    return (newOrderedScreenshots);
  };

  // When screenshots change, deselect the screenshots that are no longer visible, and order them
  React.useEffect(() => {
    setSelectedScreenshotKeys(props.screenshots.map((el) => el.key).filter((el) => selectedScreenshotKeys.includes(el)));
    const initiallyOrderedScreenshots = sortScreenshots(props.screenshots);
    setOrderedScreenshots(initiallyOrderedScreenshots);
  }, [props.screenshots]);

  // When sorting changes, order screenshots
  React.useEffect(() => {
    const currentOrderedScreenshots = orderedScreenshots;
    const newOrderedScreenshots = sortScreenshots(currentOrderedScreenshots);
    setOrderedScreenshots(newOrderedScreenshots);
  }, [sorting]);

  const handleSelectScreenshot = (key, selected) => {
    if (selected) {
      setSelectedScreenshotKeys([...selectedScreenshotKeys, key]);
    } else {
      setSelectedScreenshotKeys(selectedScreenshotKeys.filter((selectedKey) => selectedKey !== key));
    }
  };

  const handleSelectAllScreenshots = () => {
    // if all are selected, select none
    if (selectedScreenshotKeys.length === props.screenshots.length) {
      setSelectedScreenshotKeys([]);
    } else {
      // otherwise select all
      setSelectedScreenshotKeys(props.screenshots.map((screenshot) => screenshot.key));
    }
  };

  const handleCloseScreenshotOverlay = () => {
    setScreenshotOverlayIsOpen(false);
  };

  const handleSortItems = (headerTitle) => {
    const titleToAttribute = {
      Park: 'park',
      'Page Type': 'pageType',
      Date: 'timeStamp',
      Version: 'version',
    };
    // don't sort if user clicked on another column header (e.g. Time or Status)
    if (!Object.keys(titleToAttribute).includes(headerTitle)) return;
    const sortingAttribute = titleToAttribute[headerTitle];

    if (sortingAttribute === sorting.attribute) {
      // if already sorted with this attribute, reverse the order
      setSorting({ attribute: sortingAttribute, ascending: !sorting.ascending, headerTitle });
    } else if (sortingAttribute === 'timeStamp') {
      // if sorting by date, use descending order
      setSorting({ attribute: sortingAttribute, ascending: false, headerTitle });
    } else {
      // otherwise use ascending order
      setSorting({ attribute: sortingAttribute, ascending: true, headerTitle });
    }
  };

  const tableHeaders = [
    {
      title: 'Park',
      width: 20,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Page Type',
      width: 20,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Date',
      width: 15,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Time',
      width: 15,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Version',
      width: 10,
      alignHeader: 'center',
      alignItems: 'center',
    },
    {
      title: 'Status',
      width: 15,
      alignHeader: 'center',
      alignItems: 'center',
    },
  ];

  const displayScreenshot = (scrImgData) => {
    if (scrImgData) {
      setOverlayImgData([scrImgData]);
      setScreenshotOverlayIsOpen(true);
    } else {
      infoToaster.show({ message: 'Please download the screenshot first', intent: Intent.WARNING, icon: 'warning-sign' });
    }
  };

  const handleCompareScreenshots = async () => {
    // Ignore the screenshots that are selected but not currently displayed
    const selected_visible_ScreenshotKeys = [];
    for (const key of selectedScreenshotKeys) {
      if (props.screenshots.map((el) => el.key).includes(key)) selected_visible_ScreenshotKeys.push(key);
    }

    if (selected_visible_ScreenshotKeys.length !== 2) {
      compareToaster.show({ message: 'You must have 2 screenshots selected', intent: Intent.WARNING, icon: 'warning-sign' });
    } else {
      // get screenshots from localforage
      const compareImgsDataPromises = selected_visible_ScreenshotKeys.map((key) => localforage.getItem(`ganymedeScreenshotData_${key}`));
      const compareImgsData = await Promise.all(compareImgsDataPromises);
      if (!compareImgsData[0] || !compareImgsData[1]) {
        infoToaster.show({ message: 'Please download the screenshot first', intent: Intent.WARNING, icon: 'warning-sign' });
      } else {
        setOverlayImgData(compareImgsData);
        setScreenshotOverlayIsOpen(true);
      }
    }
  };

  const handleRemoveLocalScreenshots = async () => {
    setDeletingLocalCopyKeys(selectedScreenshotKeys);
    const removeItemPromises = [];
    for (const key of selectedScreenshotKeys) {
      removeItemPromises.push(localforage.removeItem(`ganymedeScreenshotData_${key}`));
    }
    await Promise.all(removeItemPromises);
    updateNbOfLocalScreenshotKeys();
    setDeletingLocalCopyKeys([]);
  };

  const removeAllLocalScreenshotsButton = async () => {
    setDeletingLocalCopyKeys(props.screenshots.map((el) => el.key)); // marking all displayed screenshots to make sure they all update if necessary
    const allKeys = await localforage.keys();
    const screenshotKeys = allKeys.filter((key) => key.includes('ganymedeScreenshotData_'));
    // remove keys
    const removeAllKeysPromises = [];
    for (const key of screenshotKeys) {
      removeAllKeysPromises.push(localforage.removeItem(key));
    }
    await Promise.all(removeAllKeysPromises);
    updateNbOfLocalScreenshotKeys();
    setDeletingLocalCopyKeys([]);
  };

  return (
    <>
      <ExplorerScreenshotsList
        tableHeaders={tableHeaders}
        sorting={sorting}
        handleSortItems={handleSortItems}
        screenshots={orderedScreenshots}
        handleSelectScreenshot={handleSelectScreenshot}
        handleSelectAllScreenshots={handleSelectAllScreenshots}
        selectedScreenshotKeys={selectedScreenshotKeys}
        deletingLocalCopyKeys={deletingLocalCopyKeys}
        aScreenshotIsLoading={aScreenshotIsLoading}
        setAScreenshotIsLoading={setAScreenshotIsLoading}
        handleDisplayScreenshot={displayScreenshot}
        handleCompareScreenshots={handleCompareScreenshots}
        handleRemoveLocalScreenshots={handleRemoveLocalScreenshots}
        removeAllLocalScreenshotsButton={removeAllLocalScreenshotsButton}
        totalNbOfScreenshots={props.totalNbOfScreenshots}
        localNbOfScreenshots={localNbOfScreenshots}
        updateNbOfLocalScreenshotKeys={updateNbOfLocalScreenshotKeys}
        screenshotsListIsUpdating={props.screenshotsListIsUpdating}
      />
      <ScreenshotOvelay
        isOpen={screenshotOverlayIsOpen}
        handleClose={handleCloseScreenshotOverlay}
        imgData={overlayImgData}
        width={overlayImgData[0] ? overlayImgData[0].width * imgZoomOutFactor : 0}
        height={overlayImgData[0] ? overlayImgData[0].height * imgZoomOutFactor : 0}
      />
    </>
  );
};

ExplorerScreenshotsListContainer.propTypes = {
  screenshots: PropTypes.arrayOf(screenshotPropTypes).isRequired,
  totalNbOfScreenshots: PropTypes.number.isRequired,
  screenshotsListIsUpdating: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
  totalNbOfScreenshots: store.ganymedeReducer.totalNbOfScreenshots,
  screenshotsListIsUpdating: store.ganymedeReducer.screenshotsListIsUpdating,
});

const mapDispatchToProps = (dispatch) => ({
  downloadScreenshot: (key, onDownloadProgress, token) => dispatch(ganymedeActions.downloadScreenshot(key, onDownloadProgress, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExplorerScreenshotsListContainer);
