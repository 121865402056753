import React from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import datasetsActions from 'AppModules/Data/Actions/datasetsActions';
import { datasetPropTypes } from 'AppModules/Data/Definitions/dataPropTypes';
import StandardPromoETLParameters from './StandardPromoETLParameters';

const StandardPromoETLParametersContainer = (props) => {
  const dispatch = useDispatch();
  const stableDispatch = React.useCallback(dispatch, []);

  const handleChangeETLparameter = (category, subcategory, parameterName, parameterValue) => {
    stableDispatch(datasetsActions.changeDatasetETLParameter(props.dataset.name, props.dataset.ETLschema, category, subcategory, parameterName, parameterValue));
  };

  return (
    <StandardPromoETLParameters
      dataset={props.dataset}
      handleChangeETLparameter={handleChangeETLparameter}
    />
  );
};

StandardPromoETLParametersContainer.propTypes = {
  dataset: datasetPropTypes.isRequired,
};

export default StandardPromoETLParametersContainer;
