import React from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import { appName } from '../../utils/appDefinitions';
import './NavBar.css';

const NavBarContainer = (props) => {
  const stage = process.env.REACT_APP_STAGE || 'dev';
  return (
    <NavBar
      appName={appName[stage]}
      isDarkMode={props.isDarkMode}
      handleToggleDarkMode={props.handleToggleDarkMode}
    />
  );
};

NavBarContainer.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  handleToggleDarkMode: PropTypes.func.isRequired,
};

export default NavBarContainer;
