import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import NavBarContainer from '../NavBar/NavBarContainer';
import SideBar from '../SideBar/SideBar';
import { sideBarItems } from '../../utils/appDefinitions';
import Home from '../Home/Home';
// imoprt components as defined in appDefinitions
import DataPage from '../../AppModules/Data/DataPage';
import GanymedeContainer from '../../AppModules/Ganymede/GanymedeContainer';
import TitanContainer from '../../AppModules/Titan/TitanContainer';
import './MainContainer.css';
import userActions from '../../reducers/userActions';

const MainContainer = (props) => {
  const handleToggleDarkMode = () => {
    props.toggleDarkMode();
  };

  const style = {
    margin: '50px 0 0 67px',
    padding: '5px',
  };

  return (
    <div style={style}>
      <NavBarContainer
        isDarkMode={props.isDarkMode}
        handleToggleDarkMode={handleToggleDarkMode}
      />
      <SideBar items={sideBarItems} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/Data" component={DataPage} />
        <Route path="/Ganymede" component={GanymedeContainer} />
        <Route path="/Titan" component={TitanContainer} />
        ;
      </Switch>
    </div>
  );
};

MainContainer.propTypes = {
  isDarkMode: PropTypes.bool,
  toggleDarkMode: PropTypes.func.isRequired,
};

MainContainer.defaultProps = {
  isDarkMode: false,
};

const mapStateToProps = (store) => ({
  isDarkMode: store.userReducer.userSettings.isDarkMode,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDarkMode: () => dispatch(userActions.toggleDarkMode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
