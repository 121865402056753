import { colorDefinitions, lineTypesDefinitions } from './definitions';
import customIcons from '../../../Components/CustomIcons/customIcons';

const parameterTypes = [
  {
    name: 'metricDisplayType',
    displayName: 'Display Type',
    type: 'list',
    defaultValue: 'lines',
    values: [
      {
        name: 'lines',
        displayName: 'Lines',
        icon: 'timeline-line-chart',
      },
      {
        name: 'bars',
        displayName: 'Bars',
        icon: 'timeline-bar-chart',
      },
      {
        name: 'stacked-bars',
        displayName: 'Stacked Bars',
        icon: 'stacked-chart',
      },
      {
        name: 'full-stacked-bars',
        displayName: 'Full Stacked Bars',
        icon: 'full-stacked-chart',
      },
    ],
  },
  {
    name: 'axisType',
    displayName: 'Axis',
    type: 'list',
    defaultValue: 'primary',
    values: [
      {
        name: 'primary',
        displayName: 'Primary',
        icon: 'alignment-left',
      },
      {
        name: 'secondary',
        displayName: 'Secondary',
        icon: 'alignment-right',
      },
    ],
  },
  {
    name: 'color',
    displayName: 'Color',
    type: 'icons',
    icon: 'tint',
    defaultValue: colorDefinitions[3][0].name,
    values: colorDefinitions,
  },
  {
    name: 'lineType',
    displayName: 'Line Type',
    type: 'icons',
    icon: customIcons.lineTypes,
    defaultValue: lineTypesDefinitions[0][1].name,
    values: lineTypesDefinitions,
  },
  {
    name: 'excludeFromGroupByColor',
    displayName: 'Exclude from Group By Color',
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'excludeFromGroupByLineType',
    displayName: 'Exclude from Group By Line Type',
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'dimensionDisplayType',
    displayName: 'Display Type',
    type: 'list',
    defaultValue: 'axis',
    values: [
      {
        name: 'axis',
        displayName: 'Axis',
        icon: 'chart',
      },
      {
        name: 'colors',
        displayName: 'Color',
        icon: 'tint',
      },
      {
        name: 'line-types',
        displayName: 'Line Type',
        icon: customIcons.lineTypes,
      },
    ],
  },
  {
    name: 'comparisonDisplayType',
    displayName: 'Comparison Display Type',
    type: 'list',
    defaultValue: 'color',
    values: [
      {
        name: 'color',
        displayName: 'Color',
        icon: 'tint',
      },
      {
        name: 'line-type',
        displayName: 'Line Type',
        icon: customIcons.lineTypes,
      },
    ],
  },
  {
    name: 'maxDisplayed',
    displayName: 'Max. values displayed',
    type: 'number',
    defaultValue: undefined,
    values: { from: 1, to: undefined },
  },
  {
    name: 'showTotal',
    displayName: 'Show Total',
    type: 'boolean',
    defaultValue: false,
  },
  {
    name: 'showOther',
    displayName: 'Show Other',
    type: 'boolean',
    defaultValue: false,
  },
];

const chartTypes = [
  {
    name: 'lines-bars',
    icon: 'chart',
    parameterGroups: [
      {
        type: 'metrics',
        displayName: 'What?',
        icon: 'arrow-up',
        minAllowed: 1,
        maxAllowed: null,
        attributeTypes: ['metric'],
        parameters: parameterTypes.filter((param) => [
          'metricDisplayType',
          'axisType',
          'color',
          'lineType',
          'excludeFromGroupByColor',
          'excludeFromGroupByLineType',
        ].includes(param.name)),
      },
      {
        type: 'dimensions',
        displayName: 'By What?',
        icon: 'arrow-right',
        minAllowed: 1,
        maxAllowed: null,
        attributeTypes: ['date', 'dimension'],
        parameters: parameterTypes.filter((param) => ['dimensionDisplayType'].includes(param.name)),
        // parameters: parameterTypes.filter((param) => ['dimensionDisplayType', 'maxDisplayed', 'showTotal', 'showOther'].includes(param.name)),
      },
      {
        type: 'comparisons',
        displayName: 'Compared To What?',
        icon: 'comparison',
        minAllowed: 0,
        maxAllowed: null,
        attributeTypes: ['comparison'],
        parameters: parameterTypes.filter((param) => ['color', 'lineType'].includes(param.name)),
      },
      {
        type: 'filters',
        displayName: 'For What?',
        icon: 'filter',
        minAllowed: 0,
        maxAllowed: null,
      },
    ],
  },
  {
    name: 'vertical-lines-bars',
    icon: 'horizontal-bar-chart',
    parameterGroups: [
      {
        type: 'metrics',
        displayName: 'What?',
        icon: 'arrow-right',
        minAllowed: 1,
        maxAllowed: null,
        attributeTypes: ['metric'],
        parameters: parameterTypes.filter((param) => [
          'metricDisplayType',
          'axisType',
          'color',
          'lineType',
          'excludeFromGroupByColor',
          'excludeFromGroupByLineType',
        ].includes(param.name)),
      },
      {
        type: 'dimensions',
        displayName: 'By What?',
        icon: 'arrow-up',
        minAllowed: 1,
        maxAllowed: null,
        attributeTypes: ['date', 'dimension'],
        parameters: parameterTypes.filter((param) => ['dimensionDisplayType', 'maxDisplayed', 'showTotal', 'showOther'].includes(param.name)),
      },
      {
        type: 'comparisons',
        displayName: 'Compared To What?',
        icon: 'comparison',
        minAllowed: 0,
        maxAllowed: null,
        attributeTypes: ['comparison'],
        parameters: parameterTypes.filter((param) => ['comparisonDisplayType', 'color', 'lineType'].includes(param.name)),
      },
      {
        type: 'filters',
        displayName: 'For What?',
        icon: 'filter',
        minAllowed: 0,
        maxAllowed: null,
      },
    ],
  },
  // {
  //   name: 'scatter',
  //   icon: 'scatter-plot',
  //   filters: true,
  //   comparison: true,
  // },
  // {
  //   name: 'area',
  //   icon: 'timeline-area-chart',
  //   filters: true,
  //   comparison: true,
  // },
  // {
  //   name: 'heat-grid',
  //   icon: 'heat-grid',
  //   filters: true,
  //   comparison: true,
  // },
  // {
  //   name: 'pie',
  //   icon: 'pie-chart',
  //   filters: true,
  //   comparison: true,
  // },
  // {
  //   name: 'donut',
  //   icon: 'doughnut-chart',
  //   filters: true,
  //   comparison: true,
  // },
  // {
  //   name: 'table',
  //   icon: 'th',
  //   filters: true,
  //   comparison: true,
  // },
];

export {
  parameterTypes,
  chartTypes,
};
