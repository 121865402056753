import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { useAuth0 } from '../react-auth0-spa';
import './loginPage.css';

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, loading } = useAuth0();
  const { state } = useLocation();
  const redirectTo = state ? state.redirectTo : null;
  if (redirectTo) sessionStorage.setItem('redirectTo', redirectTo);


  return (
    <div className="loginPage bp3-dark">
      <div className="loginPageBody">
        <Button
          large
          loading={isAuthenticated === undefined ? true : loading}
          onClick={() => loginWithRedirect({ redirect_uri: window.location.origin })}
        >
          Login
        </Button>
      </div>
      <footer className="buildNumber">
        {`Build: ${process.env.REACT_APP_BUILD_NUMBER || 'local'}`}
      </footer>
    </div>
  );
};

export default LoginPage;
