import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FileList from './FileList';
import uploadActions from '../../Actions/uploadActions';
import { fileListPropTypes } from '../../Definitions/dataPropTypes';

const FileListContainer = (props) => {
  const handleClickSelect = (itemKey) => {
    const file = props.fileList.find((el) => el.name === itemKey);
    props.updateFileParameter(file.name, 'selected', !file.selected, props.token);
  };

  const handleClickSelectAll = () => {
    const selected = props.fileList.filter((file) => !file.selected).length > 0;
    props.bulkUpdateFileParameter(props.fileList.map((file) => file.name), 'selected', selected, props.token);
  };

  const handleDeleteFile = (file) => {
    props.removeFile(file.name, props.token);
  };

  return (
    <FileList
      fileList={props.fileList}
      handleClickSelect={handleClickSelect}
      handleClickSelectAll={handleClickSelectAll}
      handleDeleteFile={handleDeleteFile}
    />
  );
};

FileListContainer.propTypes = {
  token: PropTypes.string.isRequired,
  fileList: PropTypes.arrayOf(fileListPropTypes).isRequired,
  updateFileParameter: PropTypes.func.isRequired,
  bulkUpdateFileParameter: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  token: store.authReducer.token,
  fileList: store.uploadReducer.fileList,
});

const mapDispatchToProps = (dispatch) => ({
  updateFileParameter: (fileName, parameterName, parameterValue, token) => dispatch(uploadActions.updateFileParameter(fileName, parameterName, parameterValue, token)),
  bulkUpdateFileParameter: (fileNames, parameterName, parameterValue, token) => dispatch(
    uploadActions.bulkUpdateFileParameter(fileNames, parameterName, parameterValue, token),
  ),
  removeFile: (fileName, token) => dispatch(uploadActions.removeFile(fileName, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileListContainer);
