const ETLdefinitions = [
  {
    name: 'none',
    displayName: 'None',
    fileRoles: [{ name: 'n/a' }],
  },
  {
    name: 'standardPromoETL',
    displayName: 'Standard Promo Effectiveness',
    fileRoles: [
      {
        name: 'Volumes',
        required: true,
      },
      {
        name: 'Events',
        required: true,
      },
      {
        name: 'Financials',
        required: true,
      },
      {
        name: 'Cannibalization Matrix',
        required: false,
      },
    ],
    baseline: {
      methods: [
        {
          name: 'constant',
          parameters: [],
        },
        {
          name: 'moving-average',
          parameters: [
            {
              name: 'window',
              type: 'range-slider',
              range: [-30, 30],
              step: 1,
              labelStep: 5,
              defaultValue: [-13, 13],
            },
          ],
        },
        {
          name: 'exponential-moving-average',
          parameters: [
            {
              name: 'window',
              type: 'range-slider',
              range: [-30, 30],
              step: 1,
              labelStep: 5,
              defaultValue: [-13, 13],
            },
            {
              name: 'smoothing-factor',
              type: 'slider',
              range: [0, 1],
              step: 0.1,
              defaultValue: 0.6,
            },
          ],
        },
      ],
    },
    regularPrice: {
      methods: [
        {
          name: 'constant',
          parameters: [],
        },
        {
          name: 'moving-average',
          parameters: [
            {
              name: 'window',
              type: 'slider',
              range: [-30, 0],
              step: 1,
              labelStep: 5,
              defaultValue: -13,
            },
            {
              name: 'percentile',
              type: 'slider',
              range: [80, 100],
              step: 1,
              defaultValue: 95,
            },
          ],
        },
      ],
    },
    pantryLoading: {
      parameters: [
        {
          name: 'enabled',
          type: 'boolean',
        },
        {
          name: 'window',
          type: 'slider',
          range: [0, 15],
          step: 1,
          defaultValue: 2,
        },
      ],
    },
    cannibalization: {
      parameters: [
        {
          name: 'enabled',
          type: 'boolean',
        },
      ],
    },
  },
];

export default ETLdefinitions;
