import React from 'react';
import PropTypes from 'prop-types';
import { Button, Navbar, Alignment } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../../Auth/react-auth0-spa';

const NavBar = (props) => {
  const { logout } = useAuth0();

  return (
    <Navbar style={{ zIndex: 20 }} fixedToTop className="Navbar bp3-dark">
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading className="Heading">
          <Link to="/">{props.appName}</Link>
        </Navbar.Heading>
        <Navbar.Divider />
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Button className="darkModeButton" icon={props.isDarkMode ? 'flash' : 'moon'} minimal onClick={props.handleToggleDarkMode} />
        <Button icon="log-out" onClick={() => logout({ returnTo: `${window.location.origin}/login` })}>
          Log out
        </Button>
      </Navbar.Group>
    </Navbar>
  );
};

NavBar.propTypes = {
  appName: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  handleToggleDarkMode: PropTypes.func.isRequired,
};

export default NavBar;
