const extractS3KeyComponents = (key) => {
  const keyParts = key.split('/');
  const subFolderName = keyParts[1];
  const fileName = keyParts[2];
  const localType = fileName.split('_')[1];
  const screenshotType = fileName.split('_')[2];
  return { subFolderName, fileName, localType, screenshotType };
};

const getTimeStampFromSubFolderName = (name) => {
  const year = name.substr(0, 4);
  const month = name.substr(5, 2) - 1;
  const day = name.substr(8, 2);
  const hours = name.substr(13, 2);
  const minutes = name.substr(16, 2);
  return new Date(year, month, day, hours, minutes);
};

const getImgDimensions = async (src) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => {
    const { width, height } = img;
    resolve({ width, height });
  };
  img.onerror = (err) => reject(err);
  img.src = src;
});

export { extractS3KeyComponents, getTimeStampFromSubFolderName, getImgDimensions };
